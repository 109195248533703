import {
  CheckOutlined,
  EditOutlined,
  GoldOutlined,
  LockOutlined,
  PhoneOutlined,
  RightOutlined,
  ToolOutlined
} from "@ant-design/icons";
import { Alert, Button, Result, Space, message } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ApiCredentialType, DELETE, GET, POST, PUT, useApis } from "../../api";
import { Declaration } from "../../common/Declaration";
import _ from "../../common/lodash";
import Usage from "../../common/transient/Usage";
import Loading from "../../component/Loading";
import { SalesIQ } from "../../component/SalesIQ";
import AccountContext from "../../context/AccountContext";
import useNavigate from "../../hook/useNavigate";
import PlanCard from "../component/PlanCard";
import PurchaseDialog from "../dialog/PurchaseDialog";
import StageWizard, { StageWizardRef } from "../stage/StageWizard";
import AdvertiseStage from "../stage/common/AdvertiseStage";
import SubmitReviewStage from "../stage/common/SubmitReviewStage";
import BasicStage from "../stage/declaration/BasicStage";
import CarbonStage from "../stage/declaration/CarbonStage";
import GoodStage from "../stage/declaration/GoodStage";
import GoodsSummaryStage from "../stage/declaration/GoodsSummaryStage";
import InstallationSlotStage from "../stage/declaration/InstallationSlotStage";
import InstallationsSummaryStage from "../stage/declaration/InstallationsSummaryStage";
import SubmitStage from "../stage/declaration/SubmitStage";
import WelcomeStage from "../stage/declaration/WelcomeStage";
import { ServiceLimitError } from "../../common/error";

export interface DeclarationPageProps {
  anonymous?: boolean
}

export type DeclarationPageModeType = 'edit-as-exporter' | 'view' | 'view-anonymous' | 'view-submit'

export default ({anonymous}: DeclarationPageProps) => {
  const [mode, setMode] = useState<DeclarationPageModeType>('edit-as-exporter')
  const [dialog, setDialog] = useState<'purchase' | null>(null)
  const {account} = useContext(AccountContext)
  const ref = useRef<StageWizardRef>(null)
  const search = new URLSearchParams(window.location.search)
  const params = useParams<'id'>()
  const {t} = useTranslation()
  const token = search.get('token')
  const navigate = useNavigate()
  const {declaration, usage, reload, state} = useApis({
    declaration: () => GET<Declaration>(`/cbam/declarations/${params.id}`, {
      mode: token ? 'view-anonymous' : undefined,
      token
    }, token ? ApiCredentialType.Optional : ApiCredentialType.Required),
    usage: !token && (() => GET<Usage>(`/cbam/declarations/${params.id}/usage`))
  })

  useEffect(() => {
    (async () => {
      if (state !== 'success') return
      if (!params.id) return navigate('/home')
      const mode = _(declaration?.status).where<DeclarationPageModeType>(
        ['reviewing', 'view'],
        ['rejected', 'view'],
        ['submitted', 'view-submit'],
        'edit-as-exporter'
      ).value()
      mode && setMode(mode)
    })()
  }, [declaration])

  async function onEdit() {
    const declarationId = declaration?._id?.toString()
    if (!declarationId || !declaration) return
    const answer = window.confirm(t('imperative.are-you-sure-to-withdraw'))
    if (!answer || !(await POST(`/cbam/declarations/${declarationId}/retract`)).status.success) return
    declaration.status = 'draft'
    setMode('edit-as-exporter')
    ref.current?.goto({name: 'welcome'})
  }

  async function onSave(value?: any, stageName?: string) {
    if (!declaration || mode.startsWith('view')) return undefined
    const update = value || declaration
    if (stageName !== 'installationSlots'){
      const {status} = await PUT(`/cbam/declarations/${update._id}`, {
        declaration: {
          ...update,
          currentStage: stageName
        }
      })
      if (status.statusCode === 40401) return navigate("/apps/wegreenpass/404")
      if (status.statusCode === ServiceLimitError.statusCode){
        message.error(t('validation.service-limit-excedded'))
        return setDialog('purchase')
      }
    }
    const {declaration: newDeclaration} = await reload('declaration', 'usage')
    if (!newDeclaration) throw new Error(t('validation.cannot-save-report'))
    return newDeclaration
  }

  if (state !== 'success') return <Loading/>

  if (declaration === undefined) return <Result
    status={"404"}
    title={t('validation.declaration-not-found')}
    subTitle={t('description.declaration-not-found')}
    extra={<Button
      type={"primary"}
      onClick={() => navigate("/apps/wegreenpass")}
    >{t('button.go-back')}</Button>}
  />

  return <>
    {declaration.status === 'reviewing' && <Alert
      type={'warning'}
      icon={<LockOutlined/>}
      message={<Space>{t('validation.cannot-edit-under-review')}<Button
        icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}
      >{t('button.contact-service')}</Button><Button
        danger
        icon={<EditOutlined/>}
        onClick={onEdit}
      >{t('button.retract-and-edit')}</Button></Space>}
      showIcon
    />}
    {declaration.status === 'submitted' && <Alert
      type={'success'}
      icon={<CheckOutlined/>}
      message={<Space>{t('validation.finished-review-can-submit')}<Button
        icon={<RightOutlined/>} type={'primary'} onClick={() => ref.current?.goto({name: 'submit'})}
      >{t('button.go-to-submit-page')}</Button><Button
        icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}
      >{t('button.contact-service')}</Button><Button
        danger
        icon={<EditOutlined/>}
        onClick={onEdit}
      >{t('button.retract-and-edit')}</Button></Space>}
      showIcon
    />}
    <StageWizard
      value={declaration}
      initialName={mode.startsWith('view') ? 'basic' : declaration.currentStage || 'welcome'}
      disabled={mode.startsWith('view')}
      onSave={onSave}
      onFinish={async () => {
        if (!declaration?._id) return
        const {status} = await POST(`/cbam/declarations/${declaration?._id}/submit`)
        if (status.success) navigate("/apps/wegreenpass/success")
      }}
      ref={ref}
      timelineExtras={usage && <PlanCard
        usage={usage}
        onPurchase={() => setDialog('purchase')}
      />}
    >
      {mode.startsWith('edit') && <WelcomeStage name={'welcome'} heading={t('title.step-welcome')}/>}
      <BasicStage
        name={'basic'}
        heading={t('title.step-declaration-basic')}
        year={declaration?.year}
        reportingPeriod={declaration?.reportingPeriod}
      />
      <GoodStage
        name={'goods'}
        type={'collection'}
        heading={(v, i) => t('title.step-good', {
          itemId: (i || 0) + 1,
          name: v?.Description?.native || t('value.new-good')
        })}
        dot={<GoldOutlined/>}
        onDelete={async (index: number) => {
          if (!declaration) return
          const newDeclaration = _.cloneDeep(declaration)
          newDeclaration.goods?.splice(index, 1)
          await onSave(newDeclaration, 'goods')
          await reload('declaration', 'usage')
          if (ref.current?.name() === 'goods') ref.current.goto({
            name: 'goods',
            index: Math.min(index - 1, ref.current.index() || 0)
          })
        }}
        onAdd={async (index: number) => {
          if (!declaration?._id) return
          let {status, declaration: newDeclaration} = await POST(`/cbam/declarations/${declaration?._id}/goods`)
          if (status.statusCode === 40307) {
            message.error(t('validation.maximum-good-please-upgrade'))
            return setDialog('purchase')
          }
          await reload('declaration', 'usage')
          await ref.current?.goto({position: 'previous', saving: false, name: 'goods'})
        }}
      />
      <GoodsSummaryStage name={'goods-summary'} heading={t('title.step-goods-summary')}/>
      <InstallationSlotStage
        name={'installationSlots'}
        type={'collection'}
        heading={(v, i) => t('title.step-installation', {
          itemId: (i || 0) + 1,
          name: v?.InstallationName?.native || t('value.noname-installation')
        })}
        dot={<ToolOutlined/>}

        onAdd={async () => {
          const accountId = account?._id
          if (!declaration?._id || !accountId) return
          const {status} = await POST(`/cbam/declarations/${declaration._id}/slots`)
          if (status.statusCode === 40307) {
            message.error(t('validation.service-limit-excedded'))
            return setDialog('purchase')
          }
          await reload('declaration', 'usage')
          await ref.current?.goto({
            position: 'previous',
            saving: false,
            name: 'installationSlots'
          })
        }}

        onDelete={async (index: number) => {
          const {status} = await DELETE(`/cbam/declarations/${declaration._id}/slots/${index}`)
          await reload('declaration', 'usage')
          await ref.current?.goto({
            position: 'previous',
            saving: false,
            name: 'installationSlots'
          })
        }}
      />
      {/*<InstallationStage*/}
      {/*  name={'installations'}*/}
      {/*  type={'collection'}*/}
      {/*  heading={(v, i) => t('title.step-installation', {*/}
      {/*    itemId: (i || 0) + 1,*/}
      {/*    name: v?.basic?.InstallationName?.native || t('value.noname-installation')*/}
      {/*  })}*/}
      {/*  dot={<ToolOutlined/>}*/}
      {/*  onDelete={async (index) => {*/}
      {/*    const installationId = declaration?.installations[index]._id?.toString() OBSELETE*/}
      {/*    if (!declaration?._id || !installationId) return*/}
      {/*    const {status} = await DELETE(`/cbam/declarations/${declaration?._id}/installations/${installationId}`)*/}
      {/*    await reload('declaration', 'usage')*/}
      {/*    if (!status?.success) return*/}
      {/*    const newDeclaration = _.cloneDeep(declaration)*/}
      {/*    newDeclaration.installations.splice(index, 1) OBSELETE*/}
      {/*    if (ref.current?.name() === 'installations') await ref.current.goto({*/}
      {/*      name: 'installations',*/}
      {/*      index: Math.min(index - 1, ref.current.index() || 0)*/}
      {/*    })*/}
      {/*  }}*/}
      {/*  onAdd={async () => {*/}
      {/*    const accountId = account?._id*/}
      {/*    if (!declaration?._id || !accountId) return*/}
      {/*    const {status, installation} = await POST(`/cbam/declarations/${declaration._id}/installations`)*/}
      {/*    await reload('declaration', 'usage')*/}
      {/*    if (status.statusCode === 40307) {*/}
      {/*      message.error(t('validation.maximum-installation-please-upgrade'))*/}
      {/*      return setDialog('purchase')*/}
      {/*    }*/}
      {/*    if (!status.success || !installation) return*/}
      {/*    const newDeclaration = _.cloneDeep(declaration)*/}
      {/*    newDeclaration.installations.push(installation) OBSELETE*/}
      {/*    await ref.current?.goto({*/}
      {/*      position: 'previous',*/}
      {/*      saving: false,*/}
      {/*      name: 'installations'*/}
      {/*    })*/}
      {/*  }}*/}
      {/*/>*/}
      <InstallationsSummaryStage
        name={'installations-summary'}
        heading={t('title.step-installations-summary')}
      />
      <CarbonStage
        name={'due'}
        heading={t('title.step-carbon')}
      />
      <AdvertiseStage name={'advertise'} heading={t('title.step-advertise')}/>
      {mode === 'edit-as-exporter' &&
        <SubmitReviewStage name={'submit-review'} heading={t('title.step-submit-manual-check')}/>}
      {mode === 'view-submit' && <SubmitStage name={'submit'} heading={t('title.step-submit')}/>}
    </StageWizard>
    <PurchaseDialog
      open={dialog === 'purchase'}
      entity={declaration.declarant}
      onReturn={() => window.location.reload()}
    />
  </>
}
