import _ from "../../common/lodash";
import {AlertOutlined, CheckOutlined, EditOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import {useTranslation} from "react-i18next";

export interface StatusTagProps {
  status?: string
}

export default ({status}: StatusTagProps) => {
  const {t} = useTranslation()
  if (!status) return null
  return <Tag
    color={_.where(status, ['submitted', 'green'], ['rejected', 'red'], 'gray') || 'gray'}
    icon={_.where(status, ['submitted', <CheckOutlined/>], ['rejected', <AlertOutlined/>]) ||
    <EditOutlined/>}
    >{t('value.' + status)}</Tag>
  }