import IFormItemProps from "../../form/IFormItemProps";
import React, {ReactNode, useContext, useState} from "react";
import {Button, Card, Space} from "antd";
import {CloseOutlined, EditOutlined, PlusOutlined, SelectOutlined, SwitcherOutlined} from "@ant-design/icons";
import {Trans, useTranslation} from "react-i18next";
import ObjectSelectDialog, {ObjectSelectDialogProps} from "../dialog/ObjectSelectDialog";
import "./ObjectSelect.scss"
import {AnyObject} from "antd/es/_util/type";
import DisabledContext from "antd/es/config-provider/DisabledContext";

export interface ObjectSelectProps<T extends AnyObject> extends IFormItemProps<T>, Omit<ObjectSelectDialogProps<T>, 'open' | 'onClose'> {
  name: string
  required?: boolean
  renderItem?: (value?: T) => ReactNode[] | ReactNode
  onAdd?: (value?: T) => void
  onEdit?: (value?: T) => void
  className?: string
  disabled?: boolean
}

export function ObjectInputSelect<T extends AnyObject>(props: ObjectSelectProps<T>) {
  const {name, onAdd, disabled, value, onChange, required, className, renderItem, onEdit} = props
  const [dialog, setDialog] = useState<'select' | null>(null)
  const {t} = useTranslation()
  const contextualDisabled = useContext(DisabledContext) || disabled
  return <div className={["object-select", "input", className].filter(_ => _).join(" ")}>
    <main>{renderItem?.(value)}</main>
    {!contextualDisabled && <Space.Compact>
      <Button
        type={value !== undefined ? "default" : "primary"}
        onClick={() => setDialog('select')}
        disabled={contextualDisabled}
      ><Space><SelectOutlined/>{t("button.choose-item", {name})}</Space></Button>
      {onEdit && !contextualDisabled ? <Button
        icon={<EditOutlined/>}
        onClick={() => onEdit?.(value)}
      >{t('button.edit')}</Button> : undefined}
      {!required && !contextualDisabled && value !== undefined && <Button
        icon={<CloseOutlined/>} onClick={() => onChange?.()}
      >{t("button.deselect")}</Button>}
    </Space.Compact>}
    <ObjectSelectDialog {...props} open={dialog === 'select'} onClose={() => setDialog(null)}/>
  </div>
}

export function ObjectCardSelect<T extends AnyObject>(props: ObjectSelectProps<T>) {
  const {name, onAdd, disabled, value, onChange, required, className, renderItem} = props
  const [dialog, setDialog] = useState<'select' | null>(null)
  const {t} = useTranslation()
  const contextualDisabled = useContext(DisabledContext) || disabled
  return <Card
    title={name}
    size={"small"}
    extra={!contextualDisabled && <Space.Compact>
      {onAdd && <Button
        icon={<PlusOutlined/>}
        type={"text"}
        onClick={() => onAdd?.()}
      />}
      {!contextualDisabled && value && <Button
        type={"default"}
        onClick={() => setDialog('select')}
      ><Space><SelectOutlined/>{t("button.choose-item", {name})}</Space></Button>}
      {onChange && value && !required && !contextualDisabled && <Button
        icon={<CloseOutlined/>} onClick={() => onChange?.()}
      >{t("button.deselect")}</Button>}
    </Space.Compact>}
    className={["object-select", "card", className].filter(_ => _).join(" ")}
  >
    <main>
      {value && renderItem?.(value)}
      {!value && <div className={"empty-entity"}>
        <h3>{t("validation.not-selected-item", {name})}</h3>
        <Space>
          {onAdd && !contextualDisabled && <Button
            type={"text"}
            onClick={() => onAdd?.()}
          ><Space><PlusOutlined/>{t("button.add-item", {name})}</Space></Button>}
          {!contextualDisabled && <Button
            type={"text"}
            onClick={() => setDialog('select')}
          ><Space><SwitcherOutlined/>{t("button.choose-from-existing-item", {name})}</Space>
          </Button>}
        </Space>
      </div>}
    </main>
    <ObjectSelectDialog {...props} open={dialog === 'select'} onClose={() => setDialog(null)}/>
  </Card>
}