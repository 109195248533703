import React, { useContext, useState } from "react";
import { StageProps, useStageForm } from "../Stage";
import { Declaration } from "../../../common/Declaration";
import { Col, Divider, Form, Input, Row, Select, Space } from "antd";
import EntitySelect from "../../../component/EntitySelect";
import FlagImage from "../../../component/FlagImage";
import { CodeList, ICodeListItem } from "../../../common/CodeList";
import "./BasicStage.scss";
import HintedLabel from "../../../component/HintedLabel";
import { ObjectCardSelect } from "../../component/ObjectSelect";
import { useTranslation } from "react-i18next";
import CodeListContext from "../../../context/CodeListContext";
import _ from "../../../common/lodash";
import UnlocodeSelect from "../../component/UnlocodeSelect";

export interface DeclarationBasicStageProps
  extends StageProps<Declaration, Declaration.Basic> {
  year?: number;
  reportingPeriod?: string;
}

export default (props: DeclarationBasicStageProps) => {
  const Form = useStageForm(props);
  const { year, reportingPeriod, value, root } = props;
  const { t } = useTranslation();
  const { CL_ReportingPeriods, WC_Positions, CL_CompetentAuthorities } =
    useContext(CodeListContext);
  const [region, setRegion] = useState<string | undefined>(
    value?.ImporterEntity.region
  );
  return (
    <Form
      initialValues={{
        year,
        reportingPeriod,
      }}
      className={"basic declaration stage"}
      onValuesChange={(cv, v) => {
        if (cv.Importer) setRegion(v.Importer.region);
      }}
    >
      <h3>{t("field.time-and-entity")}</h3>
      <Row gutter={18}>
        <Col span={12}>
          <Form.Item name={"year"} label={t("field.year")} required={true}>
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("field.quarter")}
            required={true}
            name={"reportingPeriod"}
            rules={[
              {
                required: true,
                message: t("validation.please-select-quarter"),
              },
            ]}
          >
            <Select options={CL_ReportingPeriods.options()} disabled />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={"ImporterEntity"}
        label={t("field.importer")}
        required={true}
        rules={[{ required: true }]}
      >
        <EntitySelect
          role={"importer"}
          type={"card"}
          name={t("field.importer")}
          required={true}
        />
      </Form.Item>
      <Form.Item
        name={"RepresentativeEntity"}
        label={t("field.representative")}
      >
        <EntitySelect
          role={"representative"}
          required={false}
          type={"card"}
          name={t("field.representative")}
        />
      </Form.Item>
      <Divider />
      <h3>{t("field.filler")}</h3>
      <Form.Item
        name={"Signature"}
        label={
          <HintedLabel title={t("field.person-name")}>
            {t("description.person-name-should-match-passport")}
          </HintedLabel>
        }
        required={true}
        rules={[
          { required: true },
          {
            // Pattern: ASCII
            pattern: /^[\x00-\x7F]{2,}$/,
            message: t("validation.ascii"),
          },
        ]}
      >
        <Input placeholder={t("validation.same-as-your-passport")} />
      </Form.Item>
      <Form.Item
        name={"PositionOfPersonSending"}
        label={t("field.position")}
        required={true}
        rules={[{ required: true }]}
      >
        <Select options={WC_Positions.options()} />
      </Form.Item>
      <Form.Item
        name={"SignaturePlace"}
        label={t("field.signature-place")}
        required={true}
      >
        <UnlocodeSelect region={region} />
      </Form.Item>
      <Divider />
      <h3>{t("field.competent-authority")}</h3>
      <p>{t("description.competent-authority")}</p>
      <Form.Item
        name={"CompetentAuthority"}
        required={true}
        rules={[{ required: true }]}
      >
        <ObjectCardSelect<ICodeListItem>
          name={t("field.competent-authority")}
          renderItem={(authority) => {
            return (
              authority && (
                <div className={"competent-authority"} key={authority.value}>
                  <Space>
                    <FlagImage iso3166={authority.value} />
                    {authority.label}
                  </Space>
                  <p>{authority.attrs?.native}</p>
                </div>
              )
            );
          }}
          data={CL_CompetentAuthorities.options()}
          columns={_.toAntdColumns({
            [t("field.competent-authority")]: (v) => {
              return (
                <Space key={v.value}>
                  <FlagImage iso3166={v.value} />
                  <span>{v.label}</span>
                </Space>
              );
            },
          })}
        />
      </Form.Item>
    </Form>
  );
};
