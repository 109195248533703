import lodash from 'lodash'
import {ReactNode} from "react";
import {ColumnsType} from "antd/es/table";
import Stripe from "stripe";
import Languages from "../../languages.json";
import {Locale} from "antd/es/locale";
import zh_CN from "antd/lib/locale/zh_CN";
import en from "antd/lib/locale/en_US";
import fr from "antd/lib/locale/fr_FR";
import zh_HK from "antd/lib/locale/zh_HK";
import de from "antd/lib/locale/de_DE";
import it from "antd/lib/locale/it_IT";
import i18n from "i18next";
import {Tab} from "rc-tabs/lib/interface";
import {SHORT_UUID_PATTERN, UUID_PATTERN} from "../constants/Regexs";

const _ = lodash.runInContext()
export type ConditionTupleType<I, O> = [RegExp | ((o: I) => any) | boolean | string | number | null | undefined, O]
export type ConditionType<I, O> = (ConditionTupleType<I, O> | O)
export type ToCaseType = 'all' | 'first' | 'none'

function where<I, O>(input: I, ...conditions: ConditionType<I, O>[]): O | undefined{
  for (const condition of conditions){
    if (!_.isArray(condition)) return condition
    const [o, v] = condition
    if (
      (o instanceof RegExp && typeof input === 'string' && o.test(input)) ||
      (typeof o === 'function' && input && o(input)) ||
      ((typeof o === 'boolean' || typeof o === 'number') && o) ||
      (typeof o === 'string' && typeof input === 'string' && o === input)
    ) return v
  }
  return undefined
}

function toAntdColumns<T = any>(
  o: { [key: string]: string | string[] | ((x: T, i?: number) => ReactNode | ReactNode[]) },
  concat?: any
): ColumnsType<T> {
  return _(o).toPairs().map(([k, v]) => ({
    title: i18n.t(k),
    key: k,
    dataIndex: typeof v !== 'function' ? v : undefined,
    render: typeof v === 'function' ? (value: any, record: any, index: number) => v(record, index) : undefined
  })).concat(concat || []).value()
}

function toAntdTabItems<T = any>(
  o: { [key: string]: ReactNode}
): Tab[]{
  return _(o).toPairs().map(([k, v]) => ({
    key: k,
    label: k,
    children: v
  } as Tab)).value()
}


function id(o: any): string | undefined {
  if (typeof o === 'string') return o
  if (typeof o === 'object') return o?._id.toString()
}

function toCase(s: string, o: ToCaseType = 'none'){
  if (o === 'all') return _.startCase(s)
  if (o === 'first') return _.capitalize(s)
  return s
}

function paths(o: any): string[]{
  return _(o)
    .toPairs()
    .map(([k, v]) => typeof v === 'object' ? paths(v).map(subKey => `${k}.${subKey}`) : k)
    .flatten()
    .value()
}

function url(s?: string, type?: 'plain' | 'css'): string {
  let url = s || ""
  if (type === 'css') url = `url('${url}')`
  return url
}

function toLocale(o: string, type: 'stripe'):Stripe.Checkout.SessionCreateParams.Locale
function toLocale(o: string, type: 'standard'): string
function toLocale(o: string, type: 'antd'): Locale
function toLocale(s: string, type: 'standard' | 'stripe' | 'antd' = 'standard'):Stripe.Checkout.SessionCreateParams.Locale | Locale | string {
  if (type === 'antd') return _.where(s,
    [/^zh-(HK|MO|TW)$/, zh_HK],
    [/^zh(-CN)?$/, zh_CN],
    [/^fr.*$/, fr],
    [/^de.*$/, de],
    [/^it.*$/, it],
    [/^en.*$/, en],
    ) || en
  return _.where(s,
    [/^en.*$/, 'en'],
    [/^zh-(HK|MO|TW)$/, 'zh-HK'],
    [s => type === 'standard' && /^zh(-CN)?$/.test(s), 'zh-CN'],
    [s => type === 'stripe' && /^zh(-CN)?$/.test(s), 'zh'],
    [/^fr.*$/, 'fr'],
    [/^de.*$/, 'de'],
    [/^it.*$/, 'it'],
    [s => type === 'standard' && Languages.some(o => o.code === s), s]
  ) || 'en'
}

function omitNull(o: any): any{
  // Recursively remove null values from an object
  if (_.isNil(o)) return undefined
  if (_.isArray(o)) return _.map(o, omitNull)
  if (_.isObject(o)) return _(o).mapValues(omitNull).omitBy(_.isNil).value()
  return o
}

function cartesian<T, U>(a?: T[], b?:U[]): [T, U][] {
  if (!a || !b) return []
  return a.flatMap(x => b.map(y => [x, y] as [T, U]))
}

function uuid(s?: string){
  if (s === undefined) return ""
  if (UUID_PATTERN.test(s)) return s
  if (SHORT_UUID_PATTERN.test(s)) return s.slice(0,8)+"-"+s.slice(8,12)+"-"+s.slice(12,16)+"-"+s.slice(16,20)+"-"+s.substr(20);
  return ""
  // Add dashes to s
}

_.mixin({
  where, id, toCase, paths, toLocale, omitNull, cartesian, uuid
}, {chain: true})

_.mixin({toAntdColumns, toAntdTabItems, url}, {chain: false})

declare module 'lodash' {
  interface LoDashStatic {
    where<I, O>(input: I, ...conditions: ConditionType<I, O>[]): O | undefined
    id(o: any): string | undefined
    toCase(s: string, o?: ToCaseType): string
    paths(o: any): string[]
    omitNull(o: any): any
    toLocale(o: string, type: 'stripe'):Stripe.Checkout.SessionCreateParams.Locale
    toLocale(o: string, type?: 'standard'): string
    toLocale(o: string, type: 'antd'): Locale
    cartesian<T, U>(a?: T[], b?:U[]): [T, U][]
    uuid(s?: string): string
    toAntdColumns<T = any>(o: { [key: string]: string | string[] | ((x: T, i?: number) => ReactNode | ReactNode[]) }, concat?: any): ColumnsType<T>
    toAntdTabItems<T = any>(o: { [key: string]: ReactNode}): Tab[]
    url(s?: string, type?: 'plain' | 'css'): string
  }
  interface LoDashImplicitWrapper<TValue> {
    where<O>(...conditions: ConditionType<TValue, O>[]): LoDashImplicitWrapper<O | undefined>
    toCase(o?: ToCaseType): LoDashImplicitWrapper<string>
    id(): LoDashImplicitWrapper<string | undefined>
    omitNull(): LoDashImplicitWrapper<any>
    toLocale(type: 'stripe'): LoDashImplicitWrapper<Stripe.Checkout.SessionCreateParams.Locale>
    toLocale(type?: 'standard'): LoDashImplicitWrapper<string>
    toLocale(type: 'antd'): LoDashImplicitWrapper<Locale>
    uuid(): LoDashImplicitWrapper<string>
    cartesian<U>(b?:U[]): LoDashImplicitWrapper<[any, U][]>
    paths(): LoDashImplicitWrapper<string[]>
  }
}
export default _ as lodash.LoDashStatic