import React, {ForwardedRef, forwardRef, RefObject, useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Button, Checkbox, Form, FormInstance, Input, message, Select} from "antd";
import Account from "../common/Account";
import {ArrowRightOutlined} from "@ant-design/icons";

import "./ProfileForm.scss"
import TranslateInput from "../component/TranslateInput";
import AccountContext from "../context/AccountContext";
import CodeListContext from "../context/CodeListContext";
import _ from "../common/lodash"
import {POST} from "../api";
import useNavigate from "../hook/useNavigate";

export interface ProfileFormProps {
  account?: Account
  submitButtonText?: string
  className?: string
  redirect?: string
}

export default forwardRef(({account, submitButtonText, className, redirect}: ProfileFormProps, ref: ForwardedRef<FormInstance<Account['Profile']>>) => {
  const newRef = useRef<FormInstance<Account['Profile']>>(null)
  const _ref = (ref || newRef) as RefObject<FormInstance<Account['Profile']>>
  const {t} = useTranslation()
  const {requireAccount} = useContext(AccountContext)
  const {WC_Positions, WC_Sectors, WC_Demands} = useContext(CodeListContext)
  const _account = account || requireAccount()
  const navigate = useNavigate()
  return <>
    <
      Form<Account['Profile']>
      initialValues={{..._account?.Profile, subscribe: true}}
      ref={_ref}
      className={_.reject(['profile-form', className], _.isNil).join(' ')}
    >
      <Form.Item label={t('field.person-name')} name={"Name"} required={true} rules={[{required: true}]}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t('field.occupation')} name={"Occupation"} required={true} rules={[{required: true}]}>
        <Select options={WC_Positions.options()}/>
      </Form.Item>
      <Form.Item label={t('field.company-name')} required={true}>
        <Form.Item name={"CompanyName"} rules={[{required: true}]} noStyle={true}>
          <TranslateInput/>
        </Form.Item>
        <p className={'description'}>{t('imperative.please-fill-legal-entity-name')}</p>
      </Form.Item>
      <Form.Item
        label={t('field.email')}
        name={"Email"}
        required={true}
        rules={[{required: true, type: 'email'}]}
      >
        <Input placeholder={t('validation.work-email')}/>
      </Form.Item>
      <Form.Item label={t('field.sector')} name={"Sector"} required={true} rules={[{required: true}]}>
        <Select options={WC_Sectors.options()}/>
      </Form.Item>
      <Form.Item
        label={t('field.how-we-help-you')}
        name={"BusinessDemand"}
        required={true}
        rules={[{required: true}]}
      >
        <Select options={WC_Demands.options()}/>
      </Form.Item>
      <Form.Item name={"EmailSubscribed"} valuePropName={"checked"}>
        <Checkbox>{t('description.subscribe-wecarbon-weekly')}</Checkbox>
      </Form.Item>
    </Form>
    <Button
      type={"primary"} icon={<ArrowRightOutlined/>} onClick={async () => {
      const id = _account?._id?.toString()
      if (!id) return
      try {
        await _ref?.current?.validateFields()
      } catch (e){
        return message.error(t('validation.required'))
      }
      const profile = _ref.current?.getFieldsValue()
      const response = await POST(`/accounts/${id}/profile`, {profile})
      if (!response.status.success) message.error(t('validation.required'))
      navigate(redirect || "/home", {library: 'native'})
    }}
    >
      {submitButtonText || t('button.submit')}
    </Button>
  </>

})