import {useMemo} from "react";
import {SelectProps, Space} from "antd";
import FlagImage from "../component/FlagImage";
import EnvUtil from "../util/EnvUtil";
import _ from "../common/lodash"
import {DefaultOptionType} from "rc-select/lib/Select";
import {useTranslation} from "react-i18next";

export interface UseLanguageOptionsProps {
  include?: string[]
  i18n?: boolean
  onJury?: () => unknown
}

export interface LanguageOptionType extends DefaultOptionType {
  external?: boolean
}

export default function useLanguageOptions({include, onJury}: UseLanguageOptionsProps): LanguageOptionType[] {
  const {env, jury, languages} = EnvUtil
  const {i18n} = useTranslation()
  const options = useMemo(() => _(languages)
    .filter(o =>
      (o.jury === jury || !o.jury) && // Filter by jury
      (!include || include.includes(o.code)) // Filter by include
    )
    .map(o => ({
      key: o.code,
      value: o.code,
      label: <Space><FlagImage iso3166={o.flag}/>{o.name}</Space>,
      selected: i18n.language === o.code,
      onClick: () => i18n.changeLanguage(o.code)
    } as LanguageOptionType))
    .concat(jury === 'intl' ? [{
      key: "__goto_cn",
      value: "__goto_cn",
      label: <Space><FlagImage iso3166={'CN'}/>{languages.find(o => o.flag === 'CN')?.name} </Space>,
      external: true,
      onClick: onJury
    }] : [])

    .value(), [])
  return options
}