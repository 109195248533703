import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Card, Form, Input} from "antd";
import WaitListLottie from "../data/WaitListLottie.json"
import Lottie from "lottie-react"
import "./WaitListPage.scss"
import {LogoutOutlined} from "@ant-design/icons";
import AccountContext from "../context/AccountContext";

export default () => {
  const {account, logout} = useContext(AccountContext)
  const {t} = useTranslation()

  return <Card bordered={false}>
    <div className={"wait-list-page"}>
      <h1>{t('title.we-are-testing')}</h1>
      <p>{t('imperative.you-are-in-the-waitlist')}</p>
      {account?.Profile?.Email &&
          <Form.Item label={t('field.email')}><Input disabled={true} value={account?.Profile?.Email}/></Form.Item>}
      <Lottie animationData={WaitListLottie} className={"lottie"}/>
      <Button type={'primary'} icon={<LogoutOutlined/>} onClick={logout}>{t('button.change-account')}</Button>
    </div>
  </Card>
}