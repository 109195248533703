import React, {useState} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Button, Form, Input, message, Radio, Select, Space, Tag} from "antd";
import {Trans, useTranslation} from "react-i18next";
import CallForAction from "../../component/CallForAction";
import {
  CheckCircleOutlined,
  CrownOutlined,
  DownloadOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined
} from "@ant-design/icons";
import fileSaver from "file-saver";
import "./SubmitStage.scss"
import {SalesIQ} from "../../../component/SalesIQ";
import pako from "pako";
import {Installation} from "../../../common/Installation";
import {EMAILS_PATTERN} from "../../../common/constants/Regexs";
import _ from "../../../common/lodash"
import {ApiCredentialType, POST} from "../../../api";
import HintedLabel from "../../../component/HintedLabel";
import useLanguageOptions from "../../../hook/useLanguageOptions";

export interface SubmitStageProps extends StageProps<Installation> {
  mode?: string
}

export default (props: SubmitStageProps) => {
  const Form = useStageForm(props)
  const {formRef, root, disabled, value, mode} = props
  const [choice, setChoice] = useState<string>(value?.SubmissionMethod)
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished'>('idle')
  const {i18n, t} = useTranslation()
  const languageOptions = useLanguageOptions({include: ['fr', 'en']})

  async function requestExcel(id?: string) {
    if (!id) return
    setStatus('loading')
    let readOnlyToken = undefined
    if (disabled) readOnlyToken = new URLSearchParams(window.location.search).get('token') || undefined
    const {document} = await POST(`/cbam/installations/${id}/export`, {token: readOnlyToken}, disabled ? ApiCredentialType.Optional : ApiCredentialType.Required)
    if (!document) return
    const binaryString = window.atob(document)
    const bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    const inflated = pako.inflate(bytes)
    const blob = new Blob([inflated], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    const file = new File([blob], `CBAM_${new Date().toISOString()}_${id}.xlsx`, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
    fileSaver.saveAs(file)
    setStatus('finished')
  }

  async function onSend() {
    const id = root?._id?.toString()
    if (!id) return
    setStatus('loading')
    try {
      await formRef?.current?.validateFields()
    } catch (e) {
      return message.error(t('validation.cannot-save-please-check'))
    }
    try {
      await POST(`/cbam/installations/${id}/mail`, formRef?.current?.getFieldsValue())
    } catch (e) {
      message.error(t('validation.cannot-send-email'))
      setStatus('idle')
    }
    message.success(t('validation.email-sent'))
    setStatus('finished')

  }

  return <Form
    onValuesChange={async (cv) => {
      if (cv.SubmissionMethod) setChoice(cv.SubmissionMethod)
      if (!cv.language) return
      await i18n.loadLanguages(cv.language)
      formRef?.current?.setFieldValue('subject',
        t('value.installation-email-subject', {
          lng: cv.language,
          name: root?.basic?.Importer?.name.en || "WeGreenPass"
        }),
      )
      formRef?.current?.setFieldValue('content',
        t('value.installation-email-content', {
          lng: cv.language,
          name: root?.basic?.Importer?.name.en || "WeGreenPass"
        }),
      )
    }} className={'submit-stage'} initialValues={{
    recipient: root?.basic?.Importer?.contact.Email,
    cc: root?.basic?.Entity?.contact.Email,
    language: 'en',
    subject: t('value.installation-email-subject', {
      lng: 'en',
      name: root?.basic?.Importer?.name.en || "WeGreenPass"
    }),
    content: t('value.installation-email-content', {
      lng: 'en',
      name: root?.basic?.Importer?.name.en || "WeGreenPass"
    }),
  }}
  >
    <h3>{t('title.installation-ready-how-to-submit')}</h3>
    <Form.Item name={"SubmissionMethod"}>
      <Radio.Group>
        <Radio value={"send"} disabled={mode === 'view-anonymous'}><HintedLabel
          title={t("value.submit-installation-by-send")}
        >{i18n.t('description.submit-installation-by-send')}</HintedLabel></Radio>
        <Radio value={"remote"}><Space>{t("value.submit-by-remote")}<Tag
          icon={<CrownOutlined/>}
          color={'gold'}
        >{t('value.gold')}</Tag></Space></Radio>
        <Radio value={"authorize"} disabled>{t("value.submit-by-authorize")}</Radio>
        <Radio value={"download"} disabled={false}>{t("value.submit-by-download")}</Radio>
      </Radio.Group>
    </Form.Item>
    {choice === 'send' && <>
      <Form.Item label={t('field.recipient')} name={'recipient'} rules={[{pattern: EMAILS_PATTERN}]}>
        <Input prefix={<MailOutlined/>} disabled={false}/>
      </Form.Item>
      <Form.Item label={t('field.cc')} name={'cc'} rules={[{pattern: EMAILS_PATTERN}]}>
        <Input prefix={<MailOutlined/>} disabled={false}/>
      </Form.Item>
      <Form.Item label={t('field.bcc')} name={'bcc'} rules={[{pattern: EMAILS_PATTERN}]}>
        <Input prefix={<MailOutlined/>} disabled={false}/>
      </Form.Item>
      <Form.Item label={t('field.language')} name={'language'}>
        <Select options={languageOptions}/>
      </Form.Item>
      <Form.Item label={t('field.subject')} name={'subject'}>
        <Input autoComplete={'off'} disabled={false}/>
      </Form.Item>
      <Form.Item label={t('field.content')} name={'content'}>
        <Input.TextArea autoSize readOnly/>
      </Form.Item>
      <Button
        icon={_(status).where(
          ['loading', <LoadingOutlined/>],
          ['finished', <CheckCircleOutlined/>],
          ['idle', <MailOutlined/>]
        ).value()}
        type={"primary"}
        style={{
          backgroundColor: status === 'finished' ? 'var(--secondary-color)' : undefined,
          cursor: status === 'loading' ? 'progress' : 'undefined'
        }}
        disabled={status === 'loading'}
        onClick={onSend}
      >{status === 'loading' ? t('button.loading') : t("button.send")}</Button>
    </>}
    {choice === 'remote' &&
      <CallForAction type={"dark"} backgroundImage={'/images/photo/nastuh-abootalebi-ZtC4_rPCRXA-unsplash.jpg'}>
        <span>{t('imperative.contact-support')}</span>
        <Space><Button
          type={'primary'}
          icon={<PhoneOutlined/>}
          onClick={SalesIQ.startChat}
        >{t('button.contact-service')}</Button></Space>
      </CallForAction>}
    {choice === 'download' && <div>
      <Trans i18nKey={"description.login-excel-download"}><a
        href={"https://taxation-customs.ec.europa.eu/system/files/2023-10/User%20Manual%20for%20CBAM%20Declarants-Release%201.1_v1.10.pdf"}
      ></a></Trans>
      <Button
        icon={_(status).where(
          ['loading', <LoadingOutlined/>],
          ['finished', <CheckCircleOutlined/>],
          ['idle', <DownloadOutlined/>]
        ).value()}
        type={"primary"}
        style={{
          backgroundColor: status === 'finished' ? 'var(--secondary-color)' : undefined,
          cursor: status === 'loading' ? 'progress' : undefined
        }}
        disabled={status === 'loading'}
        onClick={() => requestExcel(root?._id?.toString())}
      >{status === 'loading' ? t('button.loading') : t("button.download-excel-report")}</Button>
    </div>}
  </Form>

}