import React, {useContext, useEffect, useRef, useState} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Installation} from "../../../common/Installation";
import {Alert, Cascader, Col, DatePicker, Form, Input, Row, Space} from "antd";
import EntitySelect from "../../../component/EntitySelect";
import LocationPicker from "../../component/LocationPicker";
import TranslateInput from "../../../component/TranslateInput";
import dayjs from "dayjs";
import HintedLabel from "../../../component/HintedLabel";
import {Trans, useTranslation} from "react-i18next";
import _ from "lodash"
import CodeListContext from "../../../context/CodeListContext";
import UnlocodeSelect from "../../component/UnlocodeSelect";

export interface BasicStageProps extends StageProps<Installation, Installation.Basic> {
  anonymous?: boolean
  year?: number
  reportingPeriod?: string
}

export default (props: BasicStageProps) => {
  const Form = useStageForm(props)
  const [alertRouteChange, setAlertRouteChange] = useState(false)
  const {value, anonymous, formRef, root, disabled} = props
  const [region, setRegion] = useState<string>(value?.Address?.Country || "CN")
  const startDate = value?.StartDate ? dayjs(value?.StartDate) : undefined
  const {CL_ProductionRoutes} = useContext(CodeListContext)
  const {t} = useTranslation()
  const originalProcessesAndRoutesRef = useRef<string[][]>(value?.ProcessesAndRoutes || [])

  return <Form
    initialValues={{
    StartDate: startDate,
    EndDate: value?.EndDate ? dayjs(value?.EndDate) : undefined,
    Name: value?.Name || (value?.Entity?.name.native && startDate && t('value.default-installation-name').replace("{0}", value.Entity.name.native).replace("{1}", String(startDate.year())))
  }} onValuesChange={(cv, v) => {
    if (cv.ProcessesAndRoutes) setAlertRouteChange(_.some(
      originalProcessesAndRoutesRef.current, originalItem => !_.some(
        cv.ProcessesAndRoutes, currentItem => _.isEqual(originalItem, currentItem)
      )
    ))
    if (cv.Address?.Country) setRegion(cv.Address?.Country)
  }}
  >
    <Row>
      <Col span={12}><Form.Item
        name={"StartDate"}
        label={
          <HintedLabel title={t('field.report-period-start-date')}>
            <Trans i18nKey={'description.according-to-guidelines-reporting-period-is'}><a href={"https://taxation-customs.ec.europa.eu/system/files/2023-12/Guidance%20document%20on%20CBAM%20implementation%20for%20installation%20operators%20outside%20the%20EU.pdf"}/></Trans>
            <ul>
              <li>{t('description.reporting-period-definition-list-1')}</li>
              <li>{t('description.reporting-period-definition-list-2')}</li>
              <li>{t('description.reporting-period-definition-list-3')}</li>
            </ul>
          </HintedLabel>}
        required={true}
        rules={[{required: true}]}
      ><DatePicker/></Form.Item></Col>
      <Col span={12}><Form.Item
        name={"EndDate"}
        label={t('field.report-period-end-date')}
        required={true}
        rules={[{required: true}, {validator: (o, v) => {
            const startDate = dayjs(formRef?.current?.getFieldValue("StartDate"))
            const endDate = dayjs(v)
            // endDate - startDate > 3 months
            if (endDate.diff(startDate, "month") < 3) return Promise.reject(t("validation.report-period-too-short"))
            // endDate should be in the past
            if (endDate.isAfter(dayjs())) return Promise.reject(t("validation.report-period-in-the-future"))
            return Promise.resolve()
          }}]}
      ><DatePicker/></Form.Item></Col></Row>
    <Form.Item
      name={"Name"}
      required={true}
      rules={[{required: true}]}
      label={
        <HintedLabel title={t("field.report-name")}>{t('description.report-name')}</HintedLabel>}
    >
      <Input placeholder={t("description.report-name")}/>
    </Form.Item>
    <Form.Item name={"Entity"} label={<HintedLabel title={t("field.manufacturer")}>
      <p>{t('description.installation-has-been-predefined')}</p>
      <ul>
        <li>{t('imperative.installation-predefined-actions-if-importer')}</li>
        <li><Trans i18nKey={'imperative.installation-predefined-actions-if-exporter'}><a href={"/apps/wegreenpass"}/></Trans></li>
      </ul>
    </HintedLabel>} required={true} rules={[{required: true}]}>
      <EntitySelect
        anonymous={anonymous}
        disabled={true}
        role={'manufacturer'}
        type={"card"}
        name={t("field.manufacturer")}
        required={true}
      />
    </Form.Item>

    <Form.Item name={"Importer"} label={<Space>{t("field.importer")}</Space>} required={true} rules={[{required: true}]}>
      <EntitySelect
        anonymous={anonymous}
        disabled={anonymous || disabled}
        role={'importer'}
        type={"card"}
        name={t("field.importer")}
        required={true}
      />
    </Form.Item>
    <Form.Item
      name={"InstallationName"}
      label={t('field.installation-name')}
      required={true}
      rules={[{required: true}]}
    >
      <TranslateInput capitalize={'all'}/>
    </Form.Item>
    <Form.Item
      name={"Address"}
      label={t('field.installation-address')}
      required={true}
      rules={[{required: true}]}
    >
      <LocationPicker/>
    </Form.Item>
    <Form.Item
      name={["UNLOCODE"]}
      label={<HintedLabel title={"UNLOCODE"}>{t('description.unlocode-format')}</HintedLabel>}
      required={true}
    >
      <UnlocodeSelect region={region}/>
    </Form.Item>
    <Form.Item
      name={"ProcessesAndRoutes"}
      label={t("field.installation-category")}
      required={true}
      rules={[{required: true, validator: (o, v) => {
          const missing = _(root?.constraints?.ProcessesAndRoutesMustInclude)
            .filter(required => !_.some(v, tags => tags[0] === required)) // Remove met requirements
            .map(required => CL_ProductionRoutes.label(required)) // Convert to label
            .value()
          return missing.length === 0 ? Promise.resolve() : Promise.reject(t("validation.installation-category-must-include", {missing}));
        }}]}
    >
      <Cascader
        options={CL_ProductionRoutes.options()}
        multiple={true}
        showCheckedStrategy={Cascader.SHOW_CHILD}
      />
    </Form.Item>
    {alertRouteChange &&
        <Alert type={'warning'} message={t('validation.fewer-route-may-lose-data')} showIcon/>}
  </Form>
}