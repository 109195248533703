import { useTranslation } from "react-i18next";
import _ from "../../../common/lodash";
import { StageProps, useStageForm } from "../Stage";
import "./AdvertiseStage.scss";

export default (props: StageProps<any>) => {
  const Form = useStageForm(props)
  const {t, i18n} = useTranslation()
  return <Form className={'advertise-stage'}>
    <p>{t('imperative.please-pay-attention-to-other-cbam-items')}</p>
    <img
      src={i18n.language.startsWith('zh') ? "/images/cbam-advertise-zh.svg" : "/images/cbam-advertise-en.svg"}
      alt={"CBAM Advertisement"}
    />
  </Form>
}