import React, {useContext, useEffect} from "react";

import AccountContext from "../context/AccountContext";
import useNavigate from "../hook/useNavigate";

export default () => {
  const {requireAccount} = useContext(AccountContext)
  const navigate = useNavigate()
  useEffect(() => {
    requireAccount()
    navigate('/home')
  })
  return <></>
}