import { PhoneOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Input, InputNumber, Radio, Select, Space } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Declaration } from "../../../common/Declaration";
import _ from "../../../common/lodash";
import HintedLabel from "../../../component/HintedLabel";
import RegionSelect from "../../../component/RegionSelect";
import { SalesIQ } from "../../../component/SalesIQ";
import TranslateInput from "../../../component/TranslateInput";
import CodeListContext from "../../../context/CodeListContext";
import { ObjectInputSelect } from "../../component/ObjectSelect";
import TabularForms from "../../component/TabularForms";
import Good from "../../model/Good";
import { StageProps, useStageForm } from "../Stage";


export interface GoodStageState {
  requestedProc?: string
  previousProc?: string
}


export default (props: StageProps<Declaration, Good>) => {
  const Form = useStageForm(props)
  const [state, setState] = useState<GoodStageState>({})
  const {t} = useTranslation()
  const {CL_HarmonizedCodes, CL_HarmonizedCodeCategories, CL_Regions, CL_Procedures, CL_MeasurementUnits, CL_ProcedureDescriptions, CL_ImportArea} = useContext(CodeListContext)

  return <Form layout={"vertical"}>
    <Form.Item
      name={"Description"}
      label={
        <HintedLabel title={t("field.name")}>{t('description.name-match-other-declaration-processes')}</HintedLabel>}
      required={true}
      rules={[{required: true}]}
    >
      <TranslateInput capitalize={'first'}/>
    </Form.Item>
    <Form.Item
      name={"CategoryCode"}
      label={t("field.category-code")}
      required={true}
      rules={[{required: true}]}
    >
      <ObjectInputSelect
        name={t("field.category-code")}
        renderItem={(item) => <Space>{item?.key}{CL_HarmonizedCodes.label(item?.key)}</Space>}
        data={(search?: string) => CL_HarmonizedCodes.options().filter(_ =>
          search === undefined ||
          CL_HarmonizedCodeCategories.label(_.attrs?.category).includes(search) ||
          _.value.includes(search) ||
          Object.values(_.labels).join(' ').includes(search)
        )}
        columns={_.toAntdColumns({
          [t('field.code')]: 'value',
          [t('field.category')]: v => CL_HarmonizedCodeCategories.label(v.attrs.category),
          [t('field.description')]: 'label'
        })}
        empty={<Alert
          message={<Space>{t("validation.category-not-found")}<Button
            icon={<PhoneOutlined/>}
            type={'primary'}
            onClick={SalesIQ.startChat}
          >{t('button.consult-now')}</Button></Space>} type={"warning"}
        />}
      />
    </Form.Item>
    <Form.Item
      name={"OriginCountry"}
      label={t("field.origin-region")}
      required={true}
      rules={[{required: true}]}
    >
      <RegionSelect options={CL_Regions.options({'cbamRegionType': 'ThirdRegion'})}/>
    </Form.Item>
    <Form.Item
      label={t("field.imported-quantities")} required={true} name={"ImportedQuantities"} rules={[{
      validator: (o, v: Declaration.ImportedQuantity[]) => {
        return v && _.uniq(v.map(_ => _.MeasurementUnit)).length > 1 ? Promise.reject(o.message) : Promise.resolve();
      }, message: t('validation.all-declaration-must-use-same-units')
    }]}
    >
      <TabularForms
        name={t("field.imported-batch")} columns={_.toAntdColumns({
        [t('field.description')]: 'Description',
        [t('field.requested-procedure')]: _ => CL_Procedures.label(_.RequestedProc),
        [t('field.net-mass')]: _ =>
          <Space wrap={true}>{_.NetMass} {CL_MeasurementUnits.label(_.MeasurementUnit)}</Space>
      })} onDialogValuesChange={(i?: number, cv?: any, v?: any) => {
        if (v?.RequestedProc !== state.requestedProc || v?.PreviousProc !== state.previousProc)
          setState({
            requestedProc: v?.RequestedProc,
            previousProc: v?.PreviousProc
          })
      }}
      >
        <Form.Item label={t("field.import-batch-name")} name={"Description"} required={true}>
          <Input placeholder={t("description.import-batch-name")}/>
        </Form.Item>
        <Form.Item
          label={t("field.requested-procedure")}
          name={"RequestedProc"}
          required={true}
        ><Select
          options={CL_Procedures.options({types: 'requested'})}
          optionRender={_ => <div className={"multiline-select-option"}>{_.label}</div>}
        /></Form.Item>
        {state.requestedProc &&
            <Alert
                message={CL_ProcedureDescriptions.label(state.requestedProc)}
                type={"info"}
                showIcon
            />}
        <Divider/>
        <Form.Item
          label={t("field.previous-procedure")}
          name={"PreviousProc"}
          required={true}
          rules={[{required: true, message: t('validation.required')}]}
        ><Select options={CL_Procedures.options({types: 'previous'})}/></Form.Item>
        {state.previousProc &&
            <Alert
                message={CL_ProcedureDescriptions.label(state.previousProc)}
                type={"info"}
                showIcon
            />}
        <Divider/>

        <Form.Item
          label={<HintedLabel title={t("field.import-area")}>{t('description.import-area-recommends-default')}</HintedLabel>}
          required={true}
          name={"ImportArea"}
          initialValue={"EU"}
        ><Radio.Group options={CL_ImportArea.options()}/></Form.Item>
        <Form.Item label={t("field.net-mass")} required={true}><Space.Compact>
          <Form.Item
            name={"NetMass"}
            rules={[{required: true, message: t("validation.required")}, {
              type: "number",
              min: 1E-6,
              message: t("validation.wrong-number")
            }]}
          ><InputNumber
            style={{width: "100px"}}
          /></Form.Item>
          <Form.Item
            name={"MeasurementUnit"}
            initialValue={"01"}
          ><Select options={CL_MeasurementUnits.options()}/></Form.Item>
        </Space.Compact></Form.Item>
      </TabularForms>
    </Form.Item>
  </Form>

}