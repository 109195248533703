import {Button, Divider, Layout} from "antd";
import "./OffsetPage.scss"
import WelcomeBoard from "../../component/WelcomeBoard";
import Lottie from "lottie-react";
import welcomeLottie from "../data/WelcomeLottie.json"
import {PhoneOutlined} from "@ant-design/icons";
import Entity from "../../common/Entity";
import {SalesIQ} from "../../component/SalesIQ";
import NavigationSider from "../component/NavigationSider";
import {useTranslation} from "react-i18next";
import {GET, useApis} from "../../api";
import Loading from "../../component/Loading";

export default () => {
  const {t} = useTranslation()
  const {state, entity} = useApis({
    entity: () => GET<Entity>('/entities/my/default')
  })
  if (state === 'loading') return <Loading/>
  return <Layout className={"offset wegreenpass-page"}>
    <NavigationSider declarant={entity}/>
    <main>
      <WelcomeBoard
        title={t('title.let-us-help-your-offset')}
        icon={<Lottie animationData={welcomeLottie} width={100}/>}
      >
        <p>{t('description.let-us-help-your-offset')}</p>
        <Button
          type={'primary'}
          icon={<PhoneOutlined/>}
          onClick={SalesIQ.startChat}
        >{t('button.contact-us-now')}</Button>
      </WelcomeBoard>
      <Divider/>
    </main>
  </Layout>
}