import { Button, Card, List, Modal, Space, Tag } from "antd";
import { useContext } from "react";

import { ArrowRightOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { GET, useApis } from "../../api";
import Transaction from "../../common/Transaction";
import FlagImage from "../../component/FlagImage";
import Loading from "../../component/Loading";
import CodeListContext from "../../context/CodeListContext";
import useNavigate from "../../hook/useNavigate";
import PlanTag from "../component/PlanTag";
import ConfettiLottie from "../data/ConfettiLottie.json";
import "./PaymentReturnDialog.scss";

export interface PaymentReturnDialogProps {
  open: boolean
  onReturn: () => void
}

export default ({open, onReturn}: PaymentReturnDialogProps) => {
  const {t} = useTranslation()
  const {WC_Roles} = useContext(CodeListContext)
  const transactionUuid = new URLSearchParams(window.location.search).get("transaction")
  const navigate = useNavigate()
  const {transaction, state} = useApis({
    transaction: transactionUuid && (() => GET<Transaction>(`/transactions/${transactionUuid}`))
  })

  return <Modal
    open={open}
    footer={null} width={800}
    closable={false}
  >
    <div className={"payment-return-dialog"}>
      {(!transaction?.status || transaction.status === 'pending') && <Space><Loading/>{t('validation.loading-payment-result')}</Space>}
      {(transaction?.status === 'success' || transaction?.status === 'delivered') && typeof transaction.Entity === 'object' && transaction.Entity.attrs && <>
          <Lottie
              animationData={ConfettiLottie} className={"lottie"}
          />
          <p>{t('imperative.you-have-subscribed')}</p>
          <Card bordered={false} className={`card ${transaction.Entity.attrs["wegreenpass-product-name"]}`}>
              <main>
                  <List.Item.Meta
                      title={
                        <Space wrap direction={'vertical'}>
                          <Space><FlagImage iso3166={transaction.Entity.region}/><span>{transaction.Entity.name.native}</span></Space><span style={{color: 'gray'}}>{transaction.Entity.name.en}</span>
                          <Space>
                          <PlanTag
                          entity={transaction.Entity}
                        /><Tag>{WC_Roles.label(transaction.Entity?.attrs["cbam-role"])}</Tag></Space>

                        </Space>}
                  />
              </main>
          </Card>

          <Space><Button
              type={"primary"}
              icon={<ArrowRightOutlined/>}
              onClick={() => navigate("/apps/wegreenpass", {library: 'native'})}
          >{t('button.enable-wegreenpass')}</Button></Space>
      </>}
    </div>

  </Modal>
  
}