import React, {useContext} from "react";
import {CrownFilled} from "@ant-design/icons";
import {Space, Tag} from "antd";
import Entity from "../../common/Entity";
import "./PlanTag.scss"
import CodeListContext from "../../context/CodeListContext";

export interface PlanTagProps {
  entity?: Entity

}

export default ({entity}: PlanTagProps) => {
  const {WC_Roles, WC_Products} = useContext(CodeListContext)
  if (!entity) return null
  const name = entity?.attrs["wegreenpass-product-name"] || 'none'
  if (name === 'none') return <Tag className={'plan-tag'}>{WC_Roles.label(entity.attrs["cbam-role"])}</Tag>
  const color = WC_Products.attr( name, "color")
  return <Tag className={'plan-tag'} color={color}><Space><CrownFilled style={{color: 'white'}}/>{WC_Products.label(name)}
  </Space></Tag>
}