import { Alert, Button, Divider, Flex, Layout, Space, Tour } from "antd";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Entity from "../../common/Entity";
import Loading from "../../component/Loading";
import WelcomeBoard from "../../component/WelcomeBoard";
import DeclarationList from "../component/DeclarationList";
import DescriptiveIcon from "../component/DescriptiveIcon";
import NavigationSider from "../component/NavigationSider";
import welcomeLottie from "../data/WelcomeLottie.json";
import PaymentReturnDialog from "../dialog/PaymentReturnDialog";
import SelectDeclarantDialog from "../dialog/SelectDeclarantDialog";
import WelcomeDialog from "../dialog/WelcomeDialog";
import "./HomePage.scss";

import _ from "lodash";
import { GET, POST, useApis } from "../../api";
import IconFont from "../../component/IconFont";
import InstallationList from "../component/InstallationList";

export type HomePageDialogType =
  "select-declarant" |
  "payment-return" |
  "welcome" |
  "tour" |
  "loading" |
  null

export default () => {
  const [dialog, setDialog] = useState<HomePageDialogType>('loading')
  const { t, i18n } = useTranslation()
  const { entity, state, reload } = useApis({
    entity: () => GET<Entity>('/entities/my/default')
  })

  useEffect(() => {
    (async () => {
      if (state !== 'success') return
      const search = new URLSearchParams(window.location.search)
      const tour = entity && (await GET<boolean>(`/entities/${entity?._id}/tour`)).tour
      let dialog: HomePageDialogType = null;
      if (search.has("transaction")) dialog = "payment-return"
      else if (!entity) dialog = "welcome"
      else if (tour) dialog = 'tour'
      setDialog(dialog)
    })()
  }, [entity, state])

  if (dialog === 'loading' || state !== 'success') return <Loading fullscreen />
  return <Layout className={"home wegreenpass-page"}>
    <NavigationSider declarant={entity} />
    <main>
      <WelcomeBoard
        title={t('title.welcome')}
        icon={<Lottie animationData={welcomeLottie} width={100} />}
      >
        <p>{t('description.welcome')}</p>
        <Flex wrap={'wrap'} gap={18}>
          <DescriptiveIcon
            title={t('title.steel-and-aluminium')}
            icon={<IconFont type={'icon-steel'} />}
            description={t('description.construction-materials')}
          />
          <DescriptiveIcon title={t('title.cement')} icon={<IconFont type={'icon-cement'} />} />
          <DescriptiveIcon icon={<IconFont type={'icon-fertilizer'} />} title={t('title.fertilizer')} />
          <DescriptiveIcon title={t('title.electricity')} icon={<IconFont type={'icon-electricity'} />} />
          <DescriptiveIcon title={t('title.hydrogen')} icon={<IconFont type={'icon-hydrogen'} />} />
          <DescriptiveIcon icon={<IconFont type={'icon-plastic'} />} title={t('title.plastic') + "*"} />
        </Flex>

      </WelcomeBoard>
      <Divider />
      <div className={"content"}>
        <div className={"left"}>
          <Space><h2>{t('title.declaration-importer')}</h2></Space>
          <p>{t('description.declaration-importer')}</p>
          {entity && ['importer', 'representative'].includes(entity?.attrs["cbam-role"]) ?
            <DeclarationList declarant={entity} /> : <Alert
              message={t("validation.declaration-importer-ineligible")}
              type="info"
              showIcon
            />
          }
          <Divider />
          <Space><h2>{t('title.declaration-exporter')}</h2></Space>
          <p>{t('description.declaration-exporter')}</p>
          {entity && ['exporter', 'manufacturer'].includes(entity?.attrs["cbam-role"]) ?
            <InstallationList declarant={entity} /> : <Alert
              message={t("validation.declaration-exporter-ineligible")}
              type="info"
              showIcon
            />
          }
          <p className={'description'}>{t('description.expected-plastic-policy')}</p>
        </div>
        {i18n.language.startsWith("zh") && <div className={"right"}>
          <h2>{t('title.global-news')}</h2>
          <iframe src={"https://we-carbon.com/news-embedded"} />
          <Button target={'_blank'} href={"https://we-carbon.com/news/"}>{t('button.more-news')}</Button>
        </div>}
      </div>
    </main>
    <SelectDeclarantDialog
      open={dialog === "select-declarant"}
      value={entity}
      onChange={async (o) => {
        if (!o) return
        await POST('/entities/my/default', { declarantEntity: o._id })
        await reload('entity')
      }}
    />
    <PaymentReturnDialog
      open={dialog === "payment-return"}
      onReturn={() => {
        window.history.replaceState(null, "", window.location.pathname);
        // Close the dialog
        setDialog(null)
      }}
    />
    <WelcomeDialog
      open={dialog === "welcome"}
      onFinish={() => setDialog('select-declarant')}
    />
    <Tour open={dialog === 'tour'} onClose={() => setDialog(null)} steps={_.compact([
      {
        title: t('title.tour-welcome-to-wegreenpass'),
        description: t('description.tour-welcome-to-wegreenpass')

      },
      ['exporter', 'manufacturer'].includes(entity?.attrs?.['cbam-role'] || "") && {
        title: t('title.tour-add-new-installation'),
        description: t('description.tour-add-new-installation'),
        target: () => document.querySelector(".installation-list .create-button") as HTMLElement,
      },
      ['importer', 'representative'].includes(entity?.attrs?.['cbam-role'] || "") && {
        title: t('title.tour-add-new-declaration'),
        description: t('description.tour-add-new-declaration'),
        target: () => document.querySelector(".declaration-list .create-button") as HTMLElement,
      },
      {
        title: t('title.tour-contact-us'),
        description: t('description.tour-contact-us'),
        target: () => document.querySelector("#zsiq_float") as HTMLElement,
      },
      {
        title: t('title.tour-settings'),
        description: t('description.tour-settings'),
        target: () => document.querySelector("#page-container-user") as HTMLElement,
      },
      {
        title: t('title.reduction-and-offset'),
        description: t('description.reduction-and-offset'),
        target: () => document.querySelector("#navigation-sider-menu") as HTMLElement,
      }
    ])} />
  </Layout>
}