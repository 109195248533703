import React, {useState} from "react";
import {Divider, Layout, Space} from "antd";
import Entity from "../../common/Entity";
import Loading from "../../component/Loading";
import NavigationSider from "../component/NavigationSider";
import DeclarationList from "../component/DeclarationList";
import InstallationList from "../component/InstallationList";
import {useTranslation} from "react-i18next";
import {GET, useApis} from "../../api";


export default () => {
  const [ready, setReady] = useState(false)
  const {entity, state} = useApis({
    entity: () => GET<Entity>('/entities/my/default')
  })
  const {t} = useTranslation()
  if (state !== 'success') return <Loading state={state} fullscreen/>
  return <Layout className={"home wegreenpass-page"}>
    <NavigationSider declarant={entity}/>
    <main>
      <Divider/>
      <div className={"content"}>
        <div className={"left"}>
          {entity && ['importer', 'representative'].includes(entity?.attrs["cbam-role"]) && <>
              <Space><h2>{t('title.declaration-importer')}</h2></Space>
              <p>{t('description.declaration-importer')}</p>
              <DeclarationList declarant={entity}/></>
          }
          <Divider/>
          {entity && ['exporter', 'manufacturer'].includes(entity?.attrs["cbam-role"]) &&
              <>
                  <Space><h2>{t('title.declaration-exporter')}</h2></Space>
                  <p>{t('description.declaration-exporter')}</p>
                  <InstallationList declarant={entity}/></>
          }
        </div>
      </div>
    </main>
  </Layout>
}