import {Trans, useTranslation} from "react-i18next";
import {Button, Input, Modal, Table} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {ReactNode, useContext, useEffect, useState} from "react";
import {ColumnsType, ColumnType} from "antd/es/table";
import {TableRowSelection} from "antd/es/table/interface";
import {AnyObject} from "antd/es/_util/type";
import {RowClassName} from "rc-table/lib/interface";
import DisabledContext from "antd/es/config-provider/DisabledContext";

export interface ObjectSelectDialogProps<T> {
  name: string
  renderTitle?: ReactNode | ReactNode[] | ((s?: ReactNode | ReactNode[]) => (ReactNode | ReactNode[]))
  onAdd?: (value?: T) => void
  open: boolean
  onClose: () => void
  onChange?: (value?: T) => void
  data?: T[] | ((search?: string) => Promise<T[] | undefined> | T[] | undefined)
  columns?: ColumnsType<T>
  empty?: ReactNode | ReactNode[]
  className?: string
  getCheckboxProps?: TableRowSelection<T>["getCheckboxProps"]
  selectDialogHeader?: ReactNode[] | ReactNode
  rowClassName?: RowClassName<T>
  disabled?: boolean
}

export default function <T extends AnyObject>({renderTitle, onAdd, className, open, onClose, columns, selectDialogHeader, data, empty, name, getCheckboxProps, rowClassName, onChange, disabled}: ObjectSelectDialogProps<T>) {
  const [filteredData, setFilteredData] = useState<T[]>([])
  const {t} = useTranslation()
  const disabledContext = useContext(DisabledContext)

  useEffect(() => {
    (async () => {
      if (disabled || disabledContext) return
      const filteredData = typeof data === 'function' ? await data() : data
      if (filteredData) setFilteredData(filteredData)
    })()
  }, [data])
  return <Modal
    open={open}
    title={typeof renderTitle === 'function' ? renderTitle(t("button.choose-item", {name})) : t("button.choose-item", {name})}
    onCancel={onClose}
    destroyOnClose
    className={['object-select', 'dialog', className].filter(_ => _).join(" ")}
    width={"min(960px, 75vw)"}
    footer={onAdd ? <Button
      type={"primary"}
      icon={<PlusOutlined/>}
      onClick={() => onAdd?.()}
    >{t("button.add-item", {name: name})}</Button> : null}
  >
    <main>
      {selectDialogHeader}
      {typeof data === 'function' &&
          <Input.Search onChange={async (e) => {
            if (disabled || disabledContext || !data || typeof data !== 'function') return
            const filteredData = await data(e.target.value)
            if (filteredData) setFilteredData(filteredData)
          }} placeholder={t('imperative.search')}/>}
      {(filteredData.length || 0) > 0 ? <Table<T>
        rowClassName={rowClassName}
        rowKey={(_?: T) => (_ as any)?._id || ""}
        dataSource={filteredData}
        columns={columns}
        pagination={(filteredData.length || 0) > 10 ? {pageSize: 10} : false}
        rowSelection={{
          type: "radio",
          onChange: (_, selectedRows) => {
            onChange?.(selectedRows[0])
            onClose()
          },
          getCheckboxProps: getCheckboxProps
        }}
      /> : empty}
    </main>
  </Modal>
}