import {Button, Divider, Layout} from "antd";
import "./ValidationPage.scss"
import WelcomeBoard from "../../component/WelcomeBoard";
import Lottie from "lottie-react";
import WelcomeLottie from "../data/WelcomeLottie.json"
import {PhoneOutlined} from "@ant-design/icons";
import Entity from "../../common/Entity";
import {useTranslation} from "react-i18next";
import {SalesIQ} from "../../component/SalesIQ";
import NavigationSider from "../component/NavigationSider";
import {GET, useApis} from "../../api";
import _ from "../../common/lodash"

export default ()=>{
  const {t} = useTranslation()
  const {entity} = useApis({
    entity: () => GET<Entity>("/entities/my/default")
  })
  return <Layout className={"validation wegreenpass-page"}>
    <NavigationSider declarant={entity}/>
    <main>
      <WelcomeBoard
        title={t('title.let-us-help-your-validation')}
        icon={<Lottie animationData={WelcomeLottie} width={100}/>}
      >
        <p>{t('description.let-us-help-your-validation')}</p>
        <Button type={'primary'} icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}>{t('button.contact-us-now')}</Button>
      </WelcomeBoard>
      <Divider/>
      <h3>{t('title.available-certifications')}</h3>
      <section className={"certificates"}>
        <figure className={"certificate"} onClick={SalesIQ.startChat}>
          <img src={"/images/photo/iso14064.png"} alt={"ISO14064 Certificate Sample"}/>
          <figcaption>{t('product.iso14064-certificate')}</figcaption>
        </figure>
        <figure className={"certificate"} onClick={SalesIQ.startChat}>
          <img src={"/images/photo/iso14067.png"} alt={"ISO14067 Certificate Sample"}/>
          <figcaption>{t('product.iso14067-certificate')}</figcaption>
        </figure>
        <figure className={"certificate"} onClick={SalesIQ.startChat}>
          <img src={"/images/photo/epd.png"} alt={"EPD Certificate Sample"}/>
          <figcaption>{t('product.epd-certificate')}</figcaption>
        </figure>
        <figure className={"certificate"} onClick={SalesIQ.startChat}>
          <img src={"/images/photo/pas2060.png"} alt={"PAS2060 Certificate Sample"}/>
          <figcaption>{t('product.pas2060-declaration')}</figcaption>
        </figure>

      </section>
    </main>
  </Layout>
}