import {Button, Form, Modal, Segmented, Space, Tag, Timeline} from "antd";
import {useTranslation} from "react-i18next";
import ConfettiLottie from "../data/ConfettiLottie.json"
import Lottie from "lottie-react";
import "./WelcomeDialog.scss"
import {ArrowLeftOutlined, ArrowRightOutlined, CrownOutlined} from "@ant-design/icons";
import ShakeHandsLottie from "../data/ShakeHandsLottie.json"
import Diptych from "../component/Diptych";
import {useState} from "react";
import _ from "../../common/lodash"

export interface WelcomeDialogProps {
  open: boolean
  onFinish?: () => void
}

export default ({open, onFinish}: WelcomeDialogProps) => {
  const [page, setPage] = useState(0)
  const [identity, setIdentity] = useState<'importer' | 'exporter'>('importer')
  const {t} = useTranslation()
  return <Modal
    open={open}
    title={<p className={'title'}>{t('title.welcome-to-wegreenpass')}</p>}
    className={'welcome-dialog'}
    footer={
      <div className={'footer'}>
        {page === 0 ? <div></div> : <Button
          type={'text'}
          icon={<ArrowLeftOutlined/>}
          onClick={() => setPage(page - 1)}
        ><span>{t('button.previous')}</span></Button>}
        {page === 3 ? <Button
          type={'primary'}
          onClick={onFinish}
        ><span>{t('button.start')}</span></Button> : <Button
          type={'text'}
          icon={<ArrowRightOutlined/>}
          onClick={() => setPage(page + 1)}
        ><span>{t('button.next')}</span></Button>}
      </div>

    }
    closable={false}
    width={"720px"}
  >
    {page === 0 && <div>
      <Lottie animationData={ConfettiLottie} className={'lottie'}/>
      <p>{t('description.wegreenpass')}</p>
      <p>{t('imperative.press-next-to-start-journey')}</p>
    </div>}
    {
      page === 1 && <div>
        <Form.Item label={t('imperative.understand-by-entity')} initialValue={'importer'}>
          <Segmented
            options={[{
              label: t('value.importer'),
              value: 'importer'
            }, {
              label: t('value.exporter'),
              value: 'exporter'
            }]} onChange={(v: any) => setIdentity(v)}
          />
        </Form.Item>
        <Timeline
          items={_([
            {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-add-declarant')}</h4>
                <span>{t('description.welcome-step-add-declarant')}</span>
              </div>
            },
            {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-create-report')}</h4>
                <span>{
                  identity === 'importer' ?
                    t('description.welcome-step-create-report-importer') :
                    t('description.welcome-step-create-report-exporter')}</span>
              </div>
            },
            identity === 'importer' ? {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-collect-info')}</h4>
                <span>{t('description.welcome-step-collect-info')}</span>
              </div>
            } : undefined,
            {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-team-check')}</h4>
                <span>{t('description.welcome-step-team-check')}</span>
              </div>
            },
            identity === 'importer' && {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-submit-report')}</h4>
                <span>{t('description.welcome-step-submit-report')}</span>
              </div>
            },
            identity === 'exporter' && {
              children: <div className={'timeline-item'}>
                <h4>{t('title.welcome-step-submit-report')}</h4>
                <span>{t('description.welcome-step-submit-report-exporter')}</span>
              </div>
            },
            identity === 'importer' && {
              children: <div className={'timeline-item'}>
                <h4><Space>
                  {t('title.welcome-step-follow-up')}
                  <Tag
                  color={'yellow'}
                  icon={<CrownOutlined/>}
                >{t('value.expert-version-service')}</Tag>
                </Space></h4>
                <span>{t('description.welcome-step-follow-up')}</span>
              </div>
            },
            identity === 'exporter' && {
              children: <div className={'timeline-item'}>
                <h4><Space>
                  {t('title.welcome-step-follow-up')}
                  <Tag color={'#2C528F'} icon={<CrownOutlined/>}>{t('value.pro-version-service')}</Tag>
                  <Tag color={'yellow'} icon={<CrownOutlined/>}>{t('value.expert-version-service')}</Tag>
                </Space></h4>
                <span>{t('description.welcome-step-follow-up')}</span>
              </div>
            },
          ]).compact().map((o, i) => ({...o, dot: <div className={'timeline-dot'}>{i + 1}</div>})).value()}
        />
      </div>
    }
    {
      page === 2 && <div>
        <p>{t('title.wegreenpass-provides-full-cycle-service')}</p>
        <div className={'diptych-grid'}>
          <Diptych
            back={{title: t('title.question-accounting'), description: t('description.question-accounting')}}
            front={{title: t('title.solution-accounting'), description: t('description.solution-accounting')}}
          />
          <Diptych
            back={{title: t('title.question-declaration'), description: t('description.question-declaration')}}
            front={{title: t('title.solution-declaration'), description: t('description.solution-declaration')}}
          />
          <Diptych
            back={{title: t('title.question-collection'), description: t('description.question-collection')}}
            front={{title: t('title.solution-collection'), description: t('description.solution-collection')}}
          />
          <Diptych
            back={{title: t('title.question-verification'), description: t('description.question-verification')}}
            front={{title: t('title.solution-verification'), description: t('description.solution-verification')}}
          />
          <Diptych
            back={{title: t('title.question-regulatory'), description: t('description.question-regulatory')}}
            front={{title: t('title.solution-regulatory'), description: t('description.solution-regulatory')}}
          />
          <Diptych
            back={{title: t('title.question-reduction-offset'), description: t('description.question-reduction-offset')}}
            front={{title: t('title.solution-reduction-offset'), description: t('description.solution-reduction-offset')}}
          />

        </div>
      </div>
    }
    {
      page === 3 && <div>
        <Lottie animationData={ShakeHandsLottie} className={'lottie'}/>
        <p className={'slogan'}>{t('title.compliance-starts-from-today')}</p>
      </div>
    }
  </Modal>

}