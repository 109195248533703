import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import IFormItemProps from "../../form/IFormItemProps";
import {Checkbox} from "antd";
import "./Disclaimer.scss"
import useStaticHtml from "../../hook/useStaticHtml";

export default ({value, onChange}: IFormItemProps<boolean>) => {
  const {t, i18n} = useTranslation()
  const [name, setName] = useState<string>()
  const html = useStaticHtml(name)

  return <div className={"disclaimer"}>
    <Checkbox
      checked={value}
      value={value}
      onChange={e => onChange?.(e.target.checked)}
      className={'check-box'}
    ><Trans i18nKey={'value.i-agree-above-disclaimer'}>
      <a target={'_blank'} href={"https://we-carbon.com/privacy"}/>
      <a onClick={() => setName('disclaimer')}/>
      <a onClick={() => setName('cross-border')}/>
      </Trans></Checkbox>
    {html && <main dangerouslySetInnerHTML={{__html: html}}/>}
  </div>
}