import React from "react";
import {StageProps} from "../Stage";
import {useTranslation} from "react-i18next";
import {Installation} from "../../../common/Installation";

export default (props: StageProps<Installation>) => {
  const {t} = useTranslation()
  return <>
    <h3>{t('imperative.report-is-ready-press-to-submit')}</h3>
    <p>{t('validation.you-cannot-review')}</p>
  </>
}