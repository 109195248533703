import {useTranslation} from "react-i18next";
import Sider from "antd/es/layout/Sider";
import DeclarantCard from "./DeclarantCard";
import {Menu} from "antd";
import Entity from "../../common/Entity";

import "./NavigationSider.scss"
import {CustomerServiceOutlined, FolderOpenFilled, HomeOutlined} from "@ant-design/icons";
import {SalesIQ} from "../../component/SalesIQ";
import {POST} from "../../api";
import useNavigate from "../../hook/useNavigate";

export interface NavigationSiderProps {
  declarant?: Entity
}

export default ({declarant}: NavigationSiderProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return <Sider className={"navigation-sider"} width={"300px"}>
    <div className={'navigation'}>
      <main>
        <DeclarantCard
          value={declarant}
          onChange={async (v) => v && POST('/entities/my/default', {declarantEntity: v._id})}
          required
        />
        <Menu items={[
          {label: t("button.home"), key: "home", icon: <HomeOutlined/>, className: "menu-item", onClick: () => navigate("/apps/wegreenpass")},
          {type: "divider"},
          {label: t("button.my-declarations"), key: "declarations", icon: <FolderOpenFilled/>, onClick: () => navigate("/apps/wegreenpass/documents")},
          {label: t("button.mrv"), key: "certifications", icon: <FolderOpenFilled/>, onClick: () => navigate("/apps/wegreenpass/validation")},
          {label: t("button.reduction-and-offsets"), key: "offsets", icon: <FolderOpenFilled/>, onClick: () => navigate("/apps/wegreenpass/offset")},
          {type: "divider"},
          {label: t("button.customer-service"), key: "services", icon: <CustomerServiceOutlined/>, onClick: SalesIQ.startChat},
        ]} className={"menu"} id={"navigation-sider-menu"}/>
      </main>
      <footer>{t('description.copyright')}</footer>

    </div>
  </Sider>
}