import {StageProps, useStageForm} from "../Stage";
import {Declaration} from "../../../common/Declaration";
import EntitySelect from "../../../component/EntitySelect";
import React, {useCallback, useContext, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import TranslateInput from "../../../component/TranslateInput";
import {Alert, Button, FormInstance, InputNumber, message, Popover, Radio, Select, Space} from "antd";
import {ObjectInputSelect} from "../../component/ObjectSelect";
import {GET, POST} from "../../../api";
import {Installation} from "../../../common/Installation";
import _ from "../../../common/lodash"
import {
  AlertOutlined,
  CloseCircleFilled,
  EditOutlined,
  ReloadOutlined,
  RightOutlined,
  UnlockOutlined,
  WarningOutlined
} from "@ant-design/icons";
import CopyInput from "../../../component/CopyInput";
import TabularForms from "../../component/TabularForms";
import CodeListContext from "../../../context/CodeListContext";
import Entity from "../../../common/Entity";
import Loading from "../../../component/Loading";
import StatusTag from "../../component/StatusTag";

export default (props: StageProps<Declaration, Declaration.InstallationSlot>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  const ref = useRef<FormInstance<any>>(null)
  const {CL_MeasurementUnits} = useContext(CodeListContext)
  const {value, root, formRef, index} = props
  const [slot, setSlot] = useState<Declaration.InstallationSlot | undefined>(value)
  const [slotLoading, setSlotLoading] = useState<boolean>(false)
  const [installationId, setInstallationId] = useState<string | undefined>()
  const url = root && slot ? `${window.location.protocol}//${window.location.host}/apps/wegreenpass/declarations/${root._id}/slots?token=${slot.Jwt}` : ''

  return <Form
    layout={'vertical'} onValuesChange={async (cv, v) => {
    if (cv.status) setSlotLoading(true)
    const {slot: newSlot} = await POST<Declaration.InstallationSlot>(`/cbam/declarations/${root?._id}/slots/${slot?.Uuid}/initialize`, v)
    if (newSlot){
      setSlot(newSlot)
      formRef?.current?.setFieldsValue(newSlot)
    }
    setInstallationId(v.InstallationObject?._id)
    if (cv.status) setSlotLoading(false)
  }}
  >
    <Form.Item
      name={'Entity'}
      label={t("field.manufacturer")}
      required={true}
      rules={[{required: true, message: t('validation.required')}]}
    >
      <EntitySelect
        role={'manufacturer'}
        type={"card"}
        name={t("field.manufacturer")}
      />
    </Form.Item>
    <Form.Item
      name={'WeightedGoods'}
      label={t('field.installation-good-mapping')}
      required={true}
    >
      <TabularForms
        name={t("field.good")}
        title={t('field.installation-good-mapping')}
        formRef={ref}
        columns={_.toAntdColumns({
          [t('field.good')]: o => o.Good.Description.native,
          [t('field.net-mass')]: _ =>
            <Space wrap={true}>{_.NetMass} {CL_MeasurementUnits.label(_.MeasurementUnit)}</Space>
        })}
      >
        <Form.Item name={'Good'} label={t('field.good')} required={true} rules={[{required: true}]}>
          <ObjectInputSelect
            name={t('field.good')} data={root?.goods} columns={_.toAntdColumns({
            [t('field.good')]: o => o.Description.native
          })} renderItem={o => o?.Description?.native}
          />
        </Form.Item>
        <Form.Item label={t('field.net-mass')} required={true} rules={[{required: true}]}><Space.Compact>
          <Form.Item
            name={"NetMass"}
            noStyle
            rules={[{required: true, message: t("validation.required")}, {
              type: "number",
              min: 1E-6,
              message: t("validation.wrong-number")
            }, {validator: (o, v) => _.sumBy(ref.current?.getFieldValue('Good').ImportedQuantities, "NetMass") < v ? Promise.reject(t('validation.exceeding-good-limit')) : Promise.resolve()}]}
          ><InputNumber className={'input-number'}/></Form.Item>
          <Form.Item
            name={"MeasurementUnit"}
            noStyle
            initialValue={"01"}
          ><Select options={CL_MeasurementUnits.options()}/></Form.Item>
        </Space.Compact></Form.Item>
      </TabularForms>
    </Form.Item>
    <h3>{t('title.how-do-you-plan-manufacturing-info')}</h3>
          <p>{t('description.we-need-installation-data')}</p>
          <Form.Item name={'Mode'}>
              <Radio.Group  disabled={slot?.Mode === 'public' && slot?.status === 'submitted'}>
                  <Space direction={'vertical'}>
                      <Radio value={"public"}>
                        {t('value.data-entry-mode-public')}
                      </Radio>
                      <Radio value={"private"}>
                        {t('value.data-entry-mode-private')}
                      </Radio>
                  </Space>
              </Radio.Group>
          </Form.Item>
    {slot?.Entity && <>
      {slot?.status === 'submitted' && slot?.Mode === 'public' && <>
          <Alert
              type={"success"}
              showIcon
              message={<Space>{t('validation.report-has-already-submitted')}<Button
                icon={<EditOutlined/>}
                onClick={() => window.open(`/apps/wegreenpass/installations/${_.id(slot?.Installation)}`)}
              >{t('button.edit-report')}</Button><Button
                icon={<UnlockOutlined/>} onClick={async () => {

                const {slot: newSlot} = await POST<Declaration.InstallationSlot>(`/cbam/declarations/${root?._id}/slots/${slot?.Uuid}/clear`)
                if (newSlot) {
                  setSlot(newSlot)
                  window.location.reload()
                }
              }
              }
              >{t('validation.change-again')}</Button></Space>}
          />
      </>}

      {slotLoading && <Loading/>}
      {!slotLoading && slot?.Mode === 'public' && slot?.status !== 'submitted' && root && <>
          <h3>{t('title.installation-link')}</h3>
          <Form.Item
              name={'InstallationName'}
              label={t("field.installation-name")}
              required={true}
              rules={[{required: true, message: t('validation.required')}]}
          >
              <TranslateInput capitalize={'all'}/>
          </Form.Item>
          <p>{t('description.send-and-receive-notif')}</p>
          <CopyInput value={url} onOpen={() => window.open(url, '_blank')}/>
      </>}

      {!slotLoading && slot?.Mode === 'private' && <>

          <p>{t('imperative.choose-one-from-historical-reports')}</p>
          <Form.Item name={'Installation'} required={true} rules={[{required: true}]}>
              <ObjectInputSelect<Installation>
                  name={t('field.installation')}
                  data={() => slot.Entity && GET<Installation[]>(`/cbam/installations/my/${slot.Entity._id}`).then(o => o.installations)}
                  getCheckboxProps={o => ({
                    disabled: o.status !== 'submitted'
                  })}
                  columns={_.toAntdColumns({
                    [t('field.name')]: o => <Space>{o.status !== 'submitted' && <Popover
                      content={t('validation.only-can-choose-completed')}><WarningOutlined
                      style={{color: 'goldenrod'}}/></Popover>}{o.basic?.Name}</Space>,
                    [t('field.status')]: o => <StatusTag status={o.status}/>,
                    [t('field.action')]: o => <Space><Button
                      icon={<EditOutlined/>}
                      href={`/apps/wegreenpass/installations/${o._id}`}
                      target={'_blank'}
                    >{t('button.edit')}</Button></Space>
                  })}
                  renderItem={o => o?.basic?.Name}
                  selectDialogHeader={<Space><Button
                    icon={<ReloadOutlined/>}
                    onClick={() => window.location.reload()}
                  >{t('button.refresh')}</Button></Space>}
              />
          </Form.Item></>
      }</>}
  </Form>


}