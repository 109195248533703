import _ from "../common/lodash";
import EnvUtilData from "../data/EnvUtilData.json"

export type JuryType = "cn" | "intl"

export interface IEnvUtilClass {
  env: "localhost" | "production" | "coder"
  jury: JuryType

}

export interface Language {
  code: string
  flag: string
  name: string
  isd: string
}

export class EnvUtilClass implements IEnvUtilClass {
  static instance = new EnvUtilClass()
  env = _.where<string, IEnvUtilClass['env']>(
    window.location.hostname,
    ["localhost", "localhost"],
    ["127.0.0.1", "localhost"],
    [/coder\.we-carbon\.com$/, "coder"],
    ["^(cn-cloud|cloud).we-carbon.com$", "production"]
  ) || "production"
  salesIq = EnvUtilData.salesiq.widgetCode
  stripe = this.env === 'production' ? EnvUtilData.stripe.production : EnvUtilData.stripe.development
  languages = EnvUtilData.languages

  get jury() {
    if (window.location.hostname === 'cn-cloud.we-carbon.com' || process.env.REACT_JURY === 'cn') return 'cn';
    return 'intl';
  }

  webHost(env?: string, jury?: string) {
    if ((!env || env === this.env) && (!jury || jury === this.jury)) return `//${window.location.host}`
    if (env === 'localhost') return `http://localhost:3000`
    if (env === 'production' && (jury || this.jury) === 'cn') return `https://cn-cloud.we-carbon.com`
    if (env === 'production' && (jury || this.jury) === 'intl') return `https://cloud.we-carbon.com`
    return `https://cloud.we-carbon.com`
  }

  apiHost(env?: string) {
    return _.where(env || this.env,
      ['localhost', `//${window.location.hostname}:3001/v1`],
      ['production', this.jury === 'cn' ? 'https://cn-api.we-carbon.com/v1' : 'https://api.we-carbon.com/v1'],
      ['coder', `//${window.location.hostname.replace('3000', '3001')}/v1`]
    ) || 'https://api.we-carbon.com/v1'

  }

  juryMatchLanguage(language: string) {
    return (EnvUtilClass.instance.jury === 'cn' && ['zh-CN', 'zh-HK'].includes(language)) || (EnvUtilClass.instance.jury === 'intl' && !['zh-CN', 'zh-HK'].includes(language))
  }
}

export default EnvUtilClass.instance