import { useParams } from "react-router-dom"
import { POST, useApis } from "../api"
import Loading from "../component/Loading"
import { Button, Result } from "antd"
import useNavigate from "../hook/useNavigate"
import {useTranslation} from "react-i18next";

export default () => {
  const {id} = useParams()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {state} = useApis({
    account: () => POST("/accounts/verify-email", {id, token: new URLSearchParams(window.location.search).get('token')})
  })
  switch(state){
    case 'loading': return <Loading fullscreen/>
    case 'failed': return <Result status="error" title={t('validation.cannot-validate-email')}/>
    case 'success': return <Result status="success" title={t('validation.email-validated')} extra={<Button type='primary' onClick={() => navigate('/home')}>{t('button.go-back')}</Button>}/>
  }  
}