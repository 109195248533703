import {FormInstance, message, Modal, ModalProps} from "antd";
import React, {RefObject, useContext} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import DisabledContext from "antd/es/config-provider/DisabledContext";

export interface EditDialogProps<T> extends ModalProps {
  formRef?: RefObject<FormInstance>
  onChange?: (data?: T) => void
  value?: T

}

export default function <T>(props: EditDialogProps<T>) {
  const {formRef, onChange, onOk, onCancel, ...rest} = props
  const {t} = useTranslation()
  const disabledContext = useContext(DisabledContext)
  return <Modal {...rest} onOk={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!formRef?.current) return message.error(t('validation.required'))
    if (!disabledContext){
      try {
        await formRef.current?.validateFields()
      } catch (e){
        return message.error(t('validation.cannot-save-please-check'))
      }
    }
    onChange?.(formRef.current.getFieldsValue())
    onOk?.(e)
  }} onCancel={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    formRef?.current?.resetFields()
    onChange?.()
    onCancel?.(e)
  }}/>

}