import { Card, Divider, FormInstance, InputNumber, Select, Space, Statistic, Table } from "antd";
import { Button } from "antd/lib";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { POST } from "../../../api";
import { Declaration } from "../../../common/Declaration";
import _, { IdEqualCustomizer } from "../../../common/lodash";
import Emission from "../../../common/transient/Emission";
import FlagImage from "../../../component/FlagImage";
import HintedLabel from "../../../component/HintedLabel";
import { SalesIQ } from "../../../component/SalesIQ";
import CodeListContext from "../../../context/CodeListContext";
import CallForAction from "../../component/CallForAction";
import { ObjectInputSelect } from "../../component/ObjectSelect";
import TabularForms from "../../component/TabularForms";
import CarbonPriceDue from "../../model/CarbonPriceDue";
import { StageProps, useStageForm } from "../Stage";
import "./CarbonStage.scss";

export interface CarbonStageState {
  euro: number
  currencyDate: string
}

export default (props: StageProps<Declaration, Declaration.Due>) => {
  const Form = useStageForm(props)
  const {root} = props
  const ref = useRef<FormInstance<CarbonPriceDue>>(null)
  const {t} = useTranslation()
  const [state, setState] = useState<CarbonStageState>({
    euro: 0,
    currencyDate: ""
  })
  const {WC_EmissionOutputTypes, WC_CarbonInstruments, CL_Currencies} = useContext(CodeListContext)
  // FIXME: What's this?
  useEffect(() => {
    (async () => {
      if (!root?._id) return
      const formObject: any = ref.current?.getFieldsValue()
      const {status, euro} = await POST(`/cbam/declarations/${root._id}/dues`, {dues: formObject?.dues})
      if (!status.success || !euro) return
      setState({euro, currencyDate: state.currencyDate})
    })()
  }, [])
  async function onDialogValuesChange(index?: number, changedValues?: any, values?: any) {
    if (
      ('LocalCurrencyAmount' in changedValues || 'LocalCurrency' in changedValues) &&
      values.LocalCurrencyAmount &&
      values.LocalCurrency
    ) {
      const response = await POST<number | string | undefined>('/conversions/currency', {currency: values.LocalCurrency, value: values.LocalCurrencyAmount})
      if (response) setState({currencyDate: response?.date as string, euro: state.euro})
      ref.current?.setFieldsValue({EuroAmount: response?.value as number})

    }
  }
  return <Form className={'carbon-stage'}>
    <h2><HintedLabel title={t('title.existing-carbon-offset')}>
      {t('description.existing-carbon-offset')}</HintedLabel></h2>
    <Form.Item name={"dues"}>
      <TabularForms
        name={t('field.carbon-asset')}
        onDialogValuesChange={onDialogValuesChange}
        formRef={ref}
        columns={_.toAntdColumns({
          [t('field.carbon-asset')]: _ => <Space>
            <FlagImage iso3166={_.InstrumentType.attrs?.region}/>
            {_.InstrumentType.label}
          </Space>,
          [t('field.carbon-offset')]: _ => _.EmissionAmount?.toFixed(2) + " tCO2e",
          [t('field.offset-amount-euro')]: _ => _.EuroAmount?.toFixed(2)
        })}
      >
        <Form.Item name={"Good"} label={t("field.good")} required={true} rules={[{required: true}]}>
          <ObjectInputSelect
            name={t("field.good")}
            renderItem={good => good && <Space>{good.Description?.native || good.Description?.en}</Space>}
            data={root?.goods?.map((_, i) => ({..._, key: i}))}
            columns={_.toAntdColumns({
              [t("field.name")]: ["Description", "native"]
            })}
          />
        </Form.Item>
        <Form.Item
          name={"InstrumentType"}
          label={t("field.carbon-asset")}
          required={true}
          rules={[{required: true}]}
        >
          <ObjectInputSelect
            name={t("field.carbon-asset")}
            renderItem={instrument => instrument && <Space>
              <FlagImage iso3166={instrument?.attrs?.region}/>
              {instrument?.label}
            </Space>}
            data={WC_CarbonInstruments.options()}
            columns={_.toAntdColumns({
              [t("field.carbon-asset")]: v =>
                <Space><FlagImage iso3166={v?.attrs?.region}/>{v?.label}</Space>
            })}
          />
        </Form.Item>
        <Form.Item
          name={"EmissionAmount"}
          label={t("field.carbon-offset")}
          required={true}
          rules={[{required: true}, {type: "number", min: 0, message: t("validation.wrong-number")}]}
        >
          <InputNumber suffix={t('value.tco2e')} className={"input-number"}/>
        </Form.Item>
        <Form.Item label={t("field.offset-amount-local")} required={true}>
          <Space.Compact className={"input-number"}>
            <Form.Item
              name={"LocalCurrencyAmount"}
              required={true}
              rules={[{required: true}, {type: "number", min: 0, message: t("validation.wrong-number")}]}
              noStyle
            >
              <InputNumber placeholder={t("field.amount-of-money")}/>
            </Form.Item>
            <Form.Item name={"LocalCurrency"} noStyle required={true}>
              <Select
                options={CL_Currencies.options()}
                placeholder={t("field.type-of-currency")}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item
          label={t("field.offset-amount-euro")}
          name={"EuroAmount"}
          required={true}
          rules={[{required: true}, {type: "number", min: 0, message: t('validation.wrong-number')}]}
        >
          <InputNumber suffix={t('value.euro')} className={"input-number"} precision={2}/>
        </Form.Item>
        {state.currencyDate && <p className={'description'}>
          {t('description.currency-rate-from-ecb', {date: state.currencyDate})}</p>}
      </TabularForms>
    </Form.Item>
    <Divider/>
    <section>
      <h2>{t('title.carbon-accounting')}</h2>
      <Table dataSource={root?.emissions} columns={_.toAntdColumns<Emission>({
        [t('field.good')]: o => root?.goods?.find(good => _.isEqualWith(good, o.Good, IdEqualCustomizer))?.Description?.native,
        [t('field.emission-type')]: _ => WC_EmissionOutputTypes.label(_.EmissionType),
        [t('field.emission-amount')]: _ => _.EmissionValue.toFixed(2) + ' tCO2e',
      })}/>

    </section>
    <Divider/>
    <section>
      <h2>{t("title.due-cbam")}</h2>
      <div className={'due-cbam-row'}>
        <Card bordered={false} className={'statistic-card'}>
          <Statistic
            title={t("field.due-cbam")}
            value={state.euro}
            suffix={"EUR"}
            precision={2}
            valueStyle={{color: 'red', marginTop: "1rem"}}
          />
        </Card>
        <CallForAction
          type={"dark"}
          closable={false}
          backgroundImage={"/images/photo/alexander-mils-lCPhGxs7pww-unsplash.jpg"}
          className={'call-for-action'}
        >
          <h3>{t("title.no-need-to-pay")}</h3>
          <p>{t("description.no-need-to-pay")}</p>
          <Button type={"primary"} onClick={SalesIQ.startChat}>{t("button.consult-now")}</Button>
        </CallForAction>

      </div></section>
  </Form>
}