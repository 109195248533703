import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import Lottie from "lottie-react"
import GreenLottie from "../app.wegreenpass/data/GreenLottie.json"
import "./InitializeDialog.scss"
import ProfileForm from "../form/ProfileForm";
import Account from "../common/Account";

export interface InitializeDialogProps {
  open?: boolean
  redirect?: string
  account?: Account
}

export default ({open, account, redirect}: InitializeDialogProps) => {
  const {t} = useTranslation()
  return <Modal open={open} title={t('title.first-time')} footer={null} className={'initialize-dialog'}>
    <Lottie animationData={GreenLottie} className={"lottie"}/>
    <p>{t('imperative.welcome-wecarbon-cloud-please-fill-basic-info')}</p>
    <ProfileForm submitButtonText={t('button.enter-wecarbon-cloud')} account={account} redirect={redirect}/>
  </Modal>
}