import {useTranslation} from "react-i18next";
import {Card, Space} from "antd";
import {CheckCircleOutlined} from "@ant-design/icons";


export default () => {
  const {t} = useTranslation()
  return <Card bordered={false} title={<Space><CheckCircleOutlined/>{t('title.submission-successful')}</Space>}>
    <p>{t('description.data-submitted-cannot-review')}</p>
  </Card>
  
}