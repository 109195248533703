import {ReactNode, useState} from "react";
import "./CallForAction.scss"
import {Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import _ from "../../common/lodash"

export interface CallForActionProps {
  backgroundImage?: string
  children?: ReactNode[] | ReactNode
  type: "dark" | "light"
  closable?: boolean
  className?: string
}

export default ({backgroundImage, children, type, closable, className}: CallForActionProps) => {
  const [open, setOpen] = useState(true)
  if (!open) return null
  return <div
    className={_(['call-for-action', className, type]).compact().uniq().join(' ')}
    style={{backgroundImage: _.url(backgroundImage, 'css')}}
  >
    <header>{closable && <Button type={"text"} icon={<CloseOutlined/>} onClick={() => setOpen(false)}/>}</header>
    <main>{children}</main>
  </div>
}