import React, {ReactNode, useCallback} from "react";
import "./AppIcon.scss"
import {Space} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import useNavigate from "../hook/useNavigate";

export interface AppIconProps {
  name: string
  description: string
  icon: ReactNode
  style?: React.CSSProperties
  onClick?: string | (() => void)
  status: 'available' | 'unavailable'
}

export default ({name, description, icon, style, onClick, status}: AppIconProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const _onClick = useCallback(() => {
    if (typeof onClick === 'function') onClick()
    else if (typeof onClick === 'string') navigate(onClick)
  }, [onClick])
  return <div className={"app-icon"} style={style} onClick={_onClick}>
    <main>
      <div className={'icon'}>{icon}</div>
      <h3>{name}</h3>
      <div className={'description'}>{description}</div>
    </main>
    <button><Space>{status === 'available' ? t('button.enter') : t('button.consult-now')}<ArrowRightOutlined
      className={'arrow'}
    /></Space></button>
  </div>
}