import React, {useContext, useRef} from "react";
import {Form, FormInstance, Input, InputNumber} from "antd";
import Address from "../../common/Address";
import RegionSelect from "../../component/RegionSelect";
import "./EditLocationDialog.scss"
import {Rule} from "rc-field-form/lib/interface"
import EditDialog, {EditDialogProps} from "./EditDialog";
import {useTranslation} from "react-i18next";
import CodeListContext from "../../context/CodeListContext";
import DisabledContext from "antd/es/config-provider/DisabledContext";


export default ({value, open, onChange}:EditDialogProps<Address>) => {
  const {t} = useTranslation()
  const {CL_Regions} = useContext(CodeListContext)
  const disabledContext = useContext(DisabledContext)
  const rules: Rule[] = [
    {required: true, message: t("validation.required")},
    {pattern: /^[\x00-\x7F]*$/, message: t("validation.ascii")}
  ]
  const formRef = useRef<FormInstance<Address>>(null)
  return <EditDialog
    formRef={formRef}
    open={open}
    title={t("title.edit-location")}
    onChange={onChange}
    destroyOnClose
    className={"edit-location-dialog"}
  >
    <Form<Address> ref={formRef} initialValues={value} disabled={disabledContext}>
      <Form.Item label={t("field.name")} name={"Name"} required={true} rules={rules}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.region")} name={"Country"} required={true} rules={rules}>
        <RegionSelect options={CL_Regions.options()}/>
      </Form.Item>
      <Form.Item label={t("field.subdivision")} name={"SubDivision"} required={true} rules={rules}>
        <Input placeholder={t("field.subdivision")}/>
      </Form.Item>
      <Form.Item label={t("field.city")} name={"City"} required={true} rules={rules}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.street")} name={"Street"} required={true} rules={rules}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item
        label={t("field.street-additional-line")}
        name={"StreetAdditionalLine"}
        required={true}
        rules={rules}
      >
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.number")} name={"Number"} required={true} rules={rules}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.postcode")} name={"Postcode"} required={true} rules={rules}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.po-box")} name={"POBox"} required={false}>
        <Input autoComplete={'off'}/>
      </Form.Item>
      <Form.Item label={t("field.longitude")} name={"Longitude"} required={true} rules={[{type: 'number', min: -180, max: 180}]}>
        <InputNumber className={"input-number"}/>
      </Form.Item>
      <Form.Item label={t("field.latitude")} name={"Latitude"} required={true} rules={[{type: 'number', min: -90, max: 90}]}>
        <InputNumber className={"input-number"}/>
      </Form.Item>
    </Form>
  </EditDialog>
  
}