import InstallationFillNoticeDialog from "../dialog/InstallationFillNoticeDialog";
import {ApiCredentialType, GET, POST, useApis} from "../../api";
import {useParams} from "react-router-dom";
import {Declaration} from "../../common/Declaration";
import {Alert, Button, Form, FormInstance, message, Radio, Space} from "antd";
import React, {useContext, useRef, useState} from "react";
import AccountContext from "../../context/AccountContext";
import DeclarantCard from "../component/DeclarantCard";
import {ObjectInputSelect} from "../component/ObjectSelect";
import EntitySelect from "../../component/EntitySelect";
import {useTranslation} from "react-i18next";
import {Installation} from "../../common/Installation";
import _ from "../../common/lodash"
import {CheckOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from "@ant-design/icons";
import "./SlotPage.scss"
import Entity from "../../common/Entity";
import StatusTag from "../component/StatusTag";
import useNavigate from "../../hook/useNavigate";

export default () => {
  const {id} = useParams()
  const token = new URLSearchParams(window.location.search).get('token')
  const [flags, setFlags] = useState<{ isDifferentEntity?: boolean, isBadInstallationStatus?: boolean }>({})
  const {account} = useContext(AccountContext)
  const ref = useRef<FormInstance<any>>(null)
  const [entity, setEntity] = useState<Entity>()
  const [installation, setInstallation] = useState<Installation>()
  const navigate = useNavigate()
  const {slot, installations, state, reload} = useApis({
    slot: () => GET<Declaration.InstallationSlot>(`/cbam/declarations/${id}/slots`, {token}, ApiCredentialType.Optional),
    installations: entity?._id && (() => GET<Installation[]>(`/cbam/installations/my/${entity?._id}`))
  }, [entity])
  const {t} = useTranslation()
  const [mode, setMode] = useState<'import' | 'excel' | undefined>(undefined)
  if (state === 'loading') return <></>
  if (slot?.status === 'submitted') navigate("/apps/wegreenpass/finished")
  return <Form
    layout={'vertical'} ref={ref} className={'slot-page'}
    onValuesChange={async (cv, v) => {
      if (cv.ReportingMethod) setMode(v.ReportingMethod)
      if (cv.Entity) {
        setEntity(v.Entity)
        setFlags({...flags, isDifferentEntity: v.Entity.idNumber !== slot?.Entity?.idNumber})
      }
      if (cv.Installation) {
        setInstallation(v.Installation)
        setFlags({...flags, isBadInstallationStatus: v.Installation.status !== 'submitted'})
      }
    }
    }
  >
    <DeclarantCard value={slot?.Entity} anonymous/>
    <h3>{t('title.how-do-you-plan-to-declare')}</h3>
    <Form.Item name={"ReportingMethod"}>
      <Radio.Group>
        <Space direction={'vertical'}>
          <Radio value={"import"} disabled={!account}>
            {t('value.import-from-already-done')}
          </Radio>
          {!account && <Alert
            type={'warning'}
            showIcon
            action={<Button
              type={'primary'}
              onClick={() => navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`)}
            >{t('button.register-or-log-in')}</Button>}
            message={t('imperative.register-or-login-to-import')}
          />}
          <Radio value={"excel"} disabled>
            {t('value.import-from-excel')}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
    {(mode === 'import') && account && <>
      <Form.Item
        name={'Entity'}
        label={t('field.declaring-manufacturer')}
        required={true} rules={[{required: true}]}
      >
        <EntitySelect name={t('field.manufacturer')} role={'manufacturer'} type={'input'}/>
      </Form.Item>
      {flags.isDifferentEntity && <Alert
        type={'warning'}
        message={t('validation.your-selection-does-not-match-importer')}
      />}
      {entity && <><Form.Item
        name={'Installation'}
        label={t('field.select-installation')}
        required={true}
        rules={[{
          required: true,
          validator: () => installation?.status === 'submitted' ? Promise.resolve() : Promise.reject(),
          message: t('validation.your-report-has-not-finished')
        }]}
      >
        <ObjectInputSelect<Installation>
          name={t('field.installation')}
          data={installations}
          columns={_.toAntdColumns({
            [t('field.name')]: o => o.basic?.Name,
            [t('field.status')]: o => <StatusTag status={o.status}/>,
            [t('field.action')]: o => <Space><Button
              icon={<SearchOutlined/>}
              href={`/apps/wegreenpass/installations/${o._id}?mode=view`}
              target={"_blank"}
            >{t('button.view')}</Button><Button
            icon={<EditOutlined/>}
            href={`/apps/wegreenpass/installations/${o._id}`}
            target={"_blank"}
            >{t('button.edit')}</Button></Space>
          })}
          renderItem={o => <Space>{o?.basic?.Name}<StatusTag status={o?.status}/></Space>}
        />
      </Form.Item>
        <p>{t('imperative.you-can-also-create-new-reports')}</p>
        <Space><Button
          icon={<PlusOutlined/>} onClick={async () => {
          if (!entity) return message.error(t('validation.no-declarant'))
          const {
            installation,
            status
          } = await POST<Installation>(`/cbam/declarations/${id}/slots/${slot?.Uuid}/create`, {entity, token})
          if (!status.success || !installation) return message.error(t('validation.create-installation-failed'))
          window.open(`/apps/wegreenpass/installations/${installation._id}`, '_blank')
          await reload('installations', 'slot')
          ref.current?.setFieldValue('Installation', installation)
          try {
            await ref.current?.validateFields()

          } catch (e) {

          }
        }}
        >{t('button.add-new-document')}</Button>
          <Button
            icon={<CheckOutlined/>} type={'primary'}
            onClick={async () => {
              try {
                await ref.current?.validateFields()
              } catch (e) {
                return message.error(t('validation.cannot-save-please-check'))
              }
              const {status} = await POST(`/cbam/declarations/${id}/slots/${slot?.Uuid}/import`, {
                ...ref.current?.getFieldsValue(),
                token
              })
              if (status.success) navigate('/apps/wegreenpass/finished')
            }}
          >{t('button.submit')}</Button><Button icon={<ReloadOutlined/>} onClick={async () => {
            const installationId = _.id(installation)
            const {installations} = await reload('installations')
            ref.current?.setFieldsValue({Installation: _.find(installations, o => o._id === installationId)})
            message.success(t('validation.status-refreshed'))
          }
          }>{t('button.refresh')}</Button></Space></>}

      {flags.isBadInstallationStatus && <Alert
        type={'warning'}
        message={t('validation.report-not-finished')}
      />}
    </>
    }
    <InstallationFillNoticeDialog importer={slot?.Importer}/>
  </Form>
}