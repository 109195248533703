import {ReactNode, useCallback} from "react";
import {Route} from "react-router-dom";
import PageContainer from "./component/PageContainer";

export interface IApp {
  name: string
  title: string
  renderPages: {[key: `/${string}`]: () => ReactNode[] | ReactNode}
}

export class App implements IApp {
  name: string
  title: string
  rootPath: string
  renderPages: {[key: string]: () => ReactNode[] | ReactNode}
  constructor(app: IApp){
    this.name = app.name
    this.title = app.title
    this.rootPath = `/apps/${app.name}`
    this.renderPages = app.renderPages
  }
  renderRoutes(){
    return Object.keys(this.renderPages).map((path, i) => {
        return <Route
          key={`${path}${i}`}
          path={`${this.rootPath}${path}`}
          element={<PageContainer i18nTitleKey={this.title}>{this.renderPages[path]()}</PageContainer>}
        />
      })
  }
}

export namespace App {
  export interface Info {
    url: string
    title: string
  }
}

export function useAppNavigate(){
  return useCallback(() => {
  }, [])
}