import {ReactNode, RefObject, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import IFormItemProps from "../../form/IFormItemProps";
import {Button, Form, FormInstance, message, Modal, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import _ from "lodash";
import "./TabularForms.scss"
import DisabledContext from "antd/es/config-provider/DisabledContext";

export interface TabularFormsProps<T> extends IFormItemProps<T[]> {
  name?: string
  title?: ReactNode
  columns?: ColumnsType<T>
  children?: ReactNode[] | ReactNode
  onDialogValuesChange?: (index: number, changedValues: any, values: any) => void
  dialogClassName?: string
  tableClassName?: string
  formRef?: RefObject<FormInstance<T>>
}

let lastFormObject: any = null

export default function <T>(
  {
    name,
    title,
    columns,
    children,
    onDialogValuesChange,
    tableClassName,
    value,
    onChange,
    formRef
  }: TabularFormsProps<any>) {
  const [dialogIndex, setDialogIndex] = useState<number | null>(null)
  const newRef = useRef<FormInstance>(null)
  const ref = formRef ? formRef as RefObject<FormInstance<T>> : newRef
  const disabledContext = useContext(DisabledContext)
  const {t} = useTranslation()
  const onValuesChange = (index: number | null, changedValues?: any, values?: any) => {
    if (index === null) return
    changedValues = changedValues || _(lastFormObject || {}).toPairs().differenceWith(_.toPairs(values), _.isEqual).fromPairs()
    lastFormObject = values
    onDialogValuesChange?.(index, changedValues, values || {})
  }
  useEffect(() => {
    if (dialogIndex === null) return ref.current?.resetFields()
    const _value = value?.[dialogIndex]
    onValuesChange(dialogIndex, undefined, _value)
    ref.current?.setFieldsValue(_value)
  }, [dialogIndex])
  const _columns : ColumnsType<T> = [
    ...columns || [],
    {
      title: t('button.action'),
      key: '__action',
      dataIndex: undefined,
      render: (v, record, index) => <Space.Compact>
        <Button
          icon={<EditOutlined/>}
          onClick={() => setDialogIndex(index)}
        />
        <Button
          danger={true}
          icon={<DeleteOutlined/>}
          onClick={async () => {
            if (dialogIndex !== null && dialogIndex > index) return
            const cloned = _.cloneDeep(value || [])
            cloned.splice(index, 1)
            await onChange?.(cloned)
          }}
        />
      </Space.Compact>
    }
  ]
  return <div className={'tabular-forms root'}>
    {!disabledContext && <Space>
      {t('value.total-objects', {length: value?.length || 0, name: name})}
      <Button
        onClick={() => setDialogIndex(value?.length || 0)}
      ><Space><PlusOutlined/>{t('button.create-object', {name: name})}</Space></Button></Space>}
    <Table
      dataSource={value}
      columns={_columns}
      className={tableClassName}
      pagination={(value?.length || 0) > 10 ? undefined : false}
    />
    <Modal
      open={dialogIndex !== null}
      onOk={async () => {
        if (dialogIndex === null || dialogIndex > (value || []).length || dialogIndex < 0) return
        // Try to validate the form
        try {
          await ref.current?.validateFields()
        } catch (e) {
          message.error(t('validation.cannot-save-please-check'))
          return
        }
        const cloned = _.cloneDeep(value || [])
        cloned[dialogIndex] = ref.current?.getFieldsValue()
        // If validate complete, assign value
        await onChange?.(cloned)
        // Close the dialog
        setDialogIndex(null)
      }}
      onCancel={() => setDialogIndex(null)}
      title={title || name}
      className={'tabular-forms dialog'}
      destroyOnClose
    >
      {dialogIndex !== null && <Form
          initialValues={value?.[dialogIndex]}
          ref={ref}
          onValuesChange={(changedValues, values) => onValuesChange(dialogIndex, changedValues, values)}
      >
        {children}
      </Form>}
    </Modal>
  </div>
}