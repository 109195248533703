import {Button, List, message, Space, Tag} from "antd";
import {DeleteOutlined, EditOutlined, FileOutlined, PlusOutlined} from "@ant-design/icons";
import {Declaration} from "../../common/Declaration";
import Entity from "../../common/Entity";
import {useTranslation} from "react-i18next";
import {SalesIQ} from "../../component/SalesIQ";
import {DELETE, GET, POST, useApis} from "../../api";
import StatusTag from "./StatusTag";
import useNavigate from "../../hook/useNavigate";

export interface DeclarationListProps {
  declarant: Entity
}

export default ({declarant}: DeclarationListProps) => {
  const {t} = useTranslation()
  const {declarations, reload} = useApis({
    declarations: declarant?._id && (() => GET<Declaration[]>(`/cbam/declarations/my/${declarant?._id}`))
  })
  const navigate = useNavigate()

  const onEdit = async (declarationId?: string) => {
    if (!declarationId) return message.error(t('validation.cannot-edit-report'));
    SalesIQ.track("Edit declaration")
    navigate(`/apps/wegreenpass/declarations/${declarationId}`)
  }
  return <List
    bordered dataSource={declarations} className={'declaration-list'} renderItem={o => {
    const color = o.daysLeft === undefined ? "" : o.daysLeft > 7 ? "green" : "red"
    const future = o.daysLeft === undefined ? "" : o.daysLeft > 0
    let button, status;
    switch (o.status) {
      case "placeholder":
        status = t('value.not-filled')
        button = <Button
          type={"primary"}
          icon={<PlusOutlined/>}
          className={'create-button'}
          onClick={async () => {
            if (!(o.year && o.reportingPeriod)) return message.error(t('validation.cannot-create-report'))
            const {declaration} = await POST<Declaration>('/cbam/declarations', {declaration: {year: o.year, reportingPeriod: o.reportingPeriod, declarant}})
            if (!declaration) return message.error(t('validation.cannot-create-report'))
            navigate(`/apps/wegreenpass/declarations/${declaration._id}`)
          }}
        >{t("button.start-writing")}</Button>;
        break;
      case "draft":
        status = t('value.filling')
        button = <Space.Compact><Button
          type={"primary"}
          icon={<EditOutlined/>}
          onClick={() => onEdit(o._id)}
        >{t("button.continue-writing")}</Button>
          <Button
            danger={true}
            icon={<DeleteOutlined/>}
            onClick={async () => {
              if (!o._id || !declarant._id || !window.confirm(t('validation.are-you-sure-to-delete'))) return
              await DELETE(`/cbam/declarations/${o._id}`)
              await reload('declarations')
            }}
          >{t("button.delete")}</Button>
        </Space.Compact>;
        break;
      case 'reviewing':
        status = t('value.reviewing')
        button = <Button
          icon={<FileOutlined/>}
          onClick={() => onEdit(o._id)}
        >{t("button.view-report")}</Button>;
      break;
      case "submitted":
        status = t('value.submitted')
        button = <Button
          icon={<FileOutlined/>}
          onClick={() => onEdit(o._id)}
        >{t("button.view-report")}</Button>;
        break;
    }
    return <List.Item
      actions={[button]}
    >
      <Space direction={'vertical'}>
      <Space>
        {t("title.importer-report", {year: o.year, reportingPeriod: o.reportingPeriod})}
        <Tag color={color}>
          {t(future ? "value.time-future" : "value.time-overdue", {daysLeft: Math.abs(o.daysLeft || 0)})}
        </Tag>
      </Space>
      <StatusTag status={o.status}/>
      </Space>

    </List.Item>
  }}
  />
}