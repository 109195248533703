import React, {useContext, useRef, useState} from "react";
import "./HomePage.scss"
import {Badge, Button, DatePicker, Form, FormInstance, Input, InputNumber, Modal, Select, Space, Table} from "antd";
import _ from "../common/lodash";
import CodeListContext from "../context/CodeListContext";
import {GET, POST, useApis} from "../api";
import Loading from "../component/Loading";
import {AccountAdminView} from "../common/Account";
import PromoterProfile from "../common/PromoterProfile";
import dayjs from "dayjs";
import useNavigate from "../hook/useNavigate";

export default () => {
  const {state, policies, accounts, reload} = useApis({
    accounts: () => GET<AccountAdminView[]>('/admin/accounts'),
    policies: () => GET<PromoterProfile[]>('/admin/policies')
  })
  const [dialog, setDialog] = useState<'create-policy' | null>(null)
  const {WC_Positions, WC_Sectors, WC_Demands} = useContext(CodeListContext)
  const navigate = useNavigate()
  const ref = useRef<FormInstance>(null)
  if (state !== "success") return <Loading state={state}/>
  return <div className={"admin-page"}>
    <h3>Accounts</h3>
    <Table
      dataSource={accounts} rowKey={"_id"} columns={_.toAntdColumns({
      "ID": "_id",
      "Phone": "Phone",
      "Name": ['Profile', 'Name'],
      "Email": ['Profile', 'Email'],
      "Occupation": o => WC_Positions.label(o.Profile?.Occupation),
      "Sector": o => WC_Sectors.label(o.Profile?.Sector),
      "Business Demand": o => WC_Demands.label(o.Profile?.BusinessDemand),
      "Company Name": ['Profile', 'CompanyName', 'native'],
      "Role": o => [o.Flags.SalesAgent && "Sales Agent", o.Flags.Admin && "Admin"].filter(Boolean).join(", "),
      "Manage": o =>
        <Badge count={o.ReviewingInstallationCount + o.ReviewingDeclarationCount}><Button
          type={'primary'}
          onClick={() => navigate(`/accounts/${o._id?.toString()}/admin`)}
        >Manage</Button></Badge>
    })}
    />
    <h3>Policies</h3>
    <Button onClick={() => setDialog('create-policy')}>Create a policy</Button>
    <Table
      dataSource={policies} rowKey={"_id"} columns={_.toAntdColumns({
      "AllowedName": o => _(o.AllowedNames).join(';'),
      "Name": "Name",
      "StartDate": "StartDate",
      "EndDate": "EndDate",
      "BenefitRate": "BenefitRate",
      "OwnerAccount": o => accounts.find(account => account._id === o.ownerAccount)?.Phone,
      "Manage": o => <Space><Button
        onClick={async () => {
          const accountId = window.prompt("Which account? Enter the ID. Leave blank to cancel. ")
          if (!accountId) return
          const foundAccount = accounts.find(account => account._id === accountId)
          if (!foundAccount) return window.alert("Account not found")
          if (!foundAccount.Flags.SalesAgent) return window.alert("Account is not a sales agent")
          await POST(`/admin/accounts/${accountId}/policies`, {policy: o})
          await reload('policies')
        }
        }
      >Copy to another account</Button></Space>
    })}
    />
    <Modal
      open={dialog === 'create-policy'} title={'Create a policy'} onOk={async () => {
      const values = await ref.current?.validateFields()
      if (!values) return
      await POST('/admin/policies', {policy: values})
      await reload('policies')
      setDialog(null)
    }} onCancel={async () => {
      await ref.current?.resetFields()
      setDialog(null)
    }
    }
    >
      <Form
        layout={"vertical"} initialValues={{
        StartDate: dayjs(),
        EndDate: dayjs().add(1, 'month'),
      }} ref={ref}
      >
        <Form.Item name={'StartDate'} label={"StartDate"} rules={[{required: true}]}><DatePicker/></Form.Item>
        <Form.Item name={'EndDate'} label={"EndDate"} rules={[{required: true}]}><DatePicker/></Form.Item>
        <Form.Item name={'Name'} label={"Name (for sales)"} rules={[{required: true}]}><Input/></Form.Item>
        <Form.Item
          name={"BenefitRate"}
          label={"Benefit Rate"}
          rules={[{required: true, type: 'number', min: 0.5, max: 50}]}
        ><InputNumber suffix={"%"}/></Form.Item>
        <Form.Item
          name={'AllowedNames'}
          label={"Allowed coupon names (for clients - use ; to separate possible options)"}
          rules={[{required: true}]}
        ><Input/></Form.Item>
        <Form.Item
          name={'ownerAccount'}
          label={"Owner account"}
          rules={[{required: true}]}
        ><Select
          options={accounts.filter(o => o.Flags.SalesAgent).map(o => ({
            value: o._id,
            label: <Space>{o._id}{o.Phone}{o.Profile?.Name}</Space>
          }))}
        /></Form.Item>
      </Form>

    </Modal>
  </div>

}