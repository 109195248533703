import {useContext, useEffect, useRef, useState} from "react";
import {Alert, Descriptions, Form, FormInstance, Input, message, Modal, Radio, Select} from "antd";

import "./CreateEntityDialog.scss"
import LocationPicker from "../app.wegreenpass/component/LocationPicker";
import UsernameInput from "../component/UsernameInput";
import Entity from "../common/Entity";
import RegionSelect from "../component/RegionSelect";
import TranslateInput from "../component/TranslateInput";
import _ from '../common/lodash';
import {useTranslation} from "react-i18next";
import CodeListContext from "../context/CodeListContext";
import {ApiCredentialType, POST, PUT, useApis} from "../api";
import {CreateEntityTemplate} from "../common/transient/CreateEntityTemplate";

export interface CreateEntityDialogProps {
  open: boolean
  roles?: string[]
  onReturn: (entity?: Entity) => void
  value?: Entity
  anonymous?: boolean
}

export default ({open, roles, onReturn, value, anonymous}: CreateEntityDialogProps) => {
  const [region, setRegion] = useState<string | undefined>()
  const [idType, setIdType] = useState<string | undefined>()
  const [dialog, setDialog] = useState<'confirm' | null>(null)
  const {template, state} = useApis({
    template: open && (() => POST<CreateEntityTemplate>(
      '/entities/my/template',
      {roles, entity: value},
      ApiCredentialType.Optional
    ))
  }, [open])
  const ref = useRef<FormInstance<Entity>>(null)
  const form = ref.current?.getFieldsValue()
  const {t} = useTranslation()
  const {WC_Regions, WC_IdTypes, WC_Roles, CL_Regions} = useContext(CodeListContext)

  useEffect(() => {
    if (state === 'success') {
      setRegion(template?.entity.region)
      setIdType(template?.entity.idType)
    }
    return () => ref.current?.resetFields()
  }, [template])

  return state !== "success" ? null : <Modal
    open={open}
    title={value?._id ? t('title.edit-entity') : t('title.create-entity')}
    onOk={() => ref.current?.validateFields()
      .then(() => setDialog('confirm'))
      .catch(() => message.error(t('validation.cannot-save-please-check')))
    }
    onCancel={() => onReturn()}
    destroyOnClose
    className={"create-entity-dialog"}
  >
    <Form
      ref={ref} onValuesChange={(cv, v) => {
      if (cv.region) {
        const idType = WC_IdTypes
          .options()
          .filter(o => CL_Regions.attrs(cv.region, "allowedIdTypes")?.includes(o.value))[0]?.value
        ref.current?.setFields([
          {name: 'idType', value: idType},
          {name: ["attrs", "cbam-role"], value: undefined}
        ])
        setRegion(cv.region)
        setIdType(idType)
      }
      if (cv.idType) setIdType(v.idType)
    }} initialValues={template?.entity}
    >
      {!template?.editable &&
        <Alert type={'warning'} message={t('validation.cannot-edit-paid-entity')} showIcon className={'alert'}/>}
      <Form.Item
        label={t('field.region')}
        name={"region"}
        rules={[{required: true}]}
      >
        <RegionSelect options={WC_Regions.options().filter(o => template?.regions.includes(o.value))} disabled={!template?.editable}/></Form.Item>
      <Form.Item label={t('field.name')} required={true}>
        <Form.Item name={"name"} noStyle rules={[{required: true}]}>
          <TranslateInput disabled={!template?.editable}/>
        </Form.Item>
        <p className={'description'}>{t('imperative.please-fill-legal-entity-name')}</p>
      </Form.Item>

      <Form.Item
        label={t('field.id-type')}
        name={"idType"}
        rules={[{required: true}]}
      ><Select disabled={!template?.editable}
        options={WC_IdTypes.options().filter(o => CL_Regions.attrs(region || "", "allowedIdTypes")?.includes(o.value))}
      /></Form.Item>
      <Form.Item
        label={t('field.id-number')}
        name={"idNumber"}
        rules={[{
          required: true,
          pattern: new RegExp(WC_IdTypes.attr(idType || "", 'pattern')
            ?.replace("%REGION%", region || "") || ".*"),
          message: t('validation.invalid-id-number')
        }]}
      ><Input autoComplete={"off"}  disabled={!template?.editable}/></Form.Item>
      <Form.Item
        label={t('field.entity-address')}
        name={"address"}
        rules={[
          {required: true},
          {
            validator: () => region === ref.current?.getFieldValue('address')?.Country
              ? Promise.resolve()
              : Promise.reject(t('validation.address-must-be-within-specified-region'))
          }]}
      >
        <LocationPicker region={region}  disabled={!template?.editable}/>
      </Form.Item>
      <h3>{t('field.contact-info')}</h3>
      <p>{t('imperative.please-provide-relevant-cbam')}</p>
      <Form.Item label={t('field.contact-name')} name={["contact", "Name"]} rules={[{required: true}]}><Input
        autoComplete={"off"}
      /></Form.Item>
      <Form.Item
        label={t('field.contact-phone')}
        name={["contact", "Phone"]}
        rules={[{required: true}]}
      ><UsernameInput region={region}/></Form.Item>
      <Form.Item
        label={t('field.contact-email')}
        name={["contact", "Email"]}
        rules={[{required: true, type: "email"}]}
      ><Input autoComplete={'off'}/></Form.Item>
      <Form.Item
        name={["attrs", "cbam-role"]}
        rules={[{required: true, message: t('validation.required')}]}
        label={t('title.cbam-entity-role')}
      >
        <Radio.Group>
          {WC_Roles.options().map(o => <Radio
            value={o.value}
            key={o.value}
            disabled={!_.intersection(WC_Regions.attrs(region || "", 'allowedRoles'), roles)?.includes(String(o.value))}
          >{o.label}</Radio>)}
        </Radio.Group>
      </Form.Item>
    </Form>
    <Modal
      open={dialog === 'confirm'} onOk={async () => {
      const entity = {...ref.current?.getFieldsValue(), _id: value?._id} as Entity
      if (anonymous) return onReturn(entity)
      let response = entity?._id
        ? await PUT(`/entities/${entity._id}`, {entity})
        : await POST(`/entities`, {entity})
      if (!response) return message.error(t('validation.create-failed'))
      onReturn(response?.entity)
    }
    } onCancel={() => setDialog(null)} title={t('title.please-confirm-correctness')}
    >
      {dialog === 'confirm' && <Descriptions
        column={1} items={form && [
        [t('field.region'), WC_Regions.label(form.region)],
        [t('field.name'), form.name.native],
        [t('field.id-type'), WC_IdTypes.label(form.idType)],
        [t('field.id-number'), form.idNumber],
        [t('field.entity-address'), form.address.FormattedAddress || form.address.Name],
        [t('field.contact-name'), form.contact.Name],
        [t('field.contact-phone'), form.contact.Phone],
        [t('field.contact-email'), form.contact.Email],
      ].map(([label, children], i) => ({key: i, label, children}))}
      />}
    </Modal>
  </Modal>
}