import {I18nText} from "./I18nText";
import {CheckboxOptionType} from "antd";

// FIXME: Not used because difficulty of refactoring
export class CodeList implements ICodeList {
  _id?: string
  name: string
  items: ICodeListItem[]

  constructor(o: ICodeList) {
    this._id = o._id
    this.name = o.name
    this.items = o.items
  }

  attr(key: string, attrName: string): string | undefined {
    const codeListItem = this.items.find(o => o.value === key)
    return codeListItem?.attrs?.[attrName]
  }

  attrs(key: string, ...attrName: string[]): string [] {
    const codeListItem = this.items.find(o => o.value === key)
    return Object.entries(codeListItem?.attrs || {}).flatMap(([key, value]) => {
      if (!attrName.includes(key) || value === undefined) return [];
      if (Array.isArray(value)) return value;
      return [value];
    }) || []
  }

  label(key?: string, childKey?: string, _default?: string): string {
    // If codeList is not cached, cache first
    if (!key) return ""
    const codeListItem = this.items.find(o => o.value === key)
    if (childKey) {
      const childCodeListItem = codeListItem?.children?.find(o => o.value === childKey)
      return childCodeListItem?.label || childKey
    }
    return codeListItem?.label || _default || key
  }

  item(key?: string, childKey?: string): ICodeListItem | undefined {
    // If codeList is not cached, cache first
    if (!key) return undefined
    if (childKey) {
      return this.items.find(o => o.value === key)?.children?.find(o => o.value === childKey)
    }
    return this.items.find(o => o.value === key)
  }

  option(key: string): (ICodeListItem & CheckboxOptionType) | undefined {
    const codeListItem = this.items.find(o => o.value === key)
    if (!codeListItem) return undefined;
    return {
      ...codeListItem,
      label: codeListItem?.label || codeListItem?.value
    }
  }

  options(attrs?: { [key: string]: any }): (ICodeListItem & CheckboxOptionType)[] {
    return this.items.map(o => ({
      ...o,
      children: (o.children?.length || 0) === 0 ? undefined : o.children,
      key: o.value,
      label: o.label || o.value
    })).filter(o => attrs === undefined ? true : Object.entries(attrs).every(([key, value]) => {
      if (Array.isArray(o.attrs?.[key])) {
        return o.attrs?.[key].includes(value)
      } else {
        return o.attrs?.[key] === value
      }
    }))
  }
}
export interface ICodeListItem {
  value: string
  key?: string
  label?: string
  labels: I18nText
  attrs?: { [key: string]: any }
  children?: ICodeListItem[]
}
export interface ICodeList {
  _id?: string
  name: string
  items: ICodeListItem[]

}