import {
  CaretDownFilled,
  CreditCardOutlined,
  GlobalOutlined,
  LogoutOutlined,
  MailOutlined,
  NotificationOutlined,
  ProfileOutlined,
  SearchOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import {Alert, Avatar, Button, Dropdown, Input, Layout, MenuProps, message, Space} from "antd";
import {Content, Header} from "antd/es/layout/layout";
import _ from "lodash";
import {ReactNode, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {POST} from "../api";
import AccountContext from "../context/AccountContext";
import useNavigate from "../hook/useNavigate";
import IconFont from "./IconFont";
import Logo from "./Logo";
import "./PageContainer.scss";
import SalesIQ from "./SalesIQ";
import EnvUtil from "../util/EnvUtil";
import useLanguageOptions from "../hook/useLanguageOptions";
import Jury from "./Jury";

export interface PageContainerProps {
  children?: ReactNode[] | ReactNode
  i18nTitleKey?: string
}

export default ({children, i18nTitleKey}: PageContainerProps) => {
  const [title, setTitle] = useState<string>("")
  const {t, i18n} = useTranslation()
  const {jury, juryMatchLanguage} = EnvUtil
  const {account, logout} = useContext(AccountContext)
  const [juryDialog, setJuryDialog] = useState<boolean>(!juryMatchLanguage(i18n.language))
  const navigate = useNavigate()
  const languageOptions = useLanguageOptions({i18n: true, onJury: () => setJuryDialog(true)})

  useEffect(() => {
    (async () => {
      if (i18nTitleKey) {
        setTitle(t(i18nTitleKey))
        document.title = t(i18nTitleKey)
      }
    })()
  }, [t, i18n, i18nTitleKey])

  const items: MenuProps['items'] = _.compact([
    account && {
      key: 'profile',
      label: t("button.profile"),

      onClick: () => navigate("/profile"),
      icon: <ProfileOutlined/>
    },
    account && {
      key: 'payment',
      label: t("button.payment"),
      onClick: () => navigate("/payment"),
      icon: <CreditCardOutlined/>
    },
    account && {
      type: "divider"
    },
    {
      key: 'language',
      label: t("button.language-and-region"),
      icon: <GlobalOutlined/>,

      children: languageOptions
    },
    account?.Flags.Admin && {
      key: 'admin',
      label: t("button.admin"),
      icon: <SettingOutlined/>,
      onClick: () => navigate("/admin")
    },
    account?.Flags.SalesAgent && {
      key: 'agent',
      label: t('button.promotion'),
      icon: <NotificationOutlined/>,
      onClick: () => navigate("/promotions")
    },
    account && {
      key: 'logout',
      danger: true,
      label: t("button.logout"),
      onClick: logout,
      icon: <LogoutOutlined/>
    },
  ])
  return <><Layout className={"page-container"}>
    <Header>
      <div className={'apps'}>
        <Button type={"text"} icon={<IconFont type={"icon-apps"}/>} onClick={() => navigate("/home")}/>
        <Logo/>
        <h1>{title}</h1>
        {jury === 'cn' && <Jury type={'tag'} onClick={() => setJuryDialog(true)}/>}
      </div>
      <div className={'search'}>
        <Input prefix={<SearchOutlined/>} placeholder={t("imperative.search-or-ask-ai")}></Input>
      </div>
      <div className={'user'} id={'page-container-user'}>
        <Avatar icon={<UserOutlined/>}/>
        <Dropdown menu={{items, selectedKeys: [i18n.language]}} placement={"bottomRight"} arrow={false}>
          <Space>
            <span>{account?.Profile?.Name}</span>
            <CaretDownFilled/>
          </Space>
        </Dropdown>
      </div>
    </Header>
    <Content>
      {account && !account?.Flags.EmailVerified && <Alert
        showIcon={true} type={'warning'} message={<Space>{t('imperative.please-validate-email')}{account?.Profile?.Email}<Button
        icon={<MailOutlined/>} onClick={async () => {
        const {status} = await POST("/accounts/me/resend-verification")
        if (status.success) message.success(t('validation.email-sent'))
        else message.error(t('validation.cannot-send-email'))
      }}
      >{t('button.resend-validate-email')}</Button></Space>}
      />}
      {children}
    </Content>

  </Layout>{account && <SalesIQ
    widgetCode={EnvUtil.salesIq}
    account={account}
  />}<Jury type={'dialog'} open={juryDialog} onContinue={() => setJuryDialog(false)}/>
  </>

}