import {QRCode} from "antd";
import Transaction from "../../common/Transaction";

export interface WeChatQrCodeProps {
  transaction: Transaction
}

export default ({transaction}: WeChatQrCodeProps) => {
  if (!transaction.Payload.code_url) return null
  return <QRCode value={transaction.Payload.code_url}/>
}

