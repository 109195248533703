import React, {useContext, useState} from "react";
import {Alert, Form, InputNumber, List} from "antd";
import {useTranslation} from "react-i18next";
import {Installation} from "../../../common/Installation";
import "../Stage.scss"
import {StageProps, useStageForm} from "../Stage";
import RegionSelect from "../../../component/RegionSelect";
import EmissionFactorSelect from "../../../component/EmissionFactorSelect";
import HintedLabel from "../../../component/HintedLabel";
import _ from 'lodash';
import TranslateInput from "../../../component/TranslateInput";
import CodeListContext from "../../../context/CodeListContext";


export default (props: StageProps<Installation, Installation.Precursor>) => {
  const Form = useStageForm(props)
  const [alertLessConsumption, setAlertLessConsumption] = useState(false)
  const {t} = useTranslation()
  const {onReturn, value, formRef, root} = props
  const {CL_ProductionRoutes, WC_Regions} = useContext(CodeListContext)
  const childRoutes = CL_ProductionRoutes.item(value?.Name)?.children

  return <Form
    onValuesChange={(cv, v) => {
      // Total purchase should be greater than total consumption. Show alert if not
      const state: any = {}
      if (
        cv.ProductionLevel || cv.ConsumptionLevel || cv.ConsumptionLevelNonCBAM !== 0
      ) setAlertLessConsumption(
        _.sum(_.values(v.ProductionLevel)) <
        _.sum(_.values(v.ConsumptionLevel)) + (v.ConsumptionLevelNonCBAM || 0))
      onReturn?.({Name: value?.Name, ...formRef?.current?.getFieldsValue()})
    }}
    className={"precursor-form"}
    layout={"horizontal"}
  >

    <p>{t('imperative.precursor-declaration-introduction', {name: CL_ProductionRoutes.label(value?.Name)})}</p>
    <Form.Item
      name={"Description"}
      label={
        <HintedLabel title={t('field.name')}>{t('description.precursor-name-hint')}</HintedLabel>}
      required={true}
    >
      <TranslateInput capitalize='first'/>
    </Form.Item>
    <Form.Item name={"Region"} label={t('field.precursor-source-region')} required={true}>
      <RegionSelect options={WC_Regions.options()}/>
    </Form.Item>
    <h3>
      <HintedLabel title={t('title.precursor-total-procurement')}>{t('description.precursor-total-procurement')}</HintedLabel>
    </h3>
    {childRoutes && childRoutes.length > 0 ? <>
      {childRoutes.map(_ => <Form.Item
        name={['ProductionLevel', _.value]}
        key={`ProductionLevel.${_.value}`}
        label={t('field.precursor-of-route', {
          route: _.label,
          precursor: CL_ProductionRoutes.label(value?.Name)
        })}
        required={true}
        rules={[
          {required: true, message: t('validation.required')},
          {type: 'number', min: 0}
        ]}
      ><InputNumber className={"input-number"} suffix={"t"}/></Form.Item>)}
    </> : <List.Item><Form.Item
      name={['ProductionLevel', 'All']}
      label={t('field.total-procurement')}
      required={true}
      rules={[
        {required: true, message: t('validation.required')},
        {type: 'number', min: 0, message: t('validation.must-not-be-negative')}
      ]}
    ><InputNumber className={"input-number"} suffix={"t"}/></Form.Item></List.Item>}
    <h3><HintedLabel title={t('title.precursor-total-consumption')}>{t('description.precursor-total-consumption')}</HintedLabel></h3>
    {root?.processes?.map(o => <Form.Item
      name={['ConsumptionLevel', _([o.Name, o.ChildName]).reject(_.isNil).join("-")]}
      key={`ConsumptionLevel.${_([o.Name, o.ChildName]).reject(_.isNil).join("-")}`}
      label={t('field.consumption-for-category-description', {
        category: CL_ProductionRoutes.label( o.Name, o.ChildName),
        description: o.Description?.native
      })}
      required={true}
      rules={[
        {required: true, message: t('validation.required')},
        {type: 'number', min: 0, message: t('validation.must-not-be-negative')}
      ]}
    ><InputNumber className={"input-number"} suffix={"t"}/></Form.Item>)}
    <Form.Item
      name={['ConsumptionLevelNonCBAM']} label={t("field.non-cbam-products")}
      required={true}
      rules={[
        {required: true, message: t('validation.required')},
        {type: 'number', min: 0, message: t('validation.must-not-be-negative')}
      ]}
    ><InputNumber
      className={"input-number"}
      suffix={"t"}
    /></Form.Item>
    {alertLessConsumption && <Alert
        type={'info'}
        showIcon
        message={t('validation.less-consumption-alert')}
    />}

    <h3><HintedLabel title={t('title.emission-factor')}>{t('description.emission-factor')}</HintedLabel></h3>
    <Form.Item
      name={"DirectEmissionFactor"}
      label={t('field.direct-emission-factor')}
      required={true}
      rules={[{required: true}]}
    >
      <EmissionFactorSelect
        query={{
          SubjectType: "CN:Category",
          Subject: value?.Name,
          ActivityType: "DirectProduction.Solid"
        }}
      />
    </Form.Item>
    <Form.Item
      name={"IndirectEmissionFactor"}
      label={t('field.indirect-emission-factor')}
      required={true}
      rules={[{required: true}]}
    >
      <EmissionFactorSelect
        query={{
          SubjectType: "CN:Category",
          Subject: value?.Name,
          ActivityType: "IndirectProduction.Solid"
        }}
      />
    </Form.Item>
  </Form>
}