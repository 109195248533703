import {ReactNode, useContext, useMemo, useState} from "react";
import {CloseOutlined, NumberOutlined, SwitcherOutlined, TagOutlined, UserOutlined} from "@ant-design/icons";
import {Button, Card, Space, Tooltip} from "antd";
import FlagImage from "../../component/FlagImage";
import Entity from "../../common/Entity";
import SelectDeclarantDialog from "../dialog/SelectDeclarantDialog";
import Loading from "../../component/Loading";
import PlanTag from "./PlanTag";
import "./DeclarantCard.scss"
import {useTranslation} from "react-i18next";
import CodeListContext from "../../context/CodeListContext";

export interface DeclarantCardProps {
  children?: ReactNode[] | ReactNode
  title?: string
  value?: Entity
  onChange?: (entity?: Entity) => void
  roles?: string[]
  required?: boolean
  loading?: boolean
  anonymous?: boolean
}

export default ({children, title, value, onChange, roles, required, loading, anonymous}: DeclarantCardProps) => {
  const {WC_Roles} = useContext(CodeListContext)
  const [dialog, setDialog] = useState<'select' | null>(null)
  const {t} = useTranslation()
  const _onChange = (o?: Entity) => {
    setDialog(null)
    onChange?.(o)
  }
  return <Card
    title={title || t("title.declarant")}
    size={"small"}
    extra={<Space.Compact>
      {onChange && value && <Tooltip title={t("imperative.choose-identity")}><Button
          icon={<SwitcherOutlined/>}
          type={"text"}
          onClick={() => setDialog('select')}
      >{t("button.change")}</Button></Tooltip>}
      {onChange && value && !required && <Button
          icon={<CloseOutlined/>}
          type={"text"}
          onClick={() => _onChange()}
      >{t("button.deselect")}</Button>}
    </Space.Compact>}
    className={"declarant-card"}
  >
    <main>
      {loading && <Loading/>}
      {!loading && value && <>
          <Space><FlagImage iso3166={value.region}/><h3>{value.name.native}</h3></Space>
          <Space><TagOutlined/>{value.name.en}</Space>
          <Space><NumberOutlined/>{value.idNumber}</Space>
          <Space><UserOutlined/>{WC_Roles.label(value?.attrs["cbam-role"])}</Space>
          {!anonymous && <Space><PlanTag entity={value}/></Space>}
        {children}
      </>}
      {!loading && !value && <div className={"empty-entity"}>
          <h3>{t("validation.declarant-entity-not-selected")}</h3>
          <Space>
              <Button
                  icon={<SwitcherOutlined/>}
                  type={"text"}
                  onClick={() => setDialog('select')}
              >{t("button.select-from-entities")}</Button>
          </Space>
      </div>}
    </main>
    {!anonymous && <SelectDeclarantDialog
      open={dialog === 'select'}
      value={value}
      onChange={_onChange}
    />}
  </Card>
}