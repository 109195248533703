import { ReactNode } from "react";
import "./WelcomeBoard.scss";

export interface WelcomeBoardProps {
  title?: string
  icon?: ReactNode
  children?: ReactNode[] | ReactNode
}

export default ({title, icon, children}: WelcomeBoardProps) => {
  return <div className={"welcome-board"}>
    <figure>{icon}</figure>
    <main>
      <h2>{title}</h2>
      {children}
    </main>
  </div>
}