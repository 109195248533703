import {useContext, useRef} from "react";
import {Alert, Button, Result, Space} from "antd";
import {useParams} from "react-router-dom";
import {CheckOutlined, EditOutlined, GoldOutlined, LockOutlined, PhoneOutlined, RightOutlined} from "@ant-design/icons";
import {Installation, NewInstallationGood} from "../../common/Installation";
import BasicStage from "../stage/installation/BasicStage";
import ProcessStage from "../stage/installation/ProcessStage";
import EmissionSourceStage from "../stage/installation/EmissionSourceStage";
import RecognizedStage from "../stage/installation/RecognizedStage";
import PrecursorStage from "../stage/installation/PrecursorStage";
import SubmitStage from "../stage/installation/SubmitStage";
import InstallationFillNoticeDialog from "../dialog/InstallationFillNoticeDialog";
import ReturnStage from "../stage/installation/ReturnStage";
import WelcomeStage from "../stage/installation/WelcomeStage";
import Loading from "../../component/Loading";
import "./InstallationPage.scss"
import StageWizard, {StageWizardRef} from "../stage/StageWizard";
import AdvertiseStage from "../stage/common/AdvertiseStage";
import {FinishedInstallationError} from "../../common/error";
import _ from '../../common/lodash'
import {SalesIQ} from "../../component/SalesIQ";
import SubmitReviewStage from "../stage/common/SubmitReviewStage";
import {useTranslation} from "react-i18next";
import GoodStage from "../stage/installation/GoodStage";
import CodeListContext from "../../context/CodeListContext";
import {ApiCredentialType, GET, POST, PUT, useApis} from "../../api";
import {Declaration} from "../../common/Declaration";
import useNavigate from "../../hook/useNavigate";

export interface InstallationPageProps {
  anonymous?: boolean
}

export type InstallationPageStateType =
  'edit-as-exporter'
  | 'view'
  | 'view-anonymous'
  | 'view-submit'
  | 'edit-as-importer'
  | 'edit-anonymous'
  | 'loading'


export default ({anonymous}: InstallationPageProps) => {
  const search = new URLSearchParams(window.location.search)
  // 'edit-as-exporter': Edit as exporter, default
  // 'edit-as-importer': Edit as importer, as a child of declaration
  // 'edit-anonymous': Edit as exporter, but from an anonymous link
  // 'view': View the installation.
  // 'view-anonymous': View the installation as an anonymous user, using the token.
  // 'view-submit': Can view, while allowing to submit.
  const ref = useRef<StageWizardRef>(null)
  const params = useParams<'id' | 'slot'>()
  const {CL_ProductionRoutes, CL_Precursors} = useContext(CodeListContext)
  const {t, i18n} = useTranslation()
  const {installation, slot, status, reload, state} = useApis({
    installation: params.id && (() => GET<Installation>(`/cbam/installations/${params.id}`, {
      anonymous: anonymous || search.get('token') ? true : undefined,
      token: search.get('token') || undefined
    }, anonymous || search.get('token') ? ApiCredentialType.Ignored : ApiCredentialType.Required)),
    slot: params.slot && (() => GET<Declaration.InstallationSlot>(`/cbam/installations/${params.slot}`, undefined, ApiCredentialType.Optional))
  })
  const navigate = useNavigate()

  if (status.statusCode === FinishedInstallationError.statusCode) navigate("/apps/wegreenpass/finished")
  const mode = _(installation?.status)
    .where<InstallationPageStateType>(
      [search.get('mode') === 'view-anonymous', 'view-anonymous'],
      [search.get('mode') === 'view', 'view'],
      ['draft', 'edit-as-exporter'],
      ['public-waiting', 'edit-as-importer'],
      ['reviewing', 'view'],
      ['rejected', 'view'],
      ['submitted', 'view-submit'],
      'edit-as-exporter'
    ).value() || 'edit-as-exporter'

  async function onEdit() {
    const installationId = installation?._id?.toString()
    if (!installationId || !installation) return
    const answer = window.confirm(t('imperative.are-you-sure-to-withdraw'))
    if (!answer || !(await POST(`/cbam/installations/${installationId}/retract`)).status.success) return
    installation.status = 'draft'
    await reload('installation')
    ref.current?.goto({name: 'welcome'})
  }

  async function onSave(v?: any, name?: string) {
    if (!installation || mode.startsWith('view')) return
    if (name) installation.currentStage = name
    const {
      status,
      installation: newInstallation
    } = await PUT(`/cbam/installations/${installation._id}`, {installation: v || installation}, anonymous ? ApiCredentialType.Ignored : ApiCredentialType.Required)
    if (!status.success) throw new Error(t('validation.cannot-save-report'))
    await reload('installation')
  }


  if (!installation){
    if (state === 'loading') return <Loading fullscreen/>
    return <Result
      status={"404"}
      title={t('validation.declaration-not-found')}
      subTitle={t('description.declaration-not-found')}
      extra={<Button
        type={"primary"}
        onClick={() => navigate("/apps/wegreenpass")}
      >{t('button.go-back')}</Button>}
    />
  }

  return <>
    {installation.status === 'reviewing' && <Alert
      type={'warning'}
      icon={<LockOutlined/>}
      message={<Space>{t('validation.cannot-edit-under-review')}<Button
        icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}
      >{t('button.contact-service')}</Button><Button
        danger
        icon={<EditOutlined/>}
        disabled={mode === 'view' || mode === 'view-anonymous'}
        onClick={onEdit}
      >{t('button.retract-and-edit')}</Button></Space>}
      showIcon
    />}
    {installation.status === 'rejected' && <Alert
      type={'error'}
      icon={<LockOutlined/>}
      message={<Space>{t('imperative.these-problems-in-reports')}{installation.rejectionReason}<Button
        icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}
      >{t('button.contact-service')}</Button><Button
        danger
        icon={<EditOutlined/>}
        onClick={onEdit}
      >{t('button.retract-and-edit')}</Button></Space>}
      showIcon
    />}
    {installation.status === 'submitted' && <Alert
      type={'success'}
      icon={<CheckOutlined/>}
      message={<Space>{t('validation.finished-review-can-submit')}<Button
        icon={<RightOutlined/>} type={'primary'} onClick={() => ref.current?.goto({name: 'submit'})}
      >{t('button.go-to-submit-page')}</Button>{mode !== 'view-anonymous' && <><Button
        icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}
      >{t('button.contact-service')}</Button><Button
        danger
        icon={<EditOutlined/>}
        onClick={onEdit}
      >{t('button.retract-and-edit')}</Button></>}</Space>}
      showIcon
    />}
    <StageWizard
      value={installation}
      ref={ref}
      initialName={mode.startsWith('view') ? 'basic' : installation.currentStage || 'welcome'}
      disabled={mode.startsWith('view')}
      onSave={onSave}
      onFinish={async () => {
        const installationId = installation?._id?.toString()
        if (!installationId || !installation || mode.startsWith('view')) return
        if (mode === 'edit-anonymous' || mode === 'edit-as-importer') {
          const {status} = await POST(`/cbam/installations/${installationId}/finish`, undefined, ApiCredentialType.Optional)
          if (status.success) navigate("/apps/wegreenpass/finished")
        } else if (mode === 'edit-as-exporter') {
          const {status} = await POST(`/cbam/installations/${installationId}/submit`)
          if (status.success) navigate("/apps/wegreenpass/success")
        }
      }}
      onFormValuesChange={(name, i, cv) => {
        if (!installation) return
        if (name !== 'emissionSources' || !installation.emissionSources || i === undefined || !cv.EmissionFactor) return
        installation.emissionSources[i].EmissionFactor = cv.EmissionFactor
      }}
      className={'installation wegreenpass-page'}
    >
      {
        mode.startsWith('edit') &&
        <WelcomeStage name={'welcome'} heading={t('title.step-welcome')}/>
      }
      <BasicStage
        anonymous={anonymous}
        name={'basic'}
        heading={t('title.step-installation-basic')}
      />
      <RecognizedStage name={'recognized'} heading={t('title.recognized')}/>
      <ProcessStage
        name={'processes'}
        type={'collection'}
        heading={(v, i) => {
          return t('title.step-process', {
            itemId: (i || 0) + 1,
            name: _.uniq([CL_ProductionRoutes.label(v?.Name), CL_ProductionRoutes.label(v?.Name, v?.ChildName)]).join('-'),
          })
        }}
        dot={<GoldOutlined/>}
      />
      <PrecursorStage
        name={'precursors'}
        type={'collection'}
        heading={(v, i) => t('title.step-precursor', {
          itemId: (i || 0) + 1,
          name: CL_Precursors.label(v?.Name) || ""
        })}
        dot={<GoldOutlined/>}
      />
      <GoodStage
        name={'goods'}
        type={'collection'}
        heading={(v, i) => t('title.step-good', {
          itemId: (i || 0) + 1,
          name: v?.Description?.native || t('value.new-good')
        })}
        dot={<GoldOutlined/>}
        onDelete={async (index: number) => {
          if (!installation) return
          const newInstallation = _.cloneDeep(installation)
          newInstallation.goods?.splice(index, 1)
          await onSave(newInstallation, 'goods')
          await reload('installation')
          if (ref.current?.name() === 'goods') await ref.current?.goto({
            name: 'goods',
            index: Math.min(index - 1, ref.current.index() || 0)
          })
        }}
        onAdd={async (index: number) => {
          if (!installation) return
          const newInstallation = _.cloneDeep(installation)
          newInstallation.goods?.push(NewInstallationGood({t}))
          await onSave(newInstallation, 'goods')
          const {status} = await reload('installation')
          if (status.statusCode === 40307) window.alert("OOPS, GOTCHA")
          await ref.current?.goto({
            position: 'previous',
            saving: false,
            name: 'goods'
          })
        }}
      />
      <EmissionSourceStage
        name={'emissionSources'}
        type={'collection'}
        onDelete={async (index: number) => {
          if (!installation) return
          const newInstallation = _.clone(installation)
          newInstallation.emissionSources = (newInstallation.emissionSources || []).filter((o, j) => j !== index)
          await onSave(newInstallation, 'emissionSources')
          await reload('installation')
          if (ref.current?.name() === 'emissionSources') await ref.current?.goto({
            name: 'emissionSources',
            index: Math.min(index - 1, ref.current.index() || 0)
          })
        }}
        onAdd={async (index: number) => {
          const newInstallation = _.clone(installation)
          newInstallation?.emissionSources?.push({
            Name: {
              "native": t("value.new-emission-source"),
              nativeLanguage: i18n.language,
              "en": "New emission source"
            }
          })
          await onSave(newInstallation, 'emissionSources')
          await reload('installation')
          await ref.current?.goto({
            position: 'previous',
            saving: false,
            name: 'emissionSources'
          })
        }}
        heading={(v, i) => t('title.step-emission-source-for-name', {
          itemId: (i || 0) + 1,
          name:
            v?.EmissionFactor?.Labels?.[i18n.language] ||
            v?.EmissionFactor?.Label ||
            v?.EmissionFactor?.Labels?.['en'] ||
            t('value.new-emission-source')
        })}
        dot={<GoldOutlined/>}
      />
      <AdvertiseStage name={'advertise'} heading={t('title.step-advertise')}/>
      {(mode === 'edit-anonymous' || mode === 'edit-as-importer') &&
        <ReturnStage name={'complete'} heading={t('title.step-complete')}/>}
      {mode === 'edit-as-exporter' &&
        <SubmitReviewStage name={'submit-review'} heading={t('title.step-submit-manual-check')}/>}
      {(mode === 'view-submit' || mode === 'view-anonymous') &&
        <SubmitStage name={'submit'} heading={t('title.step-submit')} disabled={false} mode={mode}/>}
      {/*{readOnly ? undefined : !anonymous ? <CompleteStage name={'complete'} title={t('title.step-complete')}/> : undefined}*/}
    </StageWizard>
    {mode === 'edit-anonymous' &&
      <InstallationFillNoticeDialog importer={installation.basic?.Importer}/>}
  </>
}