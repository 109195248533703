import {useState} from "react";
import "./FlagImage.scss"
import _ from "../common/lodash"

export interface FlagImageProps {
  iso3166: string
}

export default ({iso3166}: FlagImageProps) => {
  const [notFound, setNotFound] = useState(false)
  return <div className={'flag-image'}>
    <div className={'image'} style={{backgroundImage: _.url(notFound ? '/images/flags/_unknown' : `/images/flags/${iso3166}.png`, 'css')}} onError={() => setNotFound(true)}></div>
  </div>
}