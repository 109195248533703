import { LoadingOutlined } from "@ant-design/icons";
import { Result, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UseApiState } from "../api";
import "./Loading.scss";

export interface LoadingProps {
  fullscreen?: boolean
  state?: UseApiState
  status?: any
}

export default ({fullscreen, state, status}: LoadingProps) => {
  const {t} = useTranslation()
  return <div className={fullscreen ? 'loading fullscreen' : 'loading'}>
    {state === 'loading' || state === undefined && <Spin indicator={<LoadingOutlined/>} size={fullscreen ? 'large' : undefined}/>}
    {state === 'failed' && <Result status={'error'}>{t('validation.error-please-try-again-later')}{JSON.stringify(status)}</Result>}
    {state === 'success' && <></>}
  </div>
}