import EnvUtil, {JuryType} from "../util/EnvUtil";
import {Alert, Button, Modal, Space, Tag} from "antd";
import FlagImage from "./FlagImage";
import {useTranslation} from "react-i18next";
import {GlobalOutlined} from "@ant-design/icons";

export type JuryProps = |
  ({ type: 'alert' } & JuryAlertProps) |
  ({ type: 'tag' } & JuryTagProps) |
  ({ type: 'dialog' } & JuryDialogProps) |
  ({ type: 'button' } & JuryButtonProps)

export interface JuryAlertProps {
}

export interface JuryTagProps {
  onClick?: () => void

}

export interface JuryDialogProps {
  open?: boolean
  onContinue?: () => void
}

export interface JuryButtonProps {
  target: JuryType
  onContinue?: () => void
}

export function JuryTag({onClick}: JuryTagProps) {
  const {jury} = EnvUtil
  const {t} = useTranslation()
  return <Tag className="tag" style={{cursor: 'pointer'}} onClick={onClick}><Space>{jury === 'cn' ? <FlagImage
    iso3166={'CN'}
  /> : <GlobalOutlined/>}{t(`jury.${jury}.tag`)}</Space></Tag>
}


export function JuryAlert({}: JuryAlertProps) {
  const {jury} = EnvUtil
  const {t} = useTranslation()
  return <Alert
    type={'info'} showIcon
    message={t(`jury.${jury}.message`)}
    action={<JuryButton target={jury === 'cn' ? 'intl' : 'cn'}/>}
  ></Alert>
}

export function JuryButton({target, onContinue}: JuryButtonProps) {
  const {jury} = EnvUtil
  const {t} = useTranslation()
  return <Button
    icon={target === 'cn' ? <FlagImage
      iso3166={'CN'}
    /> : <GlobalOutlined/>} type={jury === target ? 'default' : 'primary'}
    onClick={jury === target ? onContinue : (() => window.location.href = EnvUtil.webHost('production', target))}
  >{t(`jury.${target}.switch`)}</Button>
}

export function JuryDialog({open, onContinue}: JuryDialogProps) {
  const {jury} = EnvUtil
  const {t} = useTranslation()
  const target = jury === 'cn' ? 'intl' : 'cn'
  return <Modal
    open={open} title={t(`jury.${target}.title`)}
    closable={false}
    footer={<Space>
      <JuryButton target={'cn'} onContinue={onContinue}/>
      <JuryButton target={'intl'} onContinue={onContinue}/>
    </Space>}
  >
    <p>{t(`jury.${jury}.message`)}</p>
  </Modal>
}

export default function Jury(props: JuryProps) {
  const {type} = props
  switch (type) {
    case 'alert':
      return <JuryAlert {...props}/>;
    case 'button':
      return <JuryButton {...props}/>;
    case 'dialog':
      return <JuryDialog  {...props}/>;
    case 'tag':
      return <JuryTag {...props}/>
  }
}