import { GiftOutlined } from "@ant-design/icons";
import { Card, Space, Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import Promotion from "../common/Promotion";
import "./PromotionCard.scss";

export interface PromotionCardProps {
  promotion?: Promotion
}

export default ({promotion}: PromotionCardProps) => {
  const {t} = useTranslation()
  return <div className={'promotion-card'}>
    <div className={'tag'}>
      {t('field.coupon')}
    </div>
    <Space className={'promotion'}><span className={'number'}>{promotion?.DiscountRate.toFixed(1)}%</span>{t('value.discount')}</Space>
    <div className={'condition'}>{t('description.coupon-applies-for-specified-products')}</div>
    <div className={'expiry'}><Space>{t('field.expiry-date')}{dayjs(promotion?.ExpiryDate).format("YYYY-MM-DD")}</Space></div>

  </div>
  return <Card bordered={false} className={'promotion-card'} size={'small'} title={<span className={'title'}>{t('title.congratulations-promo-code')}</span>}>
    <Space>{promotion?.Name}<Tag color={'yellow'} icon={<GiftOutlined/>}>{t('value.discounted')}{promotion?.DiscountRate.toFixed(1)}%</Tag></Space>
  </Card>
}