import React from "react";
import {StageProps, useStageForm} from "../Stage";
import {Declaration} from "../../../common/Declaration";
import {Trans, useTranslation} from "react-i18next";
import Lottie from 'lottie-react'
import WelcomeLottie from "../../data/WelcomeLottie.json"
import "./WelcomeStage.scss"
import {Checkbox, Divider, Form} from "antd";
import Disclaimer from "../../component/Disclaimer";


export default (props: StageProps<Declaration>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  return <Form className={'welcome-stage'}>
    <Lottie animationData={WelcomeLottie} className={"lottie"}/>
    <h3>{t('title.welcome-to-declaration')}</h3>
    <p>{t('description.declaration-frequency')}</p>
    <p>{t('description.declaration-collection')}</p>
    <p>{t('description.declaration-services')}</p>
    <Divider/>
    <Form.Item
      name={"ApprovalChecked"}
      required={true}
      rules={[{
        type: "boolean", required: true, validator: async (_, checked) => {
          if (!checked) return Promise.reject(new Error(t('validation.required')))
        }
      }]}
      valuePropName={"checked"}
    >
      <Checkbox className={'checkbox'}><Trans i18nKey={"description.eu-login-form"}><a
        href={"https://we-carbon.com/privacy"}
        target={'_blank'}
      ></a><a href={"https://customs.ec.europa.eu/taxud/uumds/cas/uumds-wayf/legalNotice.jsp?locale=en"} target={'_blank'}></a></Trans></Checkbox>
    </Form.Item>
    <Form.Item
      name={"DisclaimerChecked"}
      required={true}
      rules={[{
        type: "boolean", required: true, validator: async (_, checked) => {
          if (!checked) return Promise.reject(new Error(t('validation.required')))
        }
      }]}
    >
      <Disclaimer/>
    </Form.Item>
  </Form>
  
}