import {useEffect, useRef, useState} from "react";
import "./LoginPage.scss"
import {Alert, Button, Form, FormInstance, message, Select, Space} from "antd";
import UsernameInput from "../component/UsernameInput";
import OtpInput from "../component/OtpInput";
import Cookies from "js-cookie";
import InitializeDialog from "../dialog/InitializeDialog";
import Apps from "../Apps";
import {useTranslation} from "react-i18next";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Disclaimer from "../app.wegreenpass/component/Disclaimer";
import {PHONE_PATTERN} from "../common/constants/Regexs";
import {OtpError} from "../common/error";
import Success from "../common/success/Success";
import {ApiCredentialType, GET, POST, validateRefreshToken} from "../api";
import Login from "../common/transient/Login";
import Account from "../common/Account";
import _ from "../common/lodash"
import useNavigate from "../hook/useNavigate";
import Logo from "../component/Logo";
import useLanguageOptions from "../hook/useLanguageOptions";
import EnvUtil from "../util/EnvUtil";
import Jury from "../component/Jury";


export default () => {
  const {t, i18n} = useTranslation()
  const [state, setState] = useState<
    "username" | // Show username step
    "username-alert-agreement" | // Show username step, while alerting agreement
    "otp" | // Show OTP step
    "initialize" // Show initialization dialog
  >('username')
  const {jury, juryMatchLanguage} = EnvUtil
  const [juryDialog, setJuryDialog] = useState(!juryMatchLanguage(i18n.language))
  const [username, setUsername] = useState<string>("")
  const [account, setAccount] = useState<Account>()
  const ref = useRef<FormInstance<any>>(null)
  const search = new URLSearchParams(window.location.search)
  const queryRedirect = decodeURIComponent(search.get('redirect') || "")
  const appInfo = Apps.getInfo(i18n)
  const navigate = useNavigate()
  const redirect = queryRedirect && _.startsWith(queryRedirect || "", '/') ? queryRedirect : appInfo.url
  const languageOptions = useLanguageOptions({
    i18n: true, onJury: () => {
      setJuryDialog(true)
    }
  })
  const [language, setLanguage] = useState(i18n.language)

  async function onSubmitUsername() {
    if (ref.current?.getFieldValue("disclaimed") !== true) return setState('username-alert-agreement')
    await ref.current?.validateFields()
    const username = ref.current?.getFieldValue("username")
    const {status} = await POST('/mfa/otp', {phone: username}, ApiCredentialType.Ignored)
    if (status.statusCode === OtpError.statusCode) return message.error(t('validation.otp-too-frequent'));
    if (status.statusCode !== Success.statusCode) return message.error(t('validation.otp-send-error'));
    setUsername(username)
    setState('otp')
  }


  async function onSubmitOtp() {
    try {
      await ref.current?.validateFields()
    } catch (e) {
      return message.error(t('validation.otp-error'))
    }
    if (!username) return message.error(t('validation.unknown-error'))
    const otp = ref.current?.getFieldValue("otp")
    const {login} = await POST<Login>("/accounts/login/mobile", {phone: username, otp}, ApiCredentialType.Ignored)
    if (!login) return message.error(t('validation.otp-error'))
    // Save refreshToken to local storage
    localStorage.setItem("refreshToken", login.refreshToken)
    // Save JWT to cookies with same expiry time as response
    Cookies.set("jwt", login.jwt, {expires: new Date(login.jwtExpiresAt)});
    // Get user info
    const {account} = await GET<Account>('/accounts/me')
    if (!account) return message.error(t('validation.failed-to-get-user-info'))
    if (!account.Profile) {
      setAccount(account)
      setState('initialize')
    } else navigate(redirect, {library: 'native'})
  }

  useEffect(() => {
    validateRefreshToken().then(valid => valid && navigate(redirect || appInfo.url));
  }, [])
  return <div className={"login-page"}>
    <div className={"container"}>
      <header>
        <Space><Logo/><Jury type={'tag'} onClick={() => setJuryDialog(true)}/></Space>
          <Select
            value={language}
            options={languageOptions}

            onSelect={async (v, o) => {
              if (o.external) return setJuryDialog(true)
              setLanguage(v)
              await i18n.changeLanguage(v)
            }}
          />
      </header>
      <div className={'alert'}>
        {!juryMatchLanguage(i18n.language) && <Jury type={'alert'}/>}
      </div>
      {(state === "username" || state === 'username-alert-agreement') &&
        <Form
          className={"form"}
          ref={ref}
        >
          <h1>{t('title.welcome-to', {name: search.has('brand') ? "WeGreenPass" : appInfo.title})}</h1>
          <Form.Item noStyle name={"username"} rules={[{required: true}, {pattern: PHONE_PATTERN}]}>
            <UsernameInput onPressEnter={onSubmitUsername}/>
          </Form.Item>
          <Form.Item
            name={'disclaimed'} rules={[{required: true}]}
            noStyle
          >
            <Disclaimer/>
          </Form.Item>
          {state === 'username-alert-agreement' &&
            <Alert
              className={'alert'}
              type={"info"}
              message={t('validation.must-approve-agreement')}
              closable showIcon onClose={() => setState('username')}
            />}
          <Button
            type={"primary"}
            className={"primary-button"}
            onClick={onSubmitUsername}
          >{t('button.next')}</Button>
        </Form>}
      {state === "otp" && <Form className={"form"} ref={ref}>
        <Space><Button
          icon={<ArrowLeftOutlined/>}
          type={"text"}
          onClick={() => setState('username')}
        >{t('button.previous')}</Button></Space>
        <h1>{t('title.enter-otp')}</h1>
        <p>{t('description.enter-otp')}</p>
        <Form.Item noStyle name={"otp"} rules={[{required: true, pattern: /^\d{6}$/g}]}>
          <OtpInput username={username} onPressEnter={onSubmitOtp} initialSeconds={60}/>
        </Form.Item>
        <Button
          type={"primary"}
          onClick={onSubmitOtp}
        >{t('button.login')}</Button>
      </Form>}
      <InitializeDialog open={state === 'initialize'} redirect={redirect} account={account}/>
      <footer>
        {t('description.copyright')}
      </footer>
    </div>
    <Jury type={'dialog'} open={juryDialog} onContinue={() => setJuryDialog(false)}/>
  </div>

}