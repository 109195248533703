import { Button, Card, Result, Space, Statistic, Table, Tag } from "antd";
import "./PromotionPage.scss";
import { DeleteOutlined, DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import _ from "../common/lodash";
import PromoterProfile from "../common/PromoterProfile";
import { useTranslation } from "react-i18next";
import CreatePromotionDialog from "../dialog/CreatePromotionDialog";
import Promotion from "../common/Promotion";
import CopyInput from "../component/CopyInput";
import dayjs from "dayjs";
import { PromotionReward } from "../common/PromotionReward";
import FlagImage from "../component/FlagImage";
import { SalesIQ } from "../component/SalesIQ";
import { DELETE, GET, useApis } from "../api";
import Loading from "../component/Loading";

export default () => {
  const { state, profiles, promotions, rewards, reload } = useApis({
    profiles: () => GET<PromoterProfile[]>("/promotions/profiles/my"),
    rewards: () => GET<PromotionReward[]>("/promotions/rewards/my"),
    promotions: () => GET<Promotion[]>("/promotions/my"),
  });
  const [dialog, setDialog] = useState<"issue" | null>(null);
  const { t } = useTranslation();
  if (state === "loading") return <Loading />;
  if (state === "failed") {
    if (profiles === undefined)
      return (
        <Result
          status={"403"}
          title={t("title.unauthorized")}
          subTitle={t("validation.you-are-not-registered-as-promoter")}
        />
      );
    return <></>;
  }
  return (
    <div className={"promotion-page"}>
      <Card
        title={t("title.my-redeem")}
        extra={
          <Button icon={<DollarOutlined />} type={"primary"} onClick={SalesIQ.startChat}>
            {t("button.cash-out")}
          </Button>
        }
      >
        <Space>
          {t("field.total-balance")}
          <Statistic
            value={_(rewards)
              .filter((o) => o.Currency === "CNY")
              .sumBy("Value")
              .toFixed(2)}
            suffix={"CNY"}
          />
          +
          <Statistic
            value={_(rewards)
              .filter((o) => o.Currency === "EUR")
              .sumBy("Value")
              .toFixed(2)}
            suffix={"EUR"}
          />
        </Space>
        <Table<PromotionReward>
          dataSource={rewards}
          columns={_.toAntdColumns({
            [t("field.date")]: (v) => dayjs(v.Date).format("YYYY-MM-DD"),
            [t("field.redeem-amount")]: (v) => (
              <Space>
                {v.Value} {v.Currency}
              </Space>
            ),
            [t("field.client-name")]: (v) =>
              typeof v.Entity === "object" && (
                <Space>
                  <FlagImage iso3166={v.Entity.region} />
                  {v.Entity.name.native}
                </Space>
              ),
            [t("field.note")]: "Note",
          })}
        />
      </Card>
      <Card
        title={t("title.my-promotion-codes")}
        extra={
          <Button icon={<PlusOutlined />} type={"primary"} onClick={() => setDialog("issue")}>
            {t("button.issue")}
          </Button>
        }
      >
        <Table<Promotion>
          dataSource={promotions}
          columns={_.toAntdColumns({
            [t("field.name")]: "Name",
            [t("field.customer-discount")]: (v) => <>{v.DiscountRate.toFixed(1)}%</>,
            [t("field.your-bonus-rate")]: (v) => <>{v.BonusRate.toFixed(1)}%</>,
            [t("field.note")]: "Note",
            [t("field.promotion-code")]: (v) => (
              <CopyInput value={v.Code} className={"copy-input"} disabled={v.status !== "active"} />
            ),
            [t("field.expiry-date")]: (v) => (
              <Space>
                {dayjs(v.ExpiryDate).format("YYYY-MM-DD")}
                {v.status === "active" && (
                  <Tag>{t("value.time-future", { daysLeft: dayjs(v.ExpiryDate).diff(dayjs(), "day") })}</Tag>
                )}
              </Space>
            ),
            [t("field.status")]: (v) => (
              <Tag color={_.where(v.status, ["used", "gray"], ["active", "green"])}>
                {_.where(v.status, ["used", t("value.used")], ["active", t("value.available")])}
              </Tag>
            ),
            [t("button.action")]: (v) => (
              <Space.Compact>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={async () => {
                    if (!v._id) return;
                    const answer = window.confirm(t("imperative.are-you-sure-to-delete-this-coupon"));
                    if (!answer) return;
                    await DELETE(`/promotions/${v._id}`);
                    await reload("promotions");
                  }}
                  disabled={v.status === "used"}
                />
              </Space.Compact>
            ),
          })}
        />
      </Card>
      <CreatePromotionDialog
        open={dialog === "issue"}
        profiles={profiles}
        onReturn={async (promotion) => {
          setDialog(null);
          await reload("promotions");
        }}
      />
    </div>
  );
};
