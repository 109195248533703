import {useTranslation} from "react-i18next";
import {Button, Result, Space} from "antd";
import {FolderViewOutlined, HomeOutlined} from "@ant-design/icons";
import useNavigate from "../../hook/useNavigate";


export default () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return <Result
    status={'success'}
    title={t('title.received-your-document')}
    subTitle={t('description.received-your-document')}
    extra={<Space><Button
      type={'primary'}
      icon={<HomeOutlined/>}
      onClick={() => navigate('/apps/wegreenpass')}
    >{t('button.return-to-home')}</Button><Button
      icon={<FolderViewOutlined/>}
      onClick={() => window.history.back()}
    >{t('button.return-to-document')}</Button></Space>}
  />
}