import React from "react";
import Account from "../common/Account";
import {POST} from "../api";
import {staticHrefNavigate} from "../hook/useNavigate";

export interface IAccountContext extends React.Context<AccountContextData>{
  Data: typeof AccountContextData
}

export class AccountContextData {
  public account: Account | undefined
  requireAccount: () => Account
  constructor(account?: Account){
    this.account = account
    this.requireAccount = () => {
      if (this.account) return this.account
      staticHrefNavigate('/login')
      throw new Error("Account not found")
    }
  }
  async logout(){
    // Retrieve the refreshToken
    const refreshToken = localStorage.getItem("refreshToken")
    // Revoke the refreshToken
    refreshToken && await POST('/accounts/logout', {refreshToken})
    // Clear localStorage
    localStorage.clear()
    // Clear document cookie
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    // Go to login page
    staticHrefNavigate('/login')
  }

}

const context = React.createContext<AccountContextData>(new AccountContextData())
Object.defineProperty(context, "Data", {
  value: AccountContextData
})
export default context as IAccountContext