import React, {forwardRef, ReactNode, Ref, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react";
import {Button, FormInstance, message, Progress, Space, Timeline} from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckCircleFilled,
  CheckOutlined,
  DeleteOutlined,
  HomeOutlined,
  LoadingOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import "./StageWizard.scss"
import {StageElementType} from "./Stage";
import _, {MergeCustomizer} from "../../common/lodash";
import StageWizardUtil from "./util/StageWizardUtil";
import useNavigate from "../../hook/useNavigate";

export default forwardRef((props: StageWizardProps, ref: Ref<StageWizardRef>) => {
  const {
    timelineExtras,
    onFinish,
    disabled,
    onSave,
    value,
    initialName,
    initialIndex,
    children,
    className
  } = props
  const [state, setState] = useState<StageWizardState>({
    name: initialName,
    index: initialIndex === undefined && Array.isArray(value[initialName]) ? 0 : initialIndex
  })
  const [saving, setSaving] = useState(false)
  const formRef = useRef<FormInstance>(null)
  const {t} = useTranslation()
  const navigate = useNavigate()

  const onAddFactory = (name: string, addition: (index: number) => void) => async () => {
    await addition(_.size(value[name]))
    await goto({
      position: 'previous',
      saving: false,
      name,
      index: value[name].length - 1
    })
  }

  const flatChildren = useMemo(() => _(children)
    .compact()
    .flatMap(o => {
      // If a child does not represent a collection, return the child
      if (o.props.type !== 'collection') return [StageWizardUtil.inject(o, props, {formRef})]
      const placeholders: StageElementType[] = o.props.onAdd && !disabled ? [<o.type
        {...o.props}
        type="placeholder"
        onClick={() => o.props.onAdd?.(value[o.props.name]?.length ?? 0)}
        heading={
          <Button
            icon={<PlusOutlined/>}
            type={'primary'}
            onClick={onAddFactory(o.props.name, o.props.onAdd)}
          >{t('button.add-generic', {name: t('field.' + o.props.name)})}</Button>
        }
        dot={<></>}
      />] : []
      // If a child represents an empty collection, return a placeholder
      if (_.size(value[o.props.name]) === 0) return placeholders
      // If a child represents a collection, return the children and a placeholder
      return _.concat<StageElementType>(
        _.map(value[o.props.name], (v, index: number) => StageWizardUtil.inject(o, props, {formRef, index})),
        placeholders
      )
    })
    .value(), [children, value, formRef, onAddFactory, t, props])
  const currentIndex = useMemo(() => {
    const found = flatChildren.findIndex(
      e => e.props.name === state.name && e.props.index === state.index
    )
    return found === -1 ? 0 : found
  }, [flatChildren, state])
  const goto = useCallback(
    async function (o: GotoArgument) {
      let target: StageElementType | undefined;
      let FinderFunction = 'position' in o && o.position === 'previous' ? _.findLast : _.find
      if ('name' in o) target = FinderFunction(flatChildren, (e, i) =>
        e.props.name === o.name && // Name matches
        (o.index === undefined || e.props.index === o.index) && // Index matches
        e.props.type !== 'placeholder' && // Type is not placeholder
        (
          o.position === undefined || // Position is undefined
          (o.position === 'previous' && i < currentIndex) || // Position is previous
          (o.position === 'next' && i > currentIndex) // Position is next
        )
      )
      else if ('position' in o && o.position === 'previous') target = FinderFunction(flatChildren, (e, i) => i < currentIndex && e.props.type !== 'placeholder')
      else if ('position' in o && o.position === 'next') target = FinderFunction(flatChildren, (e, i) => i > currentIndex && e.props.type !== 'placeholder')
      else if ('stage' in o) target = o.stage
      if (!target) return
      // If saving, validate and save
      if (o.saving) {
        // Exit function if the form is not valid
        try {
          await formRef.current?.validateFields()
        } catch (e) {
          return message.error(t('validation.cannot-save-please-check'))
        }
        // FIXME: Move the merges to the server side
        if (state.index === undefined) value[state.name] = _.mergeWith(value[state.name], formRef.current?.getFieldsValue(), MergeCustomizer)
        else {
          // Create the array if it does not exist
          if (!value[state.name]) value[state.name as any] = [] as any
          value[state.name][state.index] = _.mergeWith(value[state.name]?.[state.index], formRef.current?.getFieldsValue(), MergeCustomizer)
        }
        // Save the current document
        setSaving(true)
        try {
          await onSave(value, target.props.name)
        } catch (e: any) {
          return message.warning(t("validation.cannot-save-please-check"))
        } finally {
          setSaving(false)
        }
      }
      // Find next stage index according to where
      // Go to the target
      target && setState({name: target.props.name, index: target.props.index})
    },
    [state, value, onSave, t, formRef, flatChildren, currentIndex]
  )

  useImperativeHandle(ref, () => ({
    name: () => state.name,
    index: () => state.index,
    goto
  }), [state, children])

  // Child with value and onChange
  return <div className={["stage-wizard", className].filter(_ => _).join(" ")}>
    <aside>
      <div className={"navigation"}>
        <Space><Button type={"text"} icon={<HomeOutlined/>} onClick={() => navigate('/apps/wegreenpass')}/>
          <h3>{t("title.navigation")}</h3></Space>
        <Timeline
          className={'timeline'}
          items={flatChildren.map((o, i) => ({
            dot: o.props.dot ?? (i < currentIndex ? <CheckCircleFilled/> : undefined),
            key: `${o.props.name}-${o.props.type}-${o.props.index || 0}`,
            color: (i <= currentIndex ? '#2c528f' : '#aaaaaa'),
            children: <Space direction={"vertical"} className={'timeline-item'}>
              <Space>
                {o.props.type === 'placeholder'
                  ? StageWizardUtil.renderHeading(o)
                  : <Button
                    key={`${o.props.name}-${o.props.index || 0}`}
                    className={"navigation-button"}
                    type={"text"}
                    disabled={!disabled && i > currentIndex}
                    onClick={() => goto({stage: o, saving: false})}
                    style={{color: (!disabled && i > currentIndex ? '#aaaaaa' : '#2c528f')}}
                  >
                    {StageWizardUtil.renderHeading(o)}
                  </Button>}
                {o.props.onDelete && o.props.index !== undefined && !disabled ? <Button
                  icon={<DeleteOutlined/>}
                  onClick={async () => {
                    if (o.props.onDelete && o.props.index !== undefined) o.props.onDelete(o.props.index)
                  }}
                /> : undefined}
              </Space>
            </Space>
          }))}
        />
        {timelineExtras}
      </div>
    </aside>
    <main>
      <Progress
        percent={Math.floor((currentIndex + 1) / flatChildren.length * 100)}
        className={"progress"}
        strokeColor={'var(--secondary-color)'}
      />
      <header>
        <Button
          type={"text"}
          disabled={currentIndex === 0 || saving}
          onClick={() => currentIndex !== undefined && goto({position: 'previous', saving: false})}
          icon={<ArrowLeftOutlined/>}
        >{t("button.previous")}</Button>
        <h3>{StageWizardUtil.renderHeading(flatChildren[currentIndex])}</h3>
        <Button
          type={"primary"}
          // Disable on: (last-child && no-on-finish) || saving
          disabled={(currentIndex === flatChildren.length - 1 && (!onFinish || disabled)) || saving}
          icon={(currentIndex === flatChildren.length - 1 && onFinish && !disabled) ? <CheckOutlined/> : saving ?
            <LoadingOutlined/> : <ArrowRightOutlined/>}
          onClick={currentIndex === flatChildren.length - 1 && !disabled ? () => onFinish?.() : () => currentIndex !== undefined && goto({
            position: 'next',
            saving: !disabled
          })}
        >{disabled ? t('button.next') : currentIndex === flatChildren.length - 1 && onFinish ? t('button.submit') : t("button.save-and-next")}</Button>
      </header>
      <div className={"stage"}>
        {flatChildren[currentIndex]}
      </div>
    </main>
  </div>

})


export type FalseType = "" | 0 | false | null | undefined

export interface StageWizardProps<StageWizardDataType extends Record<string, any> = Record<string, any>> {
  value: StageWizardDataType
  onSave: (value?: StageWizardDataType, stageName?: string) => void
  onFinish?: (value?: StageWizardDataType) => void
  onFormValuesChange?: (name: string, index?: number, changedValues?: any, values?: any) => void
  initialName: string
  initialIndex?: number
  disabled?: boolean
  children: (StageElementType | FalseType)[]
  className?: string
  timelineExtras?: ReactNode[] | ReactNode
  onAdd?: (name: string) => void
  onDelete?: (name: string, index?: string) => void
}
export interface StageWizardState {
  name: string
  index?: number
}
export type GotoArgument = {
  position: 'previous' | 'next'
  saving?: boolean
} | {
  stage: StageElementType
  saving?: boolean
} | {
  position?: 'previous' | 'next'
  name: string
  index?: number
  saving?: boolean
}
export type StageWizardRef = {
  name: () => string
  index: () => number | undefined
  goto: (o: GotoArgument) => Promise<any>
}
