import _ from "./Lodash"

export function IdEqualCustomizer(value: any, other: any) {
  const valueId = typeof value === 'string' ? value : value?._id?.toString()
  const otherId = typeof other === 'string' ? other : other?._id?.toString()
  return valueId === otherId
}

export function MergeCustomizer(objValue: any, srcValue: any): any {
  if (srcValue === undefined) return objValue; // If source is undefined, keep original value.

  // Check if both objValue and srcValue are plain objects, not Day.js objects, and objValue is not an array
  if (_.isPlainObject(objValue) && _.isPlainObject(srcValue) && !Array.isArray(objValue) && !(srcValue?.$isDayjsObject)) {
    // If so, merge them recursively
    return _.mergeWith({}, objValue, srcValue, MergeCustomizer);
  }

  // For arrays or Day.js objects, or if srcValue is not an object, overwrite the value
  return srcValue;
}