import {ReactNode} from "react";
import {Popover, Space} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import "./HintedLabel.scss"

export default ({children, title}: {
  children?: ReactNode[] | ReactNode
  title?: ReactNode
}) => {
  return <Space className={'hinted-label'}>{title}<Popover content={children} title={title} trigger={["hover", "click"]}>
    <QuestionCircleOutlined className={'icon'}/>
  </Popover></Space>
}