import {
  EditOutlined,
  EnvironmentOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Select, Space } from "antd";
import DisabledContext from "antd/es/config-provider/DisabledContext";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiCredentialType, POST } from "../../api";
import Address from "../../common/Address";
import _ from "../../common/lodash";
import IFormItemProps from "../../form/IFormItemProps";
import EditLocationDialog from "../dialog/EditLocationDialog";
import "./LocationPicker.scss";

export interface LocationPickerProps extends IFormItemProps<Address> {
  region?: string;
}

export default ({
  region,
  value,
  onChange,
  disabled,
  ...props
}: LocationPickerProps) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [dialog, setDialog] = useState<"edit" | null>(null);
  const [composition, setComposition] = useState(false);
  const { t } = useTranslation();
  const contextualDisabled = useContext(DisabledContext) || disabled;
  const onSearch = async (v: string) => {
    if (composition || !v) return;
    setLoading(true);
    const { addresses } = await POST<Address[]>(
      "/google/places",
      { query: v },
      ApiCredentialType.Ignored
    );
    if (addresses && _.size(addresses) > 0) setAddresses(addresses);
    setLoading(false);
  };
  const onComposition = (e: Event) => {
    if (e.type === "compositionstart") return setComposition(true);
    if (e.type === "compositionend") {
      setComposition(false);
      onSearch((e.target as HTMLInputElement).value).then((r) => r);
    }
  };
  const options =
    _.size(addresses) > 0
      ? _.map(_.compact(addresses), (o) => ({
          value: o.PlaceId || "",
          label: o.FormattedAddress || "",
          name: o.Name || "",
        }))
      : value
      ? [
          {
            value: value?.PlaceId || "edited",
            label: value?.FormattedAddress || value?.Street || "",
          },
        ]
      : [];
  return (
    <Space.Compact className={"location-picker"}>
      <Select
        onFocus={(e) => {
          if (e.target.getAttribute("composition-listener-attached") === "true")
            return;
          e.target.addEventListener("compositionstart", onComposition);
          e.target.addEventListener("compositionend", onComposition);
          e.target.setAttribute("composition-listener-attached", "true");
        }}
        showSearch
        filterOption={false}
        placeholder={t("imperative.enter-address-to-start-search")}
        onSearch={onSearch}
        value={value?.Country ? value?.Name : undefined}
        onChange={(value) =>
          onChange?.(addresses.find((_) => _.PlaceId === value))
        }
        options={options}
        suffixIcon={loading ? <LoadingOutlined /> : <SearchOutlined />}
        optionRender={(o) => {
          return (
            <div className={"option"}>
              <span className={"caption"}>{o.data.name}</span>
              <div className={"description"}>
                <EnvironmentOutlined className={"icon"} />
                <span>{o.label}</span>
              </div>
            </div>
          );
        }}
        rootClassName={"location-picker-select"}
        notFoundContent={t("validation.please-continue-typing")}
      />
      <Button
        icon={contextualDisabled ? <SearchOutlined /> : <EditOutlined />}
        onClick={() => setDialog("edit")}
        disabled={false}
      >
        {contextualDisabled ? t("button.view") : t("button.edit")}
      </Button>
      <EditLocationDialog
        open={dialog === "edit"}
        onChange={(address) => {
          setDialog(null);
          address && onChange?.(address);
        }}
        value={value}
      />
    </Space.Compact>
  );
};
