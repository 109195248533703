import React, {useContext, useEffect, useState} from "react";
import Account from "../common/Account";

import {Badge, Button, Select, Space, Table, Tabs} from "antd";
import {Declaration} from "../common/Declaration";
import {Installation} from "../common/Installation";
import Entity from "../common/Entity";
import ProfileForm from "../form/ProfileForm";
import {useParams} from "react-router-dom";
import _ from "../common/lodash";
import CodeListContext from "../context/CodeListContext";
import "./AdminUserPage.scss"
import {GET, POST, useApis} from "../api";
import Loading from "../component/Loading";
import useNavigate from "../hook/useNavigate";


export interface AdminUserPageData {
  account?: Account
  declarations?: Declaration[]
  installations?: Installation[]
  entities?: Entity[]
  
}

export default () => {
  const params = useParams<'id'>()
  const {WC_Products} = useContext(CodeListContext)
  const navigate = useNavigate();
  if (!params.id) navigate('/admin')
  const {account, declarations, installations, entities, state, status, reload} = useApis({
    account: () => GET<Account>(`/admin/accounts/${params.id}`),
    declarations: () => GET<Declaration[]>(`/admin/accounts/${params.id}/declarations`),
    installations: () => GET<Installation[]>(`/admin/accounts/${params.id}/installations`),
    entities: () => GET<Entity[]>(`/admin/accounts/${params.id}/entities`)
  }, [params])
  if (state !== "success") return <Loading state={state} status={status}/>
  const tabs = [
    {
      name: "Account information",
      content: <ProfileForm account={account} className={'tab-item'}/>
    },
    {
      name: "Declarations",
      label: <Space>Declarations<Badge count={_.sumBy(declarations, o => Number(o.status === 'reviewing'))}/></Space>,
      content: <Table
      className={'tab-item'}
      dataSource={declarations} columns={_.toAntdColumns({
      'Owner': 'ownerAccount',
      'Declarant': ['declarant', 'name'],
      'Year': 'year',
      'Quarter': 'reportingPeriod',
      'Status': (record, i) => <Select
        style={{width: 200}}
        value={record.status}
        options={['draft', 'reviewing', 'submitted'].map(s => ({value: s, label: s}))}
        onChange={async (status) => {
          record._id && await POST(`/admin/declarations/${record._id}/status`, {status})
          await reload('declarations')
        }}
      />,
      'View': (record) => <Space>
        <Button onClick={() => window.open(`/apps/wegreenpass/declarations/${record._id}?mode=view`)}>View</Button>
        <Button onClick={() => window.open(`/apps/wegreenpass/declarations/${record._id}`)}>Edit</Button>
        {record.status === 'reviewing' && <>

          <Button onClick={async () => {
            await POST(`/admin/declarations/${record._id}/status`, {status: 'submitted'})
            await reload('declarations')
          }
          }>Accept</Button>
          <Button onClick={async () => {
            const reason = window.prompt("Reason for rejection? (Leave blank to cancel)")
            if (!reason) return
            await POST(`/admin/declarations/${record._id}/status`, {status: 'rejected', reason})
            await reload('declarations')
          }
          }>Reject</Button>
        </>}
      </Space>
    })}
    />
    },
    {
      name: "Installations",
      label: <Space>Installations<Badge count={_.sumBy(installations, o => Number(o.status === 'reviewing'))}/></Space>,
      content: <Table className={'tab-item'}
      dataSource={installations} columns={_.toAntdColumns({
      'Owner': 'ownerAccount',
      'Status': 'status',
      'Importer': ['basic', 'Importer', 'name'],
      'Entity': ['basic', 'Entity', 'Name'],
      'View': ( record) => <Space>
        <Button onClick={() => window.open(`/apps/wegreenpass/installations/${record._id}?mode=view`)}>View</Button>
        <Button onClick={() => window.open(`/apps/wegreenpass/installations/${record._id}`)}>Edit</Button>
        {record.status === 'reviewing' && <>

          <Button onClick={async () => {
            await POST(`/admin/installations/${record._id}/status`, {status: 'submitted'})
            await reload('installations')
          }
          }>Accept</Button>
          <Button onClick={async () => {
            const reason = window.prompt("Reason for rejection? (Leave blank to cancel)")
            if (!reason) return
            await POST(`/admin/installations/${record._id}/status`, {status: 'rejected', reason})
            await reload('installations')
          }
          }>Reject</Button>
        </>}
      </Space>
    })}
    />

    },
    {
      name: "Sales Agent",
      content: <div className={'tab-item'}>
      This user {account.Flags.SalesAgent ? "is" : "is not"} a sales agent.
      <Button onClick={async () => {
        await POST(`/admin/accounts/${account._id}/salesAgent`, {SalesAgent: !account.Flags.SalesAgent})
        await reload('account')
      }}>Toggle</Button>
    </div>
    }
  ]
  return <div className={"admin-user-page"}>
    <Tabs tabPosition={'left'} items={tabs.map(({name, label, content}) => ({key: name, label: label || name, children: content}))}/>
  </div>
}