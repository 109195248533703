import {Button, List, message, Popconfirm, Space, Tag} from "antd";
import {
  AlertOutlined,
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PlusOutlined
} from "@ant-design/icons";

import {Installation} from "../../common/Installation";
import Entity from "../../common/Entity";
import {useTranslation} from "react-i18next";
import {DELETE, GET, POST, useApis} from "../../api";
import _ from "../../common/lodash"
import StatusTag from "./StatusTag";
import useNavigate from "../../hook/useNavigate";
import {SalesIQ} from "../../component/SalesIQ";

export interface InstallationListProps {
  declarant?: Entity
}

export default ({declarant}: InstallationListProps) => {
  const {t} = useTranslation()
  const {installations, reload} = useApis({
    installations: declarant?._id && (() => GET<Installation[]>(`/cbam/installations/my/${declarant?._id}`))
  })
  const navigate = useNavigate()

  return <List
    bordered
    dataSource={installations}
    className={'installation-list'}
    renderItem={o => <List.Item key={o._id?.toString()}>
      <Space>
        <FileOutlined/>
        <a onClick={() => navigate(`/apps/wegreenpass/installations/${o._id}`)}>{o.basic?.Name || t('value.unnamed-report')}</a>
        <StatusTag status={o.status}/>
      </Space>
      <Popconfirm
        title={t('imperative.are-you-sure-to-delete-this-report')}
        onOpenChange={(open) => open && SalesIQ.track(`Attempting to delete Installation ${o.basic?.Name} (#${o._id})`)}
        onConfirm={async ()=>{
          SalesIQ.track(`Confirmed to delete Installation ${o.basic?.Name} (#${o._id})`)
          if (!o._id || !declarant) return
          await DELETE(`/cbam/installations/${o._id}`)
          await reload('installations')
        }}
        overlayClassName={'delete pop-confirm overlay'}
      >
        <Button icon={<DeleteOutlined/>}/>
      </Popconfirm>
    </List.Item>}
    header={<Button
      type={"primary"}
      icon={<PlusOutlined/>}
      className={'create-button'}
      onClick={async ()=>{
        if (!declarant) return message.error(t('validation.no-declarant'))
        const {installation, status} = await POST<Installation>('/cbam/installations', {installation: {declarant}})
        if (!status.success || !installation) return message.error(t('validation.create-installation-failed'))
        navigate(`/apps/wegreenpass/installations/${installation._id}`)
      }}
    >{t("button.new-document")}</Button>}
  />
}