import Promotion from "../common/Promotion";
import {Button, Form, Input, InputNumber, message, Modal, Select, Slider, Space, Table, Tag} from "antd";
import {ObjectInputSelect} from "../app.wegreenpass/component/ObjectSelect";
import dayjs from "dayjs";
import Product from "../common/Product";
import _ from "../common/lodash";
import {useEffect, useRef, useState} from "react";
import PromoterProfile from "../common/PromoterProfile";

import {useTranslation} from "react-i18next";
import {FormInstance} from "antd/es/form/Form";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {GET, POST, useApis} from "../api";
import Loading from "../component/Loading";

export interface CreatePromotionDialogProps {
  open: boolean
  profiles: PromoterProfile[]
  onReturn: (promotion?: Promotion) => void
}

export default ({open, profiles, onReturn}: CreatePromotionDialogProps) => {
  const [profile, setProfile] = useState<PromoterProfile>()
  const [bonusRate, setBonusRate] = useState<number>(0)
  const {t} = useTranslation()
  const ref = useRef<FormInstance>(null)
  const {state, products} = useApis({
    products: () => GET<Product[]>('/products', {priceGreaterThan: 0})
  })
  useEffect(() => {
    setBonusRate(0)
    setProfile(undefined)
  }, [open])
  if (state !== "success") return <Loading state={state}/>
  return <Modal
    open={open}
    title={t('title.issue-new-promo-code')}
    onCancel={() => onReturn()}
    className={'promotion-page dialog'}
    width={800}
    footer={profile ? <Space>
      <Button
        icon={<CheckOutlined/>} type={'primary'} onClick={async () => {
        if (!ref.current) return
        try {
          await ref.current.validateFields()
        } catch (e) {
          return message.error(t('validation.cannot-save-please-check'))
        }
        const {promotion} = await POST('/promotions', {profile, ...ref.current.getFieldsValue()})
        if (!promotion) return message.error(t('validation.cannot-create-promo-code'))
        onReturn(promotion)
      }
      }
      >{t('button.issue-promo-code')}</Button>
      <Button icon={<CloseOutlined/>} onClick={() => onReturn()}>{t('button.cancel')}</Button>
    </Space> : null}
    destroyOnClose
  >
    <Form
      ref={ref} initialValues={{
      DurationDays: 30
    }} onValuesChange={(cv, v) => {
      if ('Profile' in cv) {
        setProfile(cv.Profile)
        setBonusRate(cv.Profile.BenefitRate)
        ref.current?.setFieldValue('BonusRate', cv.Profile.BenefitRate)
      }
      if ('BonusRate' in cv) setBonusRate(cv.BonusRate)
    }}
    >
      <Form.Item label={t('field.promotion-policy')} name={"Profile"} required={true} rules={[{required: true}]}>
        <ObjectInputSelect
          name={t('field.promotion-policy')} data={profiles} columns={_.toAntdColumns({
          [t('field.name')]: v => v.Name,
          [t('field.total-discount-amount')]: v => v.BenefitRate + "%",
          [t('field.end-date')]: v => <Space>
            {dayjs(v.EndDate).format('YYYY-MM-DD')}
            <Tag>{t('value.time-future', {daysLeft: dayjs(v.EndDate).diff(dayjs(), 'day')})}</Tag>
          </Space>
        })} renderItem={v => v && <Space direction={'vertical'}>
          {v?.Name}
          <span>{t('field.total-discount-amount')}{v?.BenefitRate}%</span>
          <span>{t('field.end-date')}{dayjs(v.EndDate).format("YYYY-MM-DD")}</span>
        </Space>}
        />
      </Form.Item>
      <Form.Item
        label={t('field.expiry-duration')}
        name={"DurationDays"}
        required={true}
        rules={[{required: true}, {
          type: 'number',
          max: profile ? dayjs(profile.EndDate).diff(dayjs(), 'days') : undefined,
          message: t('validation.expiry-duration-cannot-succeed-current-policy')
        }]}
      >
        <InputNumber suffix={t('value.day')}/>
      </Form.Item>
      <Form.Item label={t('field.note')} name={"Note"} required={true} rules={[{required: true}]}>
        <Input placeholder={t('description.note-is-private')}/>
      </Form.Item>
      {profile && <>
        <Form.Item label={t('field.name')} name={"Name"} required={true} rules={[{required: true}]}>
          <Select options={_.map(profile.AllowedNames, s => ({label: s, value: s}))}/>
        </Form.Item>
        <Form.Item label={t('field.bonus-rate')} name={"BonusRate"} required={true} rules={[{required: true}]}>
          <Slider
            max={profile?.BenefitRate} step={0.1} min={0} tooltip={{
            open: open, formatter: v => <Space direction={'vertical'}>
              <span>{t('field.customer-bonus-rate')}{(profile.BenefitRate - bonusRate).toFixed(1)}%</span>
              <span>{t('field.your-bonus-rate')}{bonusRate.toFixed(1)}%</span>
            </Space>
          }}
          />
        </Form.Item>
        <Table<Product> dataSource={products} columns={_.toAntdColumns({
          [t('field.item-name')]: v => t('product.' + v.name),
          [t('field.original-price')]: v => <Space>{v.value} {v.currency}</Space>,
          [t('field.customer-paid-price')]: v =>
            <Space>{(v.value * (1 - (profile.BenefitRate - bonusRate) / 100)).toFixed(2)} {v.currency}</Space>,
          [t('field.your-bonus')]: v => <Space>{(v.value * bonusRate / 100).toFixed(2)} {v.currency}</Space>
        })}
        /></>}
    </Form>

  </Modal>

}