import React, {useContext, useEffect, useState} from "react";
import Entity from "../common/Entity";
import {ObjectCardSelect, ObjectInputSelect, ObjectSelectProps} from "../app.wegreenpass/component/ObjectSelect";
import IFormItemProps from "../form/IFormItemProps";
import {Alert, Button, message, Space, Tooltip} from "antd";
import FlagImage from "./FlagImage";
import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  NumberOutlined,
  PhoneOutlined,
  PlusOutlined,
  UserOutlined
} from "@ant-design/icons";
import CreateEntityDialog from "../dialog/CreateEntityDialog";
import EntityStateError from "../common/error/EntityStateError";
import PlanTag from "../app.wegreenpass/component/PlanTag";
import {useTranslation} from "react-i18next";
import AccountContext from "../context/AccountContext";
import CodeListContext from "../context/CodeListContext";
import _ from "../common/lodash"
import {DELETE, GET, useApis} from "../api";
import {ApiCredentialType} from "../api";

export interface EntitySelectProps extends IFormItemProps<Entity>, ObjectSelectProps<Entity> {
  anonymous?: boolean
  name: string
  type: 'input' | 'card'
  required?: boolean
  role?: string
}

export default (props: EntitySelectProps) => {
  const {anonymous, type, role, onChange, disabled} = props
  const [dialogType, setDialogType] = useState<'create' | null>(null)
  const [entity, setEntity] = useState<Entity>()
  const {requireAccount} = useContext(AccountContext)
  const {t} = useTranslation()
  const {WC_Roles} = useContext(CodeListContext)
  const {entities: _serverEntities, state, reload} = useApis({
    entities: !disabled && (() => GET<Entity[]>('/entities/my', {role}, ApiCredentialType.Optional))
  }, [entity, disabled, role])
  const [entities, setEntities] = useState<Entity[]>([])
  const ObjectSelect = type === 'input' ? ObjectInputSelect : ObjectCardSelect
  const getDisabled = (entity: Entity) => role !== undefined && (entity.attrs['cbam-role'] !== role)
  useEffect(() => {
    if (_serverEntities) setEntities(_serverEntities || [])
  }, [_serverEntities])
  return <><ObjectSelect<Entity>
    {...props}
    renderItem={o => o &&<Space direction={'vertical'}>
      <Space><FlagImage iso3166={o.region}/><h3>{o.name.native}</h3></Space>
      <Space>{o.name.en}</Space>
      <Space><NumberOutlined/>{o.idNumber}</Space>
      <Space><UserOutlined/>{o.contact.Name}</Space>
      <Space><PhoneOutlined/>{o.contact.Phone}</Space>
      <Space><MailOutlined/>{o.contact.Email}</Space>
    </Space>}
    data={entities}
    getCheckboxProps={o => ({disabled: getDisabled(o)})}
    selectDialogHeader={entities.every(o => getDisabled(o)) ? <Alert
      type={'warning'}
      message={t('validation.no-such-entity')}
      description={<Button
        type={'primary'}
        icon={<PlusOutlined/>}
        onClick={() => {
          setDialogType('create')
          setEntity(undefined)
        }}
      >{t('button.add-one-now')}</Button>}
      showIcon
    /> : undefined}
    columns={_.toAntdColumns({
      [t('field.name')]: (entity: Entity) => <Space>
        <FlagImage iso3166={entity.region}/>
        <span>{entity.name.native}</span>
        <PlanTag entity={entity}/>
      </Space>,
      [t('field.id-number')]: "idNumber",
      [t('field.cbam-role')]: (entity: Entity) => WC_Roles.label(entity.attrs['cbam-role']),
      [t('field.operation')]: (entity: Entity) => {
        const deletable = entity._id !== requireAccount().DeclarantEntity && entity.attrs['wegreenpass-product-name'] === 'none'
        return <Space.Compact>
          <Button
            icon={<EditOutlined/>} onClick={() => {
              setEntity(entity)
              setDialogType('create')
          }}
          />
          <Tooltip title={t('validation.cannot-delete-paid-entity')} open={!deletable && undefined}>
            <Button
              icon={<DeleteOutlined/>} onClick={async () => {
              // Delete entity
              const response = await DELETE(`/entities/${entity._id}`)
              if (response?.status.statusCode === EntityStateError.statusCode) message.error(t('validation.cannot-delete-paid-entity'))
              const {entities: _serverEntities} = await reload('entities')
              if (_serverEntities) setEntities(_serverEntities)
            }} danger disabled={!deletable}
            />
          </Tooltip>
        </Space.Compact>;
      }
    })}
    onAdd={disabled ? undefined : () => {
      setEntity(undefined)
      setDialogType('create')
    }}
  />
    <CreateEntityDialog
      anonymous={anonymous}
      open={dialogType === 'create'}
      onReturn={(returned) => {
        setDialogType(null)
        setEntity(returned)
        if (!(anonymous && returned)) return;
        const existing = entities.findIndex(o => o.idNumber === returned.idNumber)
        setEntities(existing === -1 ? [...entities, returned] : entities.splice(existing, 1, returned))
        onChange?.(returned)
      }}
      value={entity}
      roles={role ? [role] : undefined}
    />

  </>
}