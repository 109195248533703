import {useEffect} from "react";

import {StripeEmbeddedCheckout} from "@stripe/stripe-js/types/stripe-js/embedded-checkout";
import Transaction from "../../common/Transaction";
import EnvUtil from "../../util/EnvUtil";

export interface StripeCheckoutProps {
  transaction: Transaction
}

let stripe: any
let checkout: StripeEmbeddedCheckout

export default ({transaction}: StripeCheckoutProps) => {
  useEffect(() => {
    (async () => {
      if (!stripe) stripe = await require('@stripe/stripe-js').loadStripe(EnvUtil.stripe)
    })()
  })
  useEffect(() => {
    (async () => {
      if (!stripe) stripe = await require('@stripe/stripe-js').loadStripe(EnvUtil.stripe)
      if (!transaction?.Payload.client_secret) return
      await checkout?.destroy()
      checkout = await stripe?.initEmbeddedCheckout({clientSecret: transaction.Payload.client_secret})
      checkout?.mount("#stripe-checkout")
    })()
  }, [transaction])
  return <div id={'stripe-checkout'}/>
}