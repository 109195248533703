import HomePage from "./page/HomePage";
import {App} from "../App";
import DeclarationPage from "./page/DeclarationPage";
import InstallationPage from "./page/InstallationPage";
import FinishedPage from "./page/FinishedPage";
import ValidationPage from "./page/ValidationPage";
import OffsetPage from "./page/OffsetPage";
import NotFoundPage from "./page/NotFoundPage";
import DocumentsPage from "./page/DocumentsPage";
import SuccessPage from "./page/SuccessPage";
import SlotPage from "./page/SlotPage";
import TestPage from "./page/TestPage";

export default new App({
  name: "wegreenpass",
  title: "product.wegreenpass",
  renderPages: {
    "/": () => <HomePage/>,
    "/validation": () => <ValidationPage/>,
    "/offset": () => <OffsetPage/>,
    "/finished": () => <FinishedPage/>,
    "/success": () => <SuccessPage/>,
    "/test": () => <TestPage/>,
    '/documents': () => <DocumentsPage/>,
    "/declarations/:id": () => <DeclarationPage/>,
    "/installations/:id/fill": () => <InstallationPage anonymous={true}/>,
    "/installations/:id": () => <InstallationPage/>,
    "/declarations/:id/slots": () => <SlotPage/>,
    "/declarations/404": () => <NotFoundPage type={'declaration'}/>,
    "/installations/404": () => <NotFoundPage type={'installation'}/>,
      "/*": () => <NotFoundPage type={'page'} url={'/apps/wegreenpass'}/>,
  }
})