import React, {useContext, useState} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Button, Form, Input, message, Radio, Space} from "antd";
import {Declaration} from "../../../common/Declaration";
import {Trans, useTranslation} from "react-i18next";
import CallForAction from "../../component/CallForAction";
import {
  CheckCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  SendOutlined
} from "@ant-design/icons";

import base64toBlob from "b64-to-blob";
import fileSaver from "file-saver";
import "./SubmitStage.scss"
import {SalesIQ} from "../../../component/SalesIQ";
import AccountContext from "../../../context/AccountContext";
import {POST} from "../../../api";


export default (props: StageProps<Declaration>) => {
  const Form = useStageForm(props)
  const {root, value} = props
  const {t} = useTranslation()
  const [choice, setChoice] = useState<string>(value?.SubmissionMethod)
  const {requireAccount} = useContext(AccountContext)
  const [status, setStatus] = useState<'idle' | 'loading' | 'finished'>('idle')
  
  async function onSendEmail(){
    if (!root?._id) return
    const {email} = await POST(`/cbam/declarations/${root._id}/mail`)
    if (email) message.success(t('validation.mail-sent-to-address', {email: email}))
  }

  async function requestZip(id?: string) {
    if (!id) return
    setStatus('loading')
    const {zip} = await POST(`/cbam/declarations/${id}/export`)
    if (!zip){
      message.error(t('validation.failed-to-export'))
      setStatus('idle')
      return
    }
    const blob = base64toBlob(zip, 'application/zip')
    const file = new File([blob], `CBAM_${new Date().toISOString()}_${id}.zip`, {type: "application/zip"})
    fileSaver.saveAs(file)
    setStatus('finished')
  }
  
  return <Form onValuesChange={cv => {
    if (cv.SubmissionMethod) setChoice(cv.SubmissionMethod)
  }} className={'submit-stage'} initialValues={{
    Email: requireAccount().Profile?.Email
  }} disabled={false}>
    <h3>{t('title.send-this-form-to-my-email')}</h3>
    <div className={'email-space'}>
      <Form.Item name={'Email'} rules={[{required: true, type: 'email'}]} noStyle>
        <Input prefix={<MailOutlined/>} autoComplete={'off'} disabled/>
      </Form.Item>
      <Button type={"primary"} icon={<SendOutlined/>} onClick={onSendEmail}>{t('button.send')}</Button>
    </div>
    <h3>{t('title.how-to-submit')}</h3>
    <Form.Item name={"SubmissionMethod"} rules={[{required: true}]}>
      <Radio.Group>
        <Radio value={"remote"}>{t("value.submit-by-remote")}</Radio>
        <Radio value={"authorize"} disabled>{t("value.submit-by-authorize")}</Radio>
        <Radio value={"download"}>{t("value.submit-by-download")}</Radio>
      </Radio.Group>
    </Form.Item>
    {choice === 'remote' &&
        <CallForAction type={"dark"} backgroundImage={'/images/photo/nastuh-abootalebi-ZtC4_rPCRXA-unsplash.jpg'}>
            <section className={'instructions'}>
                <span>{t('imperative.contact-support')}</span>
                <Space><Button type={'primary'} icon={<PhoneOutlined/>} onClick={SalesIQ.startChat}>{t('button.contact-service')}</Button></Space>
            </section>
        </CallForAction>}
    {choice === 'download' && <section className={'instructions'}>
        <div>
            <Trans i18nKey={"description.login-download"}><a href={"https://taxation-customs.ec.europa.eu/system/files/2023-10/User%20Manual%20for%20CBAM%20Declarants-Release%201.1_v1.10.pdf"} target={'_blank'}/><a href={"https://cbam.ec.europa.eu/declarant"} target={'_blank'}/></Trans></div>
        <Button
            icon={
              status === 'loading' ? <LoadingOutlined/> :
                status === 'finished' ? <CheckCircleOutlined/> :
                  status === 'idle'? <DownloadOutlined/>: undefined}
            type={"primary"}
            style={{backgroundColor: status === 'finished' ? 'var(--secondary-color)' : undefined, cursor: status === 'loading' ? 'progress' : 'undefined'}}
            disabled={status === 'loading'}
            onClick={() => requestZip(root?._id?.toString())}
        >{status === 'loading' ? t('button.loading') : t("button.download-zip-report")}</Button>
    </section>}
  </Form>
  
}