import { useTranslation } from "react-i18next"
import _ from "../common/lodash"

export default () => {
  // Renders logo among technical specifications
  // 1. if url has `brand=shandong-demo`, remove logo at all times
  // 2. if the i18n is Chinese (Simp. or Trad.), use Chinese logo
  // 3. if the i18n is others, use English logo
  const { i18n } = useTranslation()
  const search = new URLSearchParams(window.location.search)
  return _.where(i18n.language,
    [search.get('brand') === 'shandong-demo' || search.get('brand') === 'zhongnan', null],
    ['zh-CN', <img src={"/images/logo.png"} alt={"WeCarbon Logo"} />],
    [true, <img src={"/images/logo-english.png"} alt={"WeCarbon Logo"} />]
  ) || null
}