import {useCallback, useEffect, useState} from "react";
import _ from "../common/lodash"
import Success, {ISuccess} from "../common/success/Success";
import IError from "../common/error/Error";
import axios, {AxiosRequestConfig} from "axios";
import i18n from "i18next";
import Cookies from "js-cookie";
import {staticHrefNavigate} from "../hook/useNavigate";
import EnvUtil from "../util/EnvUtil";

export type CredentialType = 'required' | 'optional' | 'ignored'

axios.defaults.validateStatus = () => true
axios.defaults.withCredentials = true

export type Falsy = undefined | false | null | ""

export type UseApiState = 'success' | 'failed' | 'loading'

export type ServerResponseData<T, K extends string | never> = ({
  status: {
    success: true
    statusCode: number
    message: string
  }
} & { [key in K]: T }) | ({
  status: {
    success: false
    statusCode: number
    message: string
  }
} & { [key in K]: undefined })

export type SuccessUseApis<T> =
  { [key in keyof T]: T[key] extends () => Promise<ServerResponseData<infer U, string>> ? U : T[key] extends ((() => Promise<ServerResponseData<infer U, string>>) | Falsy) ? U | undefined : never }
  & {
  state: 'success'
  reload: (...keys: (keyof T)[]) => UseApis<T>
  status: ISuccess
}
export type FailedUseApis<T> =
  { [key in keyof T]: T[key] extends ((() => Promise<ServerResponseData<infer U, string>>) | Falsy) ? U | undefined : never }
  & {
  state: 'failed'
  reload: (...keys: (keyof T)[]) => UseApis<T>
  status: IError
}
export type LoadingUseApis<T> = { [key in keyof T]: undefined } & {
  state: 'loading'
  reload: (...keys: (keyof T)[]) => UseApis<T>
  status: ISuccess
}

export type UseApis<T> = SuccessUseApis<T> | FailedUseApis<T> | LoadingUseApis<T>

export function redirectLogin() {
  window.location.href = "/login"
}

export function useApis<K extends string, T extends {
  [key in K]: (() => Promise<ServerResponseData<any, K>>) | Falsy
}>(apis: T, deps: any[] = []): UseApis<T> {
  const [state, setState] = useState<'success' | 'loading' | 'failed'>('loading')
  const [status, setStatus] = useState<ISuccess | IError>(Success)
  const [data, setData] = useState<any>({})
  const reload = useCallback(async (...keys: string[]) => {
    // setState('loading') -> FIXME: Deliberately not setting loading state, because this causes a flicker
    let newStatus = status
    const dataPairs = await Promise.all(
      _(apis).toPairs().filter(([k, v]) => keys.includes(k) && Boolean(v)).map(async ([k, v]: any) => {
        const {status: partialStatus, [k]: value} = await v()
        if (partialStatus.success) return [k, value]
        newStatus = partialStatus
        return [k, undefined]
      }).value()
    )
    const newState = newStatus.success ? 'success' : 'failed'
    const newData = {
      ...data,
      ..._.fromPairs(dataPairs)
    }
    if (data !== newData) setData(newData)
    setState(newState)
    if (status !== newStatus) setStatus(newStatus)
    return {state: newState, status: newStatus, reload, ...newData}
  }, [apis, status, data, state, setStatus, setData, setState])
  useEffect(() => {
    reload(...Object.keys(apis)).then(null)
  }, deps)
  return {state, status, reload, ...data}
}

export enum ApiCredentialType {
  Required = 'required',
  Optional = 'optional',
  Ignored = 'ignored'
}

export interface ServiceRequestConfig extends AxiosRequestConfig {
  // Credentials are required by default
  credential?: ApiCredentialType
  retries?: number
}

export async function ServiceAxios<ValueType = any, KeyType extends string = string, RequestType = any>({
  url,
  method,
  data,
  ...options
}: ServiceRequestConfig): Promise<ServerResponseData<ValueType, KeyType>> {
  /*
    credential = Required:
      > if (!refreshToken) goto login
      >
    credential = Optional: WITH_CRED NO_REDIR REFRESH_IF_THERE_IS_REFRESH_TOKEN
    credential = Ignored:  NO_WITH_CRED NO_REDIR NO_REFRESH
   */
  // Check refreshToken, and go to log in if not existing
  const baseOptions = {
    ...options,
    validateStatus: null,
    headers: {
      ...options?.headers,
      "Accept-Language": i18n.language,
    }
  }

  // request-login, get-jwt, leave, request-anonymous
  const credential = options?.credential || ApiCredentialType.Required
  let refreshToken = localStorage.getItem("refreshToken")
  let jwt = Cookies.get("jwt") || null
  let action: 'request-login' | 'request-anonymous' | 'get-jwt' | 'leave' | null = null

  // Set initial status
  switch (credential) {
    case ApiCredentialType.Ignored:
      action = 'request-anonymous';
      break;
    case ApiCredentialType.Required:
      if (!refreshToken) action = 'leave'
      else if (jwt) action = 'request-login'
      else action = 'get-jwt';
      break;
    case ApiCredentialType.Optional:
      if (!refreshToken) action = 'request-anonymous'
      else if (jwt) action = 'request-login'
      else action = 'get-jwt';
      break;
  }


  let retries = 3
  let response

  while (retries > 0) {
    // get-jwt -> [request-login, request-anonymous, leave]
    if (action === 'get-jwt') {
      console.debug("Performing", action, 'for', method, url)
      response = await axios.post(`${EnvUtil.apiHost()}/accounts/login/refresh`, {refreshToken})
      if (response.status === 200) {
        Cookies.set("jwt", response.data.jwt, {
          expires: new Date(response.data.expiresAt)
        })
        jwt = response.data.jwt
        action = 'request-login'
      } else {
        localStorage.removeItem("refreshToken")
        refreshToken = null
        action = credential === ApiCredentialType.Required ? 'leave' : 'request-anonymous'
      }
    }
    // request-anonymous -> end
    if (action === 'request-anonymous') {
      console.debug("Performing", action, 'for', method, url)
      const options = {method, url, data, ...baseOptions}
      response = await axios(options)
      return response.data;
    }
    // request-login -> end, get-jwt
    if (action === 'request-login') {
      console.debug("Performing", action, 'for', method, url)
      const options = {method, url, data, ...baseOptions}
      response = await axios(options)
      if (response.status === 401) {
        action = 'get-jwt'
      } else return response.data
    }
    // leave -> end
    if (action === 'leave') {
      console.debug("Performing", action, 'for', method, url)
      staticHrefNavigate('/login')
      throw new Error("Request failed")
    }
    retries--
  }

  staticHrefNavigate('/login')
  throw new Error("Request failed")

}

export function GET<T = never>(url: string, data?: any, credential?: ApiCredentialType, o?: ServiceRequestConfig): Promise<ServerResponseData<T, string>> {
  const queryStr = _({...data}).omitBy(_.isNil).toPairs().map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")
  return ServiceAxios({...o, method: "GET", url: _.filter([EnvUtil.apiHost() + url, queryStr], _.identity).join('?'), credential})
}

export function POST<T = never>(url: string, data?: any, credential?: ApiCredentialType, o?: ServiceRequestConfig): Promise<ServerResponseData<T, string>> {
  return ServiceAxios({...o, method: "POST", url: `${EnvUtil.apiHost()}${url}`, data, credential})
}

export function PUT<T = never>(url: string, data?: any, credential?: ApiCredentialType, o?: ServiceRequestConfig): Promise<ServerResponseData<T, string>> {
  return ServiceAxios({...o, method: "PUT", url: `${EnvUtil.apiHost()}${url}`, data, credential})
}

export function DELETE<T = never>(url: string, data?: any, credential?: ApiCredentialType, o?: ServiceRequestConfig): Promise<ServerResponseData<T, string>> {
  return ServiceAxios({...o, method: "DELETE", url: `${EnvUtil.apiHost()}${url}`, data, credential})
}

export async function validateRefreshToken(): Promise<boolean> {
  const refreshToken = localStorage.getItem("refreshToken")
  if (!refreshToken) return false
  const response = await axios.post(`${EnvUtil.apiHost()}/accounts/login/refresh`, {refreshToken})
  if (response.status === 200) {
    Cookies.set("jwt", response.data.jwt, {
      expires: new Date(response.data.expiresAt)
    })
    return true
  } else return false
}