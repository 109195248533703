import {App} from "./App";
import WeGreenPassApp from "./app.wegreenpass/WeGreenPassApp";

const Apps: App[] = [
  WeGreenPassApp
]

export default {
  all: Apps,
  find: function(name: string | null | undefined): App | undefined {
    if (name === null || name === undefined) return undefined
    return Apps.find(_ => _.name === name)
  },
  getInfo: function(i18n: any): App.Info {
    const query = new URLSearchParams(window.location.search)
    const app = this.find(query.get("app"))
    if (app) return {
      url: `/apps/${app.name}`,
      title: i18n.t(app.title)
    }; else return {
      url: `/home`,
      title: i18n.t('title.wecarbon-cloud')
    }
  },
  render: function(): JSX.Element[]{
    return Apps.flatMap(_ => _.renderRoutes())
  }
}