import { Input, InputRef, Select, Space } from "antd";
import { KeyboardEventHandler, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Languages from "../../src/languages.json";
import CodeListContext from "../context/CodeListContext";
import IFormItemProps from "../form/IFormItemProps";
import FlagImage from "./FlagImage";
import "./UsernameInput.scss";

export interface UsernameInputState {
  countryCode: string
  isd: string
  username: string
}

export interface UsernameInputProps extends IFormItemProps<string> {
  value?: string
  region?: string
  onChange?: (value?: string) => void
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>
}

export default ({onPressEnter, onChange, value, region}: UsernameInputProps) => {
  const [state, setState] = useState<UsernameInputState>({
    countryCode: "",
    isd: "",
    username: ""
  })
  const inputRef = useRef<InputRef>(null)
  const {t, i18n} = useTranslation()
  const {WC_Regions} = useContext(CodeListContext)
  const defaultIsd = useMemo(() =>
      // Prioritize the region prop, then the language, then the default ISD
  (region && WC_Regions.attr(region, 'isd')) || Languages.find(o => o.code === i18n.language)?.isd || "+86", [i18n.language, region])

  // Update data if value changes
  useEffect(() => {
    if (!value) return

    // If value starts with the same ISD, update the username
    if (state.isd && value?.startsWith(state.isd)) return setState({
      ...state,
      username: value.slice(state.isd.length)
    })

    // If value starts with a different ISD, update the ISD and username
    const region = WC_Regions.options().find(o => o.attrs?.isd && value.startsWith(o.attrs.isd))
    if (!region?.attrs?.isd) return setState({
      countryCode: "",
      isd: "",
      username: value
    })
    setState({
      countryCode: region.value,
      isd: region.attrs.isd,
      username: value.slice(region.attrs.isd.length || 0)
    })
    inputRef.current?.focus() // If ISD changes, focus on the input
  }, [value])

  return <Space.Compact className={"username-input"}>
    {state.countryCode && <Select
        options={WC_Regions.options().filter(o => o.attrs?.isd).map(o => ({
          value: o.value,
          label: <Space><FlagImage iso3166={o.value}/>{o.label}<span>({o.attrs?.isd})</span></Space>
        }))}
        className={"country-code-selector"}
        onChange={v => {
          const isd = WC_Regions.option(v)?.attrs?.isd
          // On select, update the value
          if (v && isd) setState({countryCode: v, isd, username: state.username})
          onChange?.(`${isd || ""}${state.username}`)
        }}
        value={state.countryCode}
    />}
    <Input
      placeholder={t('field.phone')}
      className={"username"}
      onChange={e => {
        // If the input is a number and no ISD, push the default ISD (+86)
        if (/^\d{3,}$/.test(e.target.value) && !state.isd) return onChange?.(`${defaultIsd}${e.target.value}`)
        onChange?.(`${state.isd || ""}${e.target.value}`)
      }}
      value={state.username}
      ref={inputRef}
      onPressEnter={onPressEnter}
    />
  </Space.Compact>

}