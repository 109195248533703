import {StageElementType} from "../Stage";
import {StageWizardProps} from "../StageWizard";
import React, {RefObject} from "react";
import {FormInstance} from "antd";


function inject(e: StageElementType, props: StageWizardProps, o: { index?: number, formRef: RefObject<FormInstance<any>>}): StageElementType {
  /**
   * Inject global props defined in `StageWizardProps` to `StageElementType`.
   * @param e - The destination of injection.
   * @param props - The source of injection.
   * @param o - The index and form reference.
   */
  const value = o.index === undefined ? props.value[e.props.name] : props.value[e.props.name][o.index]
  return <e.type
    {...e.props}
    index={o.index}
    value={value}
    formRef={o.formRef}
    save={() => props.onSave(value, e.props.name)}
    root={props.value}
    disabled={props.disabled}
    onFormValuesChange={(cv: any, v: any) => props.onFormValuesChange?.(
      e.props.name, o.index, cv, v
    )}
    type={'object'}
    key={`${e.props.name}-object-${o.index || 0}`}
  />
}

function renderHeading(e: StageElementType){
  /**
   * Render `StageProps.heading` based on its type.
   * 1. For a function, call and return the result.
   * 2. For a string, return the string.
   * @param e - The `StageElementType` to render.
   */
  if (typeof e.props.heading === 'function'){
    return e.props.heading(e.props.value, e.props.index)
  }
  return e.props.heading
}

export default {
  inject, renderHeading
}