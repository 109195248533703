import React, {useContext} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Form, Space, Table} from "antd";
import {Declaration} from "../../../common/Declaration";
import {useTranslation} from "react-i18next";
import Good from "../../model/Good";
import _ from "../../../common/lodash";
import CodeListContext from "../../../context/CodeListContext";

export default (props: StageProps<Declaration>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  const {root} = props
  const {CL_MeasurementUnits} = useContext(CodeListContext)
  return <Form>
    <p>{t('imperative.please-check-goods-correct')}</p>
    <Form.Item
      name="validatable"
      rules={[{
        message: t('validation.please-check-good-info-press-previous-to-return'),
        validator: () => root?.goods?.every(
          _ => _.Description && _.CategoryCode && _.OriginCountry && _.ImportedQuantities
        ) ? Promise.resolve() : Promise.reject()
      }]}
    >
      <Table dataSource={root?.goods} columns={_.toAntdColumns<Good>({
        [t('field.name')]: _ => _.Description?.native || _.Description?.en,
        [t('field.category-code')]: ["CategoryCode", "key"],
        [t('field.net-mass')]: good =>
          <Space>
            {_(good.ImportedQuantities).sumBy('NetMass')}
            {CL_MeasurementUnits.label(good.ImportedQuantities?.[0]?.MeasurementUnit)}
          </Space>,
        [t('field.imported-batch')]: good => _.size(good.ImportedQuantities),
      })}/>
    </Form.Item>
  </Form>
}