import {useTranslation} from "react-i18next";
import {Button, Result} from "antd";
import _ from '../../common/lodash'
import useNavigate from "../../hook/useNavigate";

export interface NotFoundPageProps {
  type?: 'declaration' | 'installation' | 'page'
  url?: string
}

export default ({type, url}: NotFoundPageProps) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const name = _(type).where(
    ['declaration', t('field.declaration')],
    ['installation', t('field.installation')],
    t('field.page')
  ).value()
  return <Result
    status={404}
    title={t('title.page-or-document-not-found', {name})}
    subTitle={t('description.page-or-document-not-found', {name})}
    extra={<Button type={'primary'} onClick={() => navigate(url ?? '/home')}>{t('button.back-home')}</Button>}
  />
}