import Entity from "./Entity";
import Address from "./Address";
import {I18nText} from "./I18nText";
import Good from "../app.wegreenpass/model/Good";
import dayjs from "dayjs";
import EmissionFactor from "./EmissionFactor";
import IOwnableDocument from "./interface/IOwnableDocument";
import CarbonPriceDue from "../app.wegreenpass/model/CarbonPriceDue";
import Account from "./Account";
import {TFunction} from "i18next";
import {ICodeListItem} from "../common/CodeList"
import Unlocode from "./Unlocode";

export interface Installation extends IOwnableDocument {
  _id?: string
  ownerAccount: string | Account
  ownerEntity?: string | Entity
  currentStage?: string

  // How the installation data is generated
  status: 'draft' | 'reviewing' | 'rejected' | 'submitted'
  rejectionReason?: string
  //status: 'placeholder' | 'private' | 'public-waiting' | 'public-finished' | 'reviewing' | 'submitted'
  welcome?: Installation.Welcome
  basic?: Installation.Basic
  goods?: Installation.Good[]
  transitionalGoods?: Installation.TransitionalGood[]
  processes?: Installation.Process[]
  precursors?: Installation.Precursor[]
  emissionSources?: Installation.EmissionSource[]
  constraints?: Record<'ProcessesAndRoutesMustInclude', any>
}

export namespace Installation {
  export interface Good {
    Description?: I18nText
    CategoryCode?: ICodeListItem
    AssociatedProcess?: Process
    Dues?: CarbonPriceDue[]
  }

  export interface Welcome {
    ApprovalChecked: boolean,
    DisclaimerChecked: boolean
  }


  export interface Constraint {

  }

  export interface GoodMap {
    Good: Good
    NetMass: number
    MeasurementUnit: string
  }

  export interface Basic {
    Name?: string
    InstallationName?: I18nText
    StartDate?: string | dayjs.Dayjs
    EndDate?: string | dayjs.Dayjs
    Entity?: Entity
    Importer?: Entity
    Address?: Address
    UNLOCODE?: Unlocode
    GoodMapping?: GoodMap[]
    ProcessesAndRoutes?: string[][]
  }



  export interface TransitionalGood {
    MethodId: string
    SteelMillNumber?: string
    Good?: Good
    DeterminationType: string
    ProdMethodQualifyingParams: { [key: string]: string | number }
    ProducedMeasure: {
      NetMass: number
      MeasurementUnit: string
    }
    Direct: {
      Factor: number
      Total: number
      OtherSourceIndication: I18nText
      ApplicableReportingTypeMethodology: I18nText
      Justification: I18nText
      ElectricityImported: number
    }
    Indirect: {
      Factor: number
      Total: number
      OtherSourceIndication: I18nText
      ElectricitySource: I18nText
      DeterminationTypeElectricity: string
      EmissionFactorSourceElectricity: I18nText
    }

  }

  export interface Process {
    IndirectEmissionFactor?: EmissionFactor;
    Name: string
    ChildName?: string
    MethodId?: string
    ChildId?: number;
    ChildTotal?: number;
    Description?: I18nText
    ProductionTotal?: number
    EmissionFactor?: EmissionFactor
    EmissionType?: string[]
    DirectEmission?: number // deprecated
    HeatImported?: number
    HeatExported?: number
    HeatEmissionFactor?: EmissionFactor
    FuelGasImported?: number
    FuelGasExported?: number
    FuelGasEmissionFactor?: EmissionFactor
    ElectricityImported?: number
    ElectricityExported?: number
    ElectricityEmissionFactor?: EmissionFactor
    ProdMethodQualifyingParams?: { [key: string]: string | number }
  }

  export interface Measure {
    NetMass: number
    Unit: string
    Route?: string
    Process?: string
  }

  export interface Precursor {
    Name: string
    Region: string
    ProductionLevel?: { [key: string]: number }
    ConsumptionLevel?: { [key: string]: number }
    ConsumptionLevelNonCBAM?: number
    DirectEmissionFactor?: EmissionFactor
    IndirectEmissionFactor?: EmissionFactor
    Description?: I18nText
  }

  export interface EmissionSource {
    Name?: I18nText
    EmissionFactor?: EmissionFactor
    ActivityData?: number
    CalorificValue?: number
    OxidationFactor?: number
    BiomassContent?: number
    ConversionFactor?: number
  }
}


export function NewInstallation(o: {
  installation?: Installation,
  account: Account,
  entity: Entity,
  owner?: Entity
  t: TFunction,
  lastYear?: number,
  importer?: Entity,
  installationName?: I18nText,
  constraints?: Record<'ProcessesAndRoutesMustInclude', any>
}) {
  return {
    ownerAccount: o.account._id,
    ownerEntity: o.owner?._id || o.entity._id,
    status: 'draft',
    basic: {
      StartDate: o.lastYear ? `${o.lastYear}-01-01T00:00:00.000Z` : undefined,
      EndDate: o.lastYear ? `${o.lastYear}-12-31T00:00:00.000Z` : undefined,
      Name: o.t('value.default-installation-name').replace("{0}", o.entity.name.native || "Company").replace("{1}", String(o.lastYear)),
      Entity: o.entity,
      Importer: o.importer,
      InstallationName: o.installationName,
    } as Partial<Installation.Basic>,
    goods: [NewInstallationGood(o)],
    emissionSources: [NewInstallationEmissionSource(o)],
    ...o.installation,
    // Non-overwriting items
    constraints: o.constraints

  } as Partial<Installation>
}

export function NewInstallationGood(o: {
  t: TFunction
}): Installation.Good {
  return {
    Description: {
      native: o.t('value.new-good'),
      en: 'New Good'
    }
  }
}

export function NewInstallationEmissionSource(o: {
  t: TFunction
}): Installation.EmissionSource {
  return {
    Name: {
      native: o.t('value.new-emission-source'),
      en: 'New Emission Source'
    }
  }
}