import React, {useContext, useState} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Alert, Checkbox, Col, Divider, Form, InputNumber, Radio, Row, Select, Space} from "antd";
import TranslateInput from "../../../component/TranslateInput";
import {Installation} from "../../../common/Installation";
import {Trans, useTranslation} from "react-i18next";
import "../Stage.scss"
import EmissionFactorSelect from "../../../component/EmissionFactorSelect";
import HintedLabel from "../../../component/HintedLabel";
import "./ProcessStage.scss"
import dayjs from "dayjs";
import CodeListContext from "../../../context/CodeListContext";


export default (props: StageProps<Installation, Installation.Process>) => {
  const Form = useStageForm(props)
  const {value, root, formRef, onReturn} = props
  const [alertElectricity, setAlertElectricity] = useState(false)
  const [indirectForms, setIndirectForms] = useState<string[]>(value?.EmissionType || ['electricity'])
  const {
    CL_QualifyingParameterDescriptions,
    CL_ProductionMethods,
    CL_ProductionRoutes,
    WC_EmissionTypes,
    CL_QualifyingParameters
  } = useContext(CodeListContext)
  const [qualifyingParameters, setQualifyingParameters] = useState<string[]>(value?.MethodId ? CL_ProductionMethods.attrs(value?.MethodId, 'qualifyingParameters') : [])
  const {t} = useTranslation()

  return <Form
    layout={"vertical"} onValuesChange={(cv, v) => {
    if (cv?.EmissionType) setIndirectForms(v?.EmissionType)
    if (cv.MethodId) setQualifyingParameters(CL_ProductionMethods.attrs(cv.MethodId, 'qualifyingParameters'))
    if (cv.ElectricityExported !== undefined) setAlertElectricity(cv.ElectricityExported > 0)
    onReturn?.({
      Name: value?.Name,
      ChildName: value?.ChildName, ...formRef?.current?.getFieldsValue()
    })
  }} initialValues={{
    ElectricityExported: 0,
    EmissionType: value?.EmissionType?.includes('electricity') ? value?.EmissionType : ['electricity']
  }} className={"process-stage"}
  >
    <p>{t('imperative.process-declaration-introduction', {name: CL_ProductionRoutes.label(value?.Name, value?.ChildName)})}</p>
    <Form.Item
      name={"Description"}
      required={true}
      rules={[{required: true}]}
      label={
        <HintedLabel title={t('field.process-description')}>{t('description.process-description')}</HintedLabel>}
    >
      <TranslateInput capitalize={'first'}/>
    </Form.Item>
    <Form.Item
      name={"ProductionTotal"}
      required={true}
      rules={[{required: true}]}
      label={t('field.total-production-of-process', {
        name: CL_ProductionRoutes.label(value?.Name, value?.ChildName),
        startDate: root?.basic?.StartDate ? dayjs(root?.basic?.StartDate).format('YYYY-MM-DD') : "",
        endDate: root?.basic?.EndDate ? dayjs(root?.basic?.EndDate).format('YYYY-MM-DD') : ""
      })}
    >
      <InputNumber suffix={t('value.ton')} className={"input-number"}/>
    </Form.Item>
    <Form.Item
      name={"MethodId"}
      label={t("field.method-type")}
      required={true}
      rules={[{required: true}]}
    >
      <Select options={CL_ProductionMethods.options({category: CL_ProductionRoutes.attr(value?.Name || "", 'category')})}/>
    </Form.Item>
    <Form.Item
      name={"EmissionFactor"} label={t('field.direct-emission-factor')}
      required={true}
      rules={[{required: true}]}
    >
      <EmissionFactorSelect
        query={{
          ActivityType: 'DirectProduction.Solid',
          SubjectType: "CN:Category",
          Subject: value?.Name
        }}
      />
    </Form.Item>
    <Form.Item
      name={"IndirectEmissionFactor"} label={t('field.indirect-emission-factor')}
      required={true}
      rules={[{required: true}]}
    >
      <EmissionFactorSelect
        query={{
          ActivityType: 'IndirectProduction.Solid',
          SubjectType: "CN:Category",
          Subject: value?.Name
        }}
      />
    </Form.Item>
    <Form.Item
      name={"EmissionType"}
      label={
        <Space>{t('field.attributed-emission-types')}<HintedLabel title={t('field.attributed-emission-types')}>
          <Trans i18nKey={'description.according-to-guidelines-define-as-below'}><a href={'https://taxation-customs.ec.europa.eu/system/files/2023-12/Guidance%20document%20on%20CBAM%20implementation%20for%20installation%20operators%20outside%20the%20EU.pdf'}/></Trans>
          <ul>
            <li>{t('description.cbam-definition-electricity')}</li>
            <li>{t('description.cbam-definition-heat')}</li>
            <li>{t('description.cbam-definition-gas')}</li>
          </ul>
        </HintedLabel></Space>}
      required={true}
    >
      <Checkbox.Group
        options={WC_EmissionTypes.options().map(_ => ({
          ..._,
          disabled: _.value === 'electricity'
        }))}
      />
    </Form.Item>
    <h3>{t('value.electricity')}</h3>
    <Row>
      <Col span={12}><Form.Item
        name={"ElectricityImported"}
        label={
          <HintedLabel title={t('field.electricity-imported')}>{t('description.electricity-imported')}</HintedLabel>}
        required={true}
        rules={[{required: true, type: "number", min: 0}]}
      >
        <InputNumber suffix={"MWh"} className={"input-number"}/>
      </Form.Item></Col>
      <Col span={12}>
        <Form.Item
          name={"ElectricityExported"}
          label={
            <HintedLabel title={t('field.electricity-exported')}>{t('description.electricity-exported')}</HintedLabel>}
          required={true}
          rules={[{required: true, type: "number", min: 0}]}
        >
          <InputNumber suffix={"MWh"} className={"input-number"}/>
        </Form.Item>
      </Col>
    </Row>
    {alertElectricity && <Alert
      type={'info'}
      message={t('alert.positive-generating-electricity')}
    />}

    <Form.Item
      name={"ElectricityEmissionFactor"}
      label={t('field.electricity-emission-factor')}
      required={true}
      rules={[{required: true}]}
    >
      <EmissionFactorSelect
        query={{
          ActivityType: 'Electricity',
          HighlightSubject: root?.basic?.Address?.Country
        }}
      />
    </Form.Item>
    {indirectForms.includes("heat") && <>
      <h3>{t('value.heat')}</h3>
      <Row>
        <Col span={12}>
          <Form.Item
            name={"HeatImported"}
            label={
              <HintedLabel title={t('field.heat-imported')}>{t('description.heat-imported')}</HintedLabel>}
            required={true}
            rules={[{required: true, type: "number", min: 0}]}
          >
            <InputNumber suffix={"m³"} className={"input-number"}/>
          </Form.Item>

        </Col>
        <Col span={12}>
          <Form.Item
            name={"HeatExported"}
            label={
              <HintedLabel title={t('field.heat-exported')}>{t('description.heat-exported')}</HintedLabel>}
            required={true}
            rules={[{required: true, type: "number", min: 0}]}
          >
            <InputNumber suffix={"m³"} className={"input-number"}/>
          </Form.Item>

        </Col>
      </Row>
      <Form.Item
        name={"HeatEmissionFactor"}
        label={t('field.heat-emission-factor')}
        required={true}
        rules={[{required: true}]}
      >
        <EmissionFactorSelect query={{ActivityType: 'Combustion.Fuel'}}/>
      </Form.Item>
    </>}
    {indirectForms.includes("fuel") && <>
      <h3>{t('value.fuel')}</h3>
      <Row>
        <Col span={12}>
          <Form.Item
            name={"FuelGasImported"}
            label={
              <HintedLabel title={t('field.fuel-gas-imported')}>{t('description.fuel-gas-imported')}</HintedLabel>}
            required={true}
            rules={[{required: true, type: "number", min: 0}]}
          >
            <InputNumber suffix={"m³"} className={"input-number"}/>
          </Form.Item>

        </Col>
        <Col span={12}>
          <Form.Item
            name={"FuelGasExported"}
            label={
              <HintedLabel title={t('field.fuel-gas-exported')}>{t('description.fuel-gas-exported')}</HintedLabel>}
            required={true}
            rules={[{required: true, type: "number", min: 0}]}
          >
            <InputNumber suffix={"m³"} className={"input-number"}/>
          </Form.Item>

        </Col>
      </Row>
      <Form.Item
        name={"FuelGasEmissionFactor"}
        label={t('field.fuel-gas-emission-factor')}
        required={true}
        rules={[{required: true}]}
      >
        <EmissionFactorSelect query={{ActivityType: 'Combustion.Fuel'}}/>
      </Form.Item>
    </>}
    <Divider/>
    {CL_QualifyingParameters.options().filter(o => qualifyingParameters?.includes(o.value)).map(_ =>
      <div
        className={"qualifying-parameter"}
      ><Form.Item name={["ProdMethodQualifyingParams", _.value]} label={_.label}>
        {_.attrs?.type === "radio" && _.attrs.options && <Radio.Group>
          {_.attrs.options?.map((option: string) => <Radio value={option}>{option}</Radio>)}
        </Radio.Group>}
        {_.attrs?.type === "input-number" && <InputNumber suffix={_.attrs.suffix} className={'input-number'}/>}
      </Form.Item>
        <Alert type={'info'} message={CL_QualifyingParameterDescriptions.label(_.value)}/>
        <Divider/>
      </div>)}
  </Form>

}