import {Button, Input, Space} from "antd";
import {CheckCircleFilled, CopyOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import "./CopyInput.scss"

export interface CopyInputProps {
  value?: string
  className?: string
  disabled?: boolean
  onOpen?: () => void

}

export default ({value, onOpen, disabled}: CopyInputProps) => {
  const [state, setState] = useState<'copied' | 'idle'>('idle')
  const {t} = useTranslation()
  return <Space.Compact className={"copy-input"}>
    <Input value={value} contentEditable={false} disabled={disabled}/>
    {onOpen && <Button
      icon={<FolderOpenOutlined/>}
      onClick={() => onOpen?.()}
    >{t('button.open')}</Button>}
    <Button
      style={state === 'copied' ? {
        backgroundColor: 'var(--secondary-color)',
        color: 'white'
      } : undefined}
      disabled={disabled}
      icon={state === 'copied' ? <CheckCircleFilled/> : <CopyOutlined/>}
      onClick={async () => value && navigator.clipboard.writeText(value).then(() => setState('copied'))}
    >{state === 'copied' ? t('validation.copied') : t('button.copy')}</Button>
  </Space.Compact>
}