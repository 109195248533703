import { Form as AntdForm, FormProps as AntdFormProps, FormInstance, FormProps } from "antd";
import React, { ReactElement, ReactNode, useMemo } from "react";
import _, { MergeCustomizer } from "../../common/lodash";
import "./Stage.scss";

export type StageElementType<WizardDataType = any, StageDataType = any> = ReactElement<StageProps<WizardDataType, StageDataType>>
export type StageType<WizardDataType = any, StageDataType = any> = (props: StageProps<WizardDataType, StageDataType>) => ReactElement
export type StageElementTypeCompatible = StageElementType | undefined | false | null | number

export interface StageProps<WizardDataType, StageDataType = any> {
  value?: StageDataType
  root?: WizardDataType
  type?: 'collection' | 'object' | 'placeholder'
  save?: () => void
  manualSave?: boolean
  onAdd?: (index: number) => void
  onDelete?: (index: number) => void
  onReturn?: (value?: StageDataType) => void
  disabled?: boolean
  onFormValuesChange?: AntdFormProps['onValuesChange']
  formRef?: React.RefObject<FormInstance>
  name: string
  dot?: ReactNode
  index?: number
  heading?: ReactNode | ((value?: StageDataType, index?: number) => ReactNode)
}

export function useStageForm<WizardDataType, StageDataType>(props: StageProps<WizardDataType, StageDataType>) {
  const {disabled, formRef, value, onFormValuesChange} = props
  const ret = (props: FormProps) => <AntdForm
    {...props}
    children={props.children as ReactNode[] | ReactNode}
    disabled={props.disabled === undefined ? disabled : props.disabled}
    className={"stage" + (props.className ? " " + props.className : "")}
    ref={formRef}
    initialValues={_.mergeWith(value, props.initialValues, MergeCustomizer)}
    onValuesChange={(cv, v) => {
      props.onValuesChange?.(cv, v)
      onFormValuesChange?.(cv, v)
    }}
  />
  // Set ret.Item with AntdForm.Item
  ret.Item = AntdForm.Item
  return useMemo(()=> ret, [disabled, formRef, value, onFormValuesChange])
}
