import {useTranslation} from "react-i18next";
import Transaction from "../common/Transaction"
import {Button, Space, Table} from "antd"
import "./TransactionPage.scss"
import {SalesIQ} from "../component/SalesIQ";
import CallForAction from "../app.wegreenpass/component/CallForAction";
import FlagImage from "../component/FlagImage";
import Entity from "../common/Entity";
import Product from "../common/Product";
import _ from "../common/lodash"
import {GET, useApis} from "../api";
import Loading from "../component/Loading";
import CodeListContext from "../context/CodeListContext";
import { useContext } from "react";

export default () => {
  const {t} = useTranslation()
  const {state, transactions} = useApis({
    transactions: () => GET<Transaction[]>('/transactions/my')
  })
  const {WC_Payments} = useContext(CodeListContext)
  if (state === 'loading') return <Loading/>
  if (state === 'failed') return <></>
  return <div className={'payment-page'}>
    <Table dataSource={transactions} columns={_.toAntdColumns({
      [t('field.entity')]: (record) => (
          <Space direction={'vertical'}>
          <Space>
            <FlagImage iso3166={(record.Entity as Entity).region}/><span>{(record.Entity as Entity).name.native}</span></Space>
          <span>{(record.Entity as Entity).name.en}</span>
          </Space>
      ),
      [t('field.product')]: (record) => t("product." + (record.Product as Product).name),
      [t('field.channel')]: o => WC_Payments.label(o.Channel),
      [t('field.paid_amount')]: (record) => (
        <Space>
          <span>{record.Amount.toFixed(2)}</span>
          <span>{record.Currency}</span>
        </Space>
      ),
    })} className={"table"}/>
    <CallForAction
      type={"dark"}
      closable={false}
      backgroundImage={"/images/photo/alexander-mils-lCPhGxs7pww-unsplash.jpg"}
    >
      <h3>{t("title.payment-questions")}</h3>
      <p>{t("description.payment-questions")}</p>
      <Button type={"primary"} onClick={SalesIQ.startChat}>{t("button.consult-now")}</Button>
    </CallForAction>
  </div>
}