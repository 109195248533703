import { CheckCircleFilled, LoadingOutlined, TranslationOutlined } from "@ant-design/icons";
import { Button, Input, message, Space } from "antd";
import { useTranslation } from "react-i18next";
import { I18nText } from "../common/I18nText";
import IFormItemProps from "../form/IFormItemProps";
import "./TranslateInput.scss";

import DisabledContext from "antd/es/config-provider/DisabledContext";
import { useContext, useState } from "react";
import { POST } from "../api";
import _, { ToCaseType } from "../common/lodash";

export interface TranslateInputProps extends IFormItemProps<I18nText> {
  value?: I18nText
  onChange?: (value?: I18nText) => void
  disabled?: boolean
  capitalize?: ToCaseType
}

export default ({value, onChange, disabled, capitalize}: TranslateInputProps) => {
  const [translateStatus, setTranslateStatus] = useState<'disabled' | 'enabled' | 'loading' | 'done'>(value?.native ? 'enabled' : 'disabled')
  const {t} = useTranslation()
  const contextualDisabled = useContext(DisabledContext) || disabled
  const onTranslate = async () => {
    if (!value?.native) return
    setTranslateStatus('loading')
    let {text: en, detectedSourceLanguage: nativeLanguage} = await POST('/google/translate', {text: value.native}).catch(e => ({} as any))
    if (!en || !nativeLanguage) {
      setTranslateStatus('enabled')
      return message.error(t('validation.unknown-error'))
    }
    await onChange?.({
      native: value.native,
      nativeLanguage,
      en: _.toCase(en, capitalize)
    })
    setTranslateStatus('done')
  }
  return <div className={"translate-input"}>
    <Space.Compact>
      <Input
        placeholder={t("field.local-language")}
        className={"input first"}
        disabled={contextualDisabled}
        onChange={e => {
          onChange?.({
            native: e.target.value,
            en: value?.en || ""
          })
          // If the target value is not empty, and the translateStatus
          if (
            e.target.value && (translateStatus === 'disabled' || translateStatus === 'done')
          ) setTranslateStatus('enabled')
          else if (!e.target.value && translateStatus === 'enabled') setTranslateStatus('disabled')
        }}
        value={value?.native}
        onBlur={contextualDisabled ? undefined : onTranslate}
        readOnly={contextualDisabled}
      />
      {!contextualDisabled && <Button
        className={"button first"}
        onClick={onTranslate}
        disabled={translateStatus !== 'enabled' || contextualDisabled}
        icon={
          translateStatus === 'loading' ? <LoadingOutlined/> :
            translateStatus === 'done' ? <CheckCircleFilled/> : <TranslationOutlined/>
        }
      >{t("button.translate")}</Button>}
    </Space.Compact>
    <Input
      placeholder={"English"}
      className={"input second"}
      disabled={contextualDisabled}
      value={value?.en}
      onChange={e => {
        onChange?.({
          native: value?.native || "",
          en: e.target.value
        })
      }}
      readOnly={contextualDisabled}
    />
  </div>

}