import { Space } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { GET, useApis } from "../../api";
import Unlocode from "../../common/Unlocode";
import _ from "../../common/lodash";
import FlagImage from "../../component/FlagImage";
import CodeListContext from "../../context/CodeListContext";
import IFormItemProps from "../../form/IFormItemProps";
import { ObjectInputSelect, ObjectSelectProps } from "./ObjectSelect";
import "./UnlocodeSelect.scss";

export interface UnlocodeSelectProps
  extends IFormItemProps<Unlocode>,
    Partial<ObjectSelectProps<Unlocode>> {
  region?: string;
}

export default (props: UnlocodeSelectProps) => {
  const { region } = props;
  const { state, unlocodes } = useApis(
    {
      unlocodes: () => GET<Unlocode[]>("/unlocodes", { region }),
      version: () => GET<number>("/unlocodes/version"),
    },
    [region]
  );
  const { t, i18n } = useTranslation();
  const { WC_Regions } = useContext(CodeListContext);
  return (
    <ObjectInputSelect<Unlocode>
      {...props}
      className={"unlocode-select"}
      selectDialogHeader={t("imperative.please-follow-unlocode")}
      name={t("field.locality")}
      data={(search?: string) =>
        GET<Unlocode[]>("/unlocodes", { region, search }).then(
          (o) => o.unlocodes
        )
      }
      renderItem={(o) =>
        o?.Name && (
          <Space>
            <FlagImage iso3166={o.Region} />
            {o.Name[i18n.language] || o.Name.en}
            <span className="unlocode">
              {o.Region} {o.Location}
            </span>
          </Space>
        )
      }
      columns={_.toAntdColumns({
        [t("field.region")]: (o) => (
          <Space>
            <FlagImage iso3166={o.Region} />
            {WC_Regions.label(o.Region)}
          </Space>
        ),
        [t("field.unlocode")]: (o) => `${o.Region} ${o.Location}`,
        [t("field.name")]: (o) => o.Name[i18n.language] || o.Name.en,
        [t("field.subdivision")]: (o) =>
          o.Subdivision?.[i18n.language] || o.Subdivision?.en,
      })}
    />
  );
};
