import {Button, Flex} from "antd";
import "./HomePage.scss"
import AppIcon from "../component/AppIcon";
import {useTranslation} from "react-i18next";
import {SalesIQ} from "../component/SalesIQ";
import _ from "../common/lodash";

export default () => {
  const {t, i18n} = useTranslation()
  return <div className={"home-page"}>
    <div className={'tile focus'}>
      <h1>{t('title.welcome-to-wecarbon')}</h1>
      <h3>{t('title.available-apps')}</h3>
      <Flex className={"flex"} gap={"16px"} wrap={"wrap"}>
        <AppIcon
          icon={<img src={"/images/wegreenpass-logo.png"} alt={"WeGreenPass Logo"}/>}
          name={t('product.wegreenpass')}
          description={t('description.wegreenpass')}
          onClick={"/apps/wegreenpass"}
          status={'available'}
        />
      </Flex>
    </div>
    <main>
      <div className={'tile all'}>
        <h3>{t('title.all-apps')}</h3>
        <div className={'category'}>{t('title.disclosure-and-compliance')}</div>
        <Flex className={"flex"} gap={"16px"} wrap={"wrap"}>
          <AppIcon
            icon={<img src={"/images/wegreenpass-logo.png"}/>}
            name={t('product.wegreenpass')}
            description={t('description.wegreenpass')}
            onClick={"/apps/wegreenpass"}
            status={'available'}
          />
          <AppIcon
            icon={<img src={"/images/report-logo.png"} alt={"Report Logo"}/>}
            name={t('product.iso14064')}
            description={t('description.iso14064')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}
          />
          <AppIcon
            icon={<img src={"/images/report-logo.png"} alt={"Report Logo"}/>}
            name={t('product.iso14067')}
            description={t('description.iso14067')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}
          />
          <AppIcon
            icon={<img src={"/images/report-logo.png"} alt={"Report Logo"}/>}
            name={t('product.gbt24915')}
            description={t('description.gbt24915')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}
          />
        </Flex>
        <div className={'category'}>{t('title.carbon-services')}</div>
        <Flex className={"flex"} gap={"16px"} wrap={"wrap"}>
          <AppIcon
            icon={<img src={"/images/green-finance-logo.png"} alt={"Green Finance Logo"}/>}
            name={t('product.green-finance')}
            description={t('description.green-finance')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}

          />
          <AppIcon
            icon={<img src={"/images/module-logo.png"} alt={"Module Logo"}/>}
            name={t('product.carbon-management-platform')}
            description={t('description.carbon-management-platform')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}

          />
        </Flex>
        <div className={'category'}>{t('title.esg-services')}</div>

        <Flex className={"flex"} gap={"16px"} wrap={"wrap"}>
          <AppIcon
            icon={<img src={"/images/green-report-logo.png"} alt={"Green Report Logo"}/>}
            name={t('product.esg-disclosure')}
            description={t('description.esg-disclosure')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}

          />
          <AppIcon
            icon={<img src={"/images/ai-logo.png"} alt={"Module Logo"}/>}
            name={t('product.ai-reporting')}
            description={t('description.ai-reporting')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}

          />
          <AppIcon
            icon={<img src={"/images/module-logo.png"} alt={"Module Logo"}/>}
            name={t('product.esg-data-management')}
            description={t('description.esg-data-management')}
            status={'unavailable'}
            onClick={SalesIQ.startChat}
          />
        </Flex>

      </div>
      {i18n.language === 'zh-CN' && <div className={'tile news'}>
        <h3>{t('title.global-news')}</h3>
        <iframe src={"https://we-carbon.com/news-embedded-simple"}/>
        <Button target={'_blank'} href={"https://we-carbon.com/news/"}>{t('button.more-news')}</Button>

      </div>}

    </main>

  </div>
}