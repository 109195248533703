import {FC, useCallback, useContext, useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import IFormItemProps from "../form/IFormItemProps";
import {Form, FormInstance, Input, InputNumber, Modal, Select, Space, Tag} from "antd";
import {LinkOutlined, StarOutlined} from "@ant-design/icons";

import EmissionFactor from "../common/EmissionFactor";
import TranslateInput from "./TranslateInput";
import RegionSelect from "./RegionSelect";
import FlagImage from "./FlagImage";
import "./EmissionFactorSelect.scss"
import HintedLabel from "./HintedLabel";
import {ObjectInputSelect, ObjectSelectProps} from "../app.wegreenpass/component/ObjectSelect";
import dayjs from 'dayjs'
import CodeListContext from "../context/CodeListContext";
import _ from "../common/lodash"
import {GET} from "../api";

export interface EmissionFactorSelectProps extends IFormItemProps<EmissionFactor>, Partial<ObjectSelectProps<EmissionFactor>> {
  query?: Partial<EmissionFactor> & { "CN:Category"?: string, "HighlightSubject"?: string }
}

const EmissionFactorSelect: FC<EmissionFactorSelectProps> = (props) => {
  const {query, value, onChange} = props
  const [dialog, setDialog] = useState<'edit' | null>(null)
  const ref = useRef<FormInstance<EmissionFactor>>(null)
  const onFinishEdit = useCallback(async () => {
    await ref.current?.validateFields()
    await onChange?.({...ref.current?.getFieldsValue()} as any)
    setDialog(null)
  }, [ref, onChange])
  const {t, i18n} = useTranslation()
  const {WC_Regions, CL_EmissionFactorSources} = useContext(CodeListContext)
  return <>
    <ObjectInputSelect<EmissionFactor>
      {...props}
      name={t('field.emission-factor')}
      renderItem={(o) => <Space direction={'vertical'} align={'start'}>
        <Space size={20} wrap={true}>
          <span>{o?.Value.toFixed(4)} {o?.Unit}</span>
          {o?.SubjectType === 'Region' && o.Subject ?
            <Space><FlagImage iso3166={o.Subject}/>{o.Label}</Space> : o?.Label}
        </Space>

        {o?.Source && <Space>
            <a target={"_blank"} href={o.Source.Url}>
                <Space><LinkOutlined/>{o.Source.Labels[i18n.language]}{dayjs(o.Date).format('YYYY-MM-DD')}
                </Space>
            </a></Space>}
      </Space>}
      renderTitle={s => <HintedLabel title={s}>
        <Trans i18nKey={'description.emission-factor-reference-link'}>
          <a href={"https://taxation-customs.ec.europa.eu/system/files/2023-12/Guidance%20document%20on%20CBAM%20implementation%20for%20installation%20operators%20outside%20the%20EU.pdf"}/>
        </Trans>
      </HintedLabel>}
      data={async (search?: string) => (await GET<EmissionFactor[]>('/databases/emissionfactors', {...query, Search: search}))?.values}
      className={'emission-factor-select'}
      rowClassName={o => o.Highlighted ? "highlight" : ""}
      onEdit={() => setDialog('edit')}
      columns={_.toAntdColumns({
        [t('field.emission-factor')]: o =>
          <div className={'emission-factor'}>
            <p>{o.Value.toFixed(4)}</p>
            <p className={'unit'}>{o.Unit}</p>
          </div>,
        [t('field.emission-factor-subject')]: o => <Space wrap={true}>
          {o.SubjectType === 'Region' && o.Subject &&
              <FlagImage iso3166={o.Subject}/>}
          {o.Label}
          {o.Highlighted &&
              <Tag color={'yellow'}><Space><StarOutlined/>{t('value.recommended')}</Space></Tag>}
        </Space>,
        [t('field.emission-factor-source')]: o =>
          // Make <a> open in new tab
          <a
            target={"_blank"} href={o.Source.Url}
          >
            <Space wrap={true}><LinkOutlined/>{o.Source.Labels[i18n.language]}{dayjs(o.Date).format('YYYY-MM-DD')}
            </Space>
          </a>,
      })}
    />
    <Modal
      open={dialog === 'edit'}
      title={<Space>{t('title.edit-emission-factor')}</Space>}
      onOk={onFinishEdit}
      onCancel={() => setDialog(null)}
      destroyOnClose
    >
      {dialog === 'edit' && <Form initialValues={value} ref={ref}>
          <Form.Item label={t('field.emission-factor')}>
              <Space.Compact>
                  <Form.Item
                      name={"Value"}
                      required={true}
                      rules={[{required: true}]}
                      noStyle
                  >
                      <InputNumber/>
                  </Form.Item>
                  <Form.Item
                      name={"Unit"}
                      required={true}
                      rules={[{required: true}]}
                      noStyle
                  >
                      <Input autoComplete={'off'}/>
                  </Form.Item>
              </Space.Compact>
          </Form.Item>

          <Form.Item
              name={["Source", "Labels"]}
              label={t('field.emission-source-name')}
              required={true}
              rules={[{required: true}]}
          >
              <TranslateInput capitalize={'all'}/>
          </Form.Item>
          <Form.Item
              name={["Source", "Region"]}
              label={t('field.region')}
              required={true}
              rules={[{required: true}]}
          >
              <RegionSelect options={WC_Regions.options()}/>
          </Form.Item>
          <Form.Item
              name={["Source", "CbamSourceType"]}
              label={t('field.emission-source-method')}
              required={true}
              rules={[{required: true}]}
          >
              <Select options={CL_EmissionFactorSources.options()}/>
          </Form.Item>
          <Form.Item
              name={["Source", "Url"]}
              label={t('field.reference-link')}
              rules={[{required: true, pattern: /^https?:\/\/.*$/g}]}
          >
              <Input autoComplete={'off'}/>
          </Form.Item>

      </Form>}

    </Modal>
  </>

}

export default EmissionFactorSelect