import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";

export default (name?: string) => {
  const {i18n} = useTranslation()
  const [html, setHtml] = useState<string>()

  useEffect(() => {
    (async () => {
      if (!name) return
      try {
        const response = await fetch(`/static/${name}/${i18n.language}.html`)
        setHtml(await response.text())
      } catch (e) {
        const response = await fetch(`/static/${name}/en.html`)
        setHtml(await response.text())
      }
    })()
  }, [name, i18n.language])

  return html
}