import "./Diptych.scss"
import {CheckOutlined} from "@ant-design/icons";

export interface IDiptychItem {
  title?: string
  description?: string
}

export interface DiptychProps {
  front?: IDiptychItem
  back?: IDiptychItem
}

export default ({front, back}: DiptychProps) => {
  return <div className={'diptych'}>
    <div className={'back item'}>
      <div className={'title'}>{back?.title}</div>
      <div className={'description'}>{back?.description}</div>
    </div>
    <div className={'front item'}>
      <CheckOutlined className={'icon'}/>
      <div className={'title'}>{front?.title}</div>
      <div className={'description'}>{front?.description}</div>
    </div>
  </div>

}