import {StageProps, useStageForm} from "../Stage";
import {Installation} from "../../../common/Installation";
import {useTranslation} from "react-i18next";
import {Alert, Button, FormInstance, InputNumber, Select, Space} from "antd";
import HintedLabel from "../../../component/HintedLabel";
import TranslateInput from "../../../component/TranslateInput";
import {ObjectInputSelect} from "../../component/ObjectSelect";
import {PhoneOutlined} from "@ant-design/icons";
import {SalesIQ} from "../../../component/SalesIQ";
import React, {useContext, useRef, useState} from "react";
import FlagImage from "../../../component/FlagImage";
import TabularForms from "../../component/TabularForms";

import CarbonPriceDue from "../../model/CarbonPriceDue";
import CodeListContext from "../../../context/CodeListContext";
import _ from "../../../common/lodash";
import {POST} from "../../../api";

export interface GoodStageState {
  euro: number
  currencyDate: string
}

export default (props: StageProps<Installation>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  const ref = useRef<FormInstance<CarbonPriceDue>>(null)
  const {root} = props
  const {CL_HarmonizedCodes, CL_HarmonizedCodeCategories, CL_ProductionRoutes, WC_CarbonInstruments, CL_Currencies} = useContext(CodeListContext)
  const [state, setState] = useState<GoodStageState>({
    euro: 0,
    currencyDate: ""
  })

  async function onDialogValuesChange(index?: number, changedValues?: any, values?: any) {
    if (
      ('LocalCurrencyAmount' in changedValues || 'LocalCurrency' in changedValues) &&
      values.LocalCurrencyAmount &&
      values.LocalCurrency
    ) {
      const response = await POST<number | string | undefined>('/conversions/currency', {currency: values.LocalCurrency, value: values.LocalCurrencyAmount})
      if (response) setState({currencyDate: response?.date as string, euro: state.euro})
      ref.current?.setFieldsValue({EuroAmount: response?.value as number})

    }
  }


  return <Form>
    <p>{t('imperative.good-declaration-introduction')}</p>
    <Form.Item
    name={"Description"}
    label={
      <HintedLabel title={t("field.name")}>{t('description.name-match-other-declaration-processes')}</HintedLabel>}
    required={true}
    rules={[{required: true}]}
  >
    <TranslateInput capitalize={'first'}/>
  </Form.Item>
    <Form.Item
      name={"CategoryCode"}
      label={t("field.category-code")}
      required={true}
      rules={[{required: true}]}
    >
      <ObjectInputSelect
        name={t("field.category-code")}
        renderItem={(item) => <Space>{item?.key}{CL_HarmonizedCodes.label(item?.key)}</Space>}
        data={(search?: string) => CL_HarmonizedCodes.options().filter(_ =>
          search === undefined ||
          CL_HarmonizedCodeCategories.label(_.attrs?.category).includes(search) ||
          _.value.includes(search) ||
          Object.values(_.labels).join(' ').includes(search)
        )}
        columns={_.toAntdColumns({
          [t('field.code')]: 'value',
          [t('field.category')]: o => CL_HarmonizedCodeCategories.label(o.attrs.category),
          [t('field.description')]: 'label'
        })}
        empty={<Alert
          message={<Space>{t("validation.category-not-found")}<Button
            icon={<PhoneOutlined/>}
            type={'primary'}
            onClick={SalesIQ.startChat}
          >{t('button.consult-now')}</Button></Space>} type={"warning"}
        />}
      />
    </Form.Item>
    <Form.Item name={"AssociatedProcess"} label={t('field.associated-process')} required={true} rules={[{required: true}]}>
      <ObjectInputSelect<Installation.Process>
        name={t('field.associated-process')}
        renderItem={o => o?.Description?.native || o?.Description?.en || o?.Name}
        data={root?.processes}
        columns={_.toAntdColumns({
          [t("field.name")]: o => o?.Description?.native || o?.Description?.en || o?.Name,
          [t('field.category')]: o => CL_ProductionRoutes.label(o?.Name),
        })}
      />
    </Form.Item>
    <Form.Item name={'Dues'}>
      <TabularForms
        name={t('field.carbon-asset')}
        onDialogValuesChange={onDialogValuesChange}
        formRef={ref}
        columns={_.toAntdColumns({
          [t('field.carbon-asset')]: _ => <Space>
            <FlagImage iso3166={_.InstrumentType.attrs?.region}/>
            {_.InstrumentType.label}
          </Space>,
          [t('field.carbon-offset')]: _ => _.EmissionAmount?.toFixed(2) + " tCO2e",
          [t('field.offset-amount-euro')]: _ => _.EuroAmount?.toFixed(2)
        })}
      >
        <Form.Item
          name={"InstrumentType"}
          label={t("field.carbon-asset")}
          required={true}
          rules={[{required: true}]}
        >
          <ObjectInputSelect
            name={t("field.carbon-asset")}
            renderItem={instrument => instrument && <Space>
                <FlagImage iso3166={instrument?.attrs?.region}/>
              {instrument?.label}
            </Space>}
            data={WC_CarbonInstruments.options()}
            columns={_.toAntdColumns({
              [t("field.carbon-asset")]: (o) =>
                <Space><FlagImage iso3166={o.attrs?.region}/>{o.label}</Space>
            })}
          />
        </Form.Item>
        <Form.Item
          name={"EmissionAmount"}
          label={t("field.carbon-offset")}
          required={true}
          rules={[{required: true}, {type: "number", min: 0, message: t("validation.wrong-number")}]}
        >
          <InputNumber suffix={t('value.tco2e')} className={"input-number"}/>
        </Form.Item>
        <Form.Item label={t("field.offset-amount-local")} required={true}>
          <Space.Compact className={"input-number"}>
            <Form.Item
              name={"LocalCurrencyAmount"}
              required={true}
              rules={[{required: true}, {type: "number", min: 0, message: t("validation.wrong-number")}]}
              noStyle
            >
              <InputNumber placeholder={t("field.amount-of-money")}/>
            </Form.Item>
            <Form.Item name={"LocalCurrency"} noStyle required={true}>
              <Select
                options={CL_Currencies.options()}
                placeholder={t("field.type-of-currency")}
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item
          label={t("field.offset-amount-euro")}
          name={"EuroAmount"}
          required={true}
          rules={[{required: true}, {type: "number", min: 0, message: t('validation.wrong-number')}]}
        >
          <InputNumber suffix={t('value.euro')} className={"input-number"} precision={2}/>
        </Form.Item>
        {state.currencyDate && <p className={'description'}>
          {t('description.currency-rate-from-ecb', {date: state.currencyDate})}</p>}
      </TabularForms>
    </Form.Item>
  </Form>
}