import http from "http-status-codes";

export default interface IError {
  success: false
  httpCode: number
  statusCode: number
  message: string
  data?: any
}

export class ApiError extends Error implements IError {
  success: false = false
  httpCode: number
  statusCode: number
  message: string
  data?: any
  constructor(error: Pick<IError, "httpCode" | "statusCode" | "message"> & Partial<IError>) {
    super(error.message)
    this.httpCode = error.httpCode
    this.statusCode = error.statusCode
    this.message = error.message
    this.data = error.data
  }
  toObject(): IError {
    return {
      success: this.success,
      httpCode: this.httpCode,
      statusCode: this.statusCode,
      message: this.message,
      data: this.data
    }
  }
  with(d: any): ApiError {
    // Return a new object
    return new ApiError({
      ...this.toObject(),
      data: d
    })
  }
  static of(message?: string){
    return new ApiError({
      success: false,
      httpCode: http.BAD_REQUEST,
      statusCode: 40000,
      message: message || "STR_CLIENT_ERROR"
    })
  }
}