import _ from "../common/lodash";
import i18n from "i18next";
import Base64 from "base64-js";
import pako from "pako";
import {CodeList} from "../common/CodeList";
import CodeListNamesData from "../data/CodeListNamesData.json"
import React from "react";
import {POST} from "../api";
import {ApiCredentialType} from "../api";

export type CodeListContextData = { [key: typeof CodeListNamesData[number]]: CodeList }

export interface ICodeListContext extends React.Context<CodeListContextData> {
  initialize: (lng?: string) => Promise<CodeListContextData>
}

const CodeListContext = React.createContext<CodeListContextData>({})
Object.defineProperty(CodeListContext, "initialize", {
  value: async (lng?: string) => {
    const language = _.toLocale(lng || i18n.language)
    // Generate key pair
    const {privateKey, publicKey} = await crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
      },
      true,
      ["decrypt", "encrypt"]
    )
    const jwk = await crypto.subtle.exportKey('jwk', publicKey)
    const {data, key} = await POST(`/codelists/init/${language}`, {jwk}, ApiCredentialType.Ignored)
    if (!data || !key) return
    const decryptedSymmetricKey = await crypto.subtle.decrypt(
      {
        name: "RSA-OAEP"
      },
      privateKey,
      Base64.toByteArray(key)
    )
    const symmetricKey = await crypto.subtle.importKey('raw', decryptedSymmetricKey, {
      name: 'AES-GCM',
      length: 128
    }, false, ['decrypt'])
    const decryptedData = await crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: new Uint8Array(12),
      },
      symmetricKey,
      Base64.toByteArray(data)
    )
    const codeLists = JSON.parse(pako.inflate(decryptedData, {to: 'string'}));
    return _(codeLists).map(o => new CodeList(o)).keyBy('name').value()
  }
})

export default CodeListContext as ICodeListContext