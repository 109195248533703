import React from "react";
import {Form, InputNumber} from "antd";
import {useTranslation} from "react-i18next";
import {Installation} from "../../../common/Installation";
import {StageProps, useStageForm} from "../Stage";
import EmissionFactorSelect from "../../../component/EmissionFactorSelect";

export default (props: StageProps<Installation, Installation.EmissionSource>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  const {formRef} = props
  return <Form
    className={"emission-source-form"}
    onValuesChange={(cv, v) => {
      if (!('EmissionFactor' in cv)) return
      if (cv.EmissionFactor === undefined) formRef?.current?.setFieldsValue({
        OxidationFactor: undefined,
        CalorificValue: undefined,
        BiomassContent: undefined,
        ConversionFactor: undefined
      })
      else if (cv.EmissionFactor.Attrs) formRef?.current?.setFieldsValue({
        OxidationFactor: cv.EmissionFactor.Attrs.Oxidation,
        CalorificValue: cv.EmissionFactor.Attrs.CalorificValue,
        BiomassContent: cv.EmissionFactor.Attrs.BiomassContent,
        ConversionFactor: cv.EmissionFactor.Attrs.ConversionFactor
      })
    }}
  >
    <p>{t('imperative.emission-source-installation-introduction')}</p>
    <Form.Item
      name={"EmissionFactor"}
      required={true}
      rules={[{required: true}]}
      label={t('field.fuel-and-emission-factor')}
    >
      <EmissionFactorSelect query={{ActivityType: 'Combustion.Fuel'}}/>
    </Form.Item>
    <Form.Item name={"ActivityData"} required={true} rules={[{required: true}]} label={t('field.activity-data')}>
      <InputNumber suffix={"t"} className={"input-number"}/>
    </Form.Item>
    <Form.Item
      name={"CalorificValue"}
      required={true}
      rules={[{required: true}]}
      label={t('field.net-calorific-value')}
    >
      <InputNumber suffix={"MJ/kg"} className={"input-number"}/>
    </Form.Item>
    <Form.Item
      name={"OxidationFactor"}
      required={true}
      rules={[{required: true}]}
      label={t('field.oxidation-factor')}
    >
      <InputNumber suffix={"%"} className={"input-number"}/>
    </Form.Item>
    <Form.Item
      name={"BiomassContent"}
      required={true}
      rules={[{required: true}]}
      label={t('field.biomass-content')}
    >
      <InputNumber suffix={"%"} className={"input-number"}/>
    </Form.Item>
  </Form>


}