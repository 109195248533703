import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {ConfigProvider} from "antd";
import LoginPage from "./page/LoginPage";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./page/HomePage";
import PageContainer from "./component/PageContainer";
import IndexRedirectPage from "./page/IndexRedirectPage";
import WaitListPage from "./page/WaitListPage";
import AdminPage from "./page/AdminPage";
import PaymentPage from "./page/TransactionPage";
import ProfileForm from "./form/ProfileForm";
import Loading from "./component/Loading";
import AdminUserPage from "./page/AdminUserPage";
import {initReactI18next, useTranslation} from "react-i18next";
import {App} from "./App";
import CodeListContext, {CodeListContextData} from "./context/CodeListContext";
import _ from "./common/lodash"
import AccountContext, {AccountContextData} from "./context/AccountContext";
import i18next from "i18next";
import Backend from "i18next-http-backend";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Languages from "./languages.json";
import PromotionPage from './page/PromotionPage';
import NotFoundPage from "./app.wegreenpass/page/NotFoundPage";
import {ApiCredentialType, GET, useApis} from "./api";
import Account from './common/Account';
import VerifyEmailPage from './page/VerifyEmailPage';
import Apps from "./Apps"
import EnvUtil from "./util/EnvUtil";

export interface IndexProps {
  children: ReactNode[] | ReactNode
  apps?: App[]
}


const Index = ({children, apps}: IndexProps) => {
  const [codeListContext, setCodeListContext] = useState<CodeListContextData>()
  const [codeListContextReady, setCodeListContextReady] = useState(false)
  const {i18n, t} = useTranslation()
  const {account, reload, state} = useApis({
    account: () => GET<Account>('/accounts/me', undefined, ApiCredentialType.Optional)
  }, [window.location.pathname])
  const accountContext = useMemo(() => new AccountContextData(account), [account])
  useEffect(() => {
    (async () => {
      const codeListContext = await CodeListContext.initialize(i18n.language)
      setCodeListContext(codeListContext)
      setCodeListContextReady(true)
    })()
  }, [i18n.language])
  if (!codeListContextReady || state === 'loading' || !codeListContext) return <Loading fullscreen/>
  return <CodeListContext.Provider value={codeListContext}><AccountContext.Provider value={accountContext}>
    <React.StrictMode>
      <ConfigProvider
        theme={{
          "token": {
            "colorPrimary": "#2C528F",
            "colorLink": "#2C528F",
          },
          components: {
            Tour: {
              paddingXS: 20
            },
            Form: {}
          }
        }}
        form={{
          validateMessages: {
            required: t('validation.required')
          }
        }}
        locale={_.toLocale(i18n.language, "antd")}
      >
        <BrowserRouter>
          <Routes>
            {children}
            {apps?.flatMap(o => o.renderRoutes())}
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </React.StrictMode></AccountContext.Provider></CodeListContext.Provider>
}

async function main() {
  const {env, jury} = EnvUtil
  i18next.on("initialized", () => {
    if (document) document.documentElement.lang = i18next.language
  })
  i18next.on("languageChanged", (language) => {
    if (document) document.documentElement.lang = language
  })
  await i18next
    .use(Backend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: jury === 'cn' ? 'zh-CN' : 'en',
      debug: false,
      detection: {
        order: ['querystring', 'cookie'],
        lookupQuerystring: 'lang',
        lookupCookie: 'i18next',
        caches: ['cookie']
      },
      supportedLngs: Languages.map(o => o.code),
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: env === 'coder' ? {
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      } : {}
    })
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(<Index
      apps={Apps.all}
    >
      <Route path={"/"} key={'root'} element={<IndexRedirectPage/>}/>
      <Route
        path={"/home"}
        key={'home'}
        element={<PageContainer i18nTitleKey={'title.wecarbon-cloud'}><HomePage/></PageContainer>}
      />
      <Route path={"/login"} key={'login'} element={<LoginPage/>}/>
      <Route
        path={"/admin"}
        key={'admin'}
        element={<PageContainer i18nTitleKey={'title.admin'}><AdminPage/></PageContainer>}
      />
      <Route
        path={"/accounts/:id/admin"}
        key={'account-admin'}
        element={<PageContainer i18nTitleKey={'title.admin'}><AdminUserPage/></PageContainer>}
      />
      <Route
        path={"/accounts/:id/verify-email"}
        key={'verify-email'}
        element={<PageContainer i18nTitleKey={'title.verify-email'}><VerifyEmailPage/></PageContainer>}
      />
      <Route
        path={"/promotions"}
        key={"promotions"}
        element={<PageContainer i18nTitleKey={'title.promotions'}><PromotionPage/></PageContainer>}
      />
      <Route
        path={"/waitlist"}
        key={'waitlist'}
        element={<PageContainer i18nTitleKey={'title.wait-list'}><WaitListPage/></PageContainer>}
      />
      <Route
        path={"/payment"}
        key={'payment'}
        element={<PageContainer i18nTitleKey={'title.payment'}><PaymentPage/></PageContainer>}
      />
      <Route
        path={"/profile"}
        key={'profile'}
        element={<PageContainer i18nTitleKey={'title.profile'}><ProfileForm/></PageContainer>}
      ></Route>
      <Route
        path={"*"}
        key={'not-found'}
        element={<PageContainer i18nTitleKey={'title.not-found'}> <NotFoundPage type={'page'}/></PageContainer>}
      />

    </Index>
  );

}

main().then(null);
reportWebVitals();
