import React, {useEffect, useRef, useState} from "react";
import {Button, Input, message, Space} from "antd";
import IFormItemProps from "../form/IFormItemProps";
import {useTranslation} from "react-i18next";
import Success from "../common/success/Success";
import {OtpError} from "../common/error";
import {POST} from "../api";
import {ApiCredentialType} from "../api";

export interface OtpInputProps extends IFormItemProps<string> {
  username?: string,
  initialSeconds?: number
  onPressEnter?: React.KeyboardEventHandler<HTMLInputElement>
}

export default ({username, onPressEnter, value, onChange, initialSeconds}: OtpInputProps) => {
  const [retrySeconds, setRetrySeconds] = useState<number>(initialSeconds || 0)
  const {t} = useTranslation()
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    (async () => {
      await onResetTimer(true)
    })()
    return () => clearInterval(timer.current || undefined)
  }, [])

  async function onResetTimer(initial?: boolean) {
    if (!username) return
    if (!initial || initialSeconds === undefined) {
      const {status} = await POST('/mfa/otp', {phone: username}, ApiCredentialType.Ignored)
      if (status.statusCode === OtpError.statusCode) return message.error(t('validation.otp-too-frequent'));
      if (status.statusCode !== Success.statusCode) return message.error(t('validation.otp-send-error'));
      setRetrySeconds(60)
    }
    clearInterval(timer.current || undefined)
    timer.current = setInterval(() => {
      setRetrySeconds(prevRetrySeconds => {
        if (prevRetrySeconds <= 0) {
          clearInterval(timer.current || undefined)
          return 0
        }
        return prevRetrySeconds - 1
      })
    }, 1000)
  }

  return <Space.Compact>
    <Input
      placeholder={t('value.otp')}
      className={"username"}
      value={value}
      onChange={e => onChange?.(e.target.value)}
      onPressEnter={onPressEnter}
    />
    <Button disabled={retrySeconds > 0} onClick={() => onResetTimer()}>{
      retrySeconds > 0 ?
        t('button.resend-otp-within-seconds', {seconds: retrySeconds}) :
        t('button.send-otp')}</Button>
  </Space.Compact>

}