import React, {useEffect, useRef, useState} from "react";
import {Button, Form, FormInstance, Input, message, Modal, Radio, Row, Space} from "antd";
import FlagImage from "../../component/FlagImage";
import {ArrowRightOutlined, CreditCardOutlined, WechatFilled} from "@ant-design/icons";
import Entity from "../../common/Entity";
import "./PurchaseDialog.scss"
import PurchaseSelect from "../component/PurchaseSelect";
import {useTranslation} from "react-i18next";
import WeChatQrCode from "../component/WeChatQrCode";
import Product from "../../common/Product";
import StripeCheckout from "../component/StripeCheckout";
import {SalesIQ} from "../../component/SalesIQ";
import Promotion from "../../common/Promotion";
import HintedLabel from "../../component/HintedLabel";
import PromotionCard from "../../component/PromotionCard";
import _ from "../../common/lodash"
import Transaction from "../../common/Transaction";
import Loading from "../../component/Loading";
import {GET, POST} from "../../api";
import useNavigate from "../../hook/useNavigate";

export interface PurchaseDialogProps {
  open: boolean
  entity: Entity
  onReturn: () => void
}


export default ({open, entity, onReturn}: PurchaseDialogProps) => {
  const [paymentMethod, setPaymentMethod] = useState<'wechat' | 'stripe'>(entity.region === 'CN' ? 'wechat' : 'stripe')
  const [product, setProduct] = useState<Product>()
  const [promotion, setPromotion] = useState<Promotion>()
  const [transaction, setTransaction] = useState<Transaction>()
  const [previousTransactionUuid, setPreviousTransactionUuid] = useState<string>()
  const [state, setState] = useState<'blank' | 'loading' | 'ready'>('blank')
  const formRef = useRef<FormInstance>(null)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const {t} = useTranslation()

  async function pollTransactionStatus(){
    if (!transaction?.Uuid) return
    const {transaction: latestTransaction} = await GET<Transaction>(`/transactions/${transaction.Uuid}`)
    if (latestTransaction?.status === 'success') navigate(`/apps/wegreenpass?transaction=${transaction.Uuid}`)
    if (timer.current) clearInterval(timer.current)
    timer.current = setTimeout(pollTransactionStatus, 1000)
  }

  useEffect(()=>{
    if (timer.current) clearInterval(timer.current)
    if (paymentMethod === 'wechat') timer.current = setTimeout(pollTransactionStatus, 1000)
  }, [transaction, paymentMethod])

  useEffect(() => {
    (async () => {
      setState('loading')
      if (previousTransactionUuid) await POST(`/transactions/${previousTransactionUuid}/close`)
      const entityId = _.id(entity)
      const productId = _.id(product)
      if (!entityId || !productId || product?.term === 'placeholder') return setState('blank')
      const {transaction} = await POST<Transaction>('/transactions', {
        entityId, productId,
        promotionCode: promotion?.Code,
        channel: paymentMethod
      })
      if (transaction) {
        setTransaction(transaction)
        setPreviousTransactionUuid(transaction.Uuid)
        setState('ready')
      }
    })()
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [entity, product, promotion])

  if (!entity._id) return null
  const currency = paymentMethod === 'wechat' ? 'CNY' : 'EUR'
  const category = ["importer", "representative"].includes(entity.attrs['cbam-role']) ? 'wegreenpass.subscription.importer' : 'wegreenpass.subscription.exporter'
  return <Modal
    open={open}
    footer={null} className={"purchase-dialog"} width={1200}
    onCancel={onReturn}
    destroyOnClose
  >
    <div className={"payment"}>
      <header>
        <p className={'description'}>{t('description.wegreenpass-features')}</p>
        <h3>{t('description.wegreenpass-slogan')}</h3>
      </header>
      <Form
        ref={formRef}
        className={'form'}
        layout={"vertical"} initialValues={{
        paymentMethod: entity.region === 'CN' ? 'wechat' : 'stripe'
      }} onValuesChange={(cv, v) => {
        setPaymentMethod(v.paymentMethod)
        setProduct(v.product)
        if (cv?.product?.term === 'placeholder'){
          setPreviousTransactionUuid(undefined)
          SalesIQ.startChat()
        }
      }}
      >
        <div className={"left"}>
          <Form.Item label={t('field.current-declarant')}>
            <Row><Space><FlagImage iso3166={entity.region}/>{entity.name.native}</Space></Row>
            <Row>{entity.name.en}</Row>
          </Form.Item>
          <Form.Item label={<HintedLabel title={t('field.purchase-options')}>{t('description.purchase-options')}</HintedLabel>} name={"product"}>
            {paymentMethod && <PurchaseSelect
              currency={currency}
              category={category}
              current={entity.attrs?.['wegreenpass-product-name'] || 'none'}
              promotion={promotion}
            />}
          </Form.Item>
          {(entity.attrs?.['wegreenpass-product-name'] || 'none') !== 'none' && <Form.Item label={t('field.purchase-boosters')} name={"product"}>
            {paymentMethod && <PurchaseSelect
              currency={currency}
              category={'wegreenpass.booster'}
              current={entity.attrs?.['wegreenpass-product-name'] || 'none'}
              promotion={promotion}
            />}
          </Form.Item>}
          <Form.Item label={t('field.do-not-want-to-fill-by-yourself')}>
            <div
              className={"action-panel"}
            >
              {t('imperative.you-can-ask-us-to-declare-for-you')}
              <Button
                icon={<ArrowRightOutlined/>}
                onClick={SalesIQ.startChat}
              >{t("button.consult-now")}</Button>
            </div>
          </Form.Item>
        </div>
        <div className={"right"}>
          <Form.Item label={t('imperative.enter-promo-code')}>
            <Space.Compact>
              <Form.Item name={"promotionCode"}>
                <Input placeholder={t('imperative.enter-promo-code')}/>
              </Form.Item>
              <Button
                type={"primary"}
                onClick={async () => {
                  const {promotion, status} = await GET<Promotion>(`/promotions/${formRef.current?.getFieldValue('promotionCode')}`)
                  if (status.success && promotion) setPromotion(promotion)
                  else message.error(t('validation.coupon-unavailable-please-check'))
                }}
              >{t('button.validation')}</Button>
            </Space.Compact>
            {promotion && <PromotionCard promotion={promotion}/>}
          </Form.Item>
          <Form.Item label={t('field.payment-method')} name={'paymentMethod'}>
            <Radio.Group className={"payment-method-radio-group"} disabled={product?.term === 'placeholder'}>
              <Row><Radio
                value={"wechat"}
                style={{color: "#09B83E"}}
                disabled={entity.attrs['cbam-role'] === 'importer' || entity.attrs['cbam-role'] === 'representative'}
              ><Space style={{marginLeft: "0.5rem"}}><WechatFilled className={"purchase-type-icon"}/>{t('value.wechat-pay')}
              </Space></Radio></Row>
              <Row><Radio
                value={"stripe"}
                style={{color: "var(--primary-color)"}}
              ><Space style={{marginLeft: "0.5rem"}}><CreditCardOutlined className={"purchase-type-icon"}/>{t('value.credit-card-pay')}
              </Space></Radio></Row>
            </Radio.Group>
          </Form.Item>
          {state === 'loading' && <Loading/>}
          {state === 'ready' && paymentMethod === 'wechat' && transaction && product && product.term !== 'placeholder' &&
            <WeChatQrCode transaction={transaction}/>}
          {state === 'ready' && paymentMethod === 'stripe' && transaction && product && product.term !== 'placeholder'&&
            <StripeCheckout transaction={transaction} key={`${transaction.Uuid}`}/>}
        </div>
      </Form>
    </div>
  </Modal>

}