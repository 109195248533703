import { CrownFilled } from "@ant-design/icons";
import { Button, Card, Progress, Space } from "antd";
import _ from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Usage, { UsageLimit } from "../../common/transient/Usage";
import CodeListContext from "../../context/CodeListContext";
import "./PlanCard.scss";

export interface PlanCardProps {
  usage: Usage
  onPurchase?: () => void
}

export const PlanCardProgress = ({usage}: { usage?: UsageLimit }) => {
  const color = _.gt(usage?.percentage, 100) ? 'var(--exceeding-color)' : 'var(--primary-color)'
  if (!usage) return null
  return <Progress
    className={'progress'}
    strokeColor={color}
    percent={usage.percentage}
    format={() =>
      <span style={{color: color}}>{`${usage.actual} / ${usage?.expected}`}</span>}
  />
}

export default ({usage: {product, limits}, onPurchase}: PlanCardProps) => {
  const {t} = useTranslation()
  const {WC_Products} = useContext(CodeListContext)
  return <Card
    title={<Space style={{color: WC_Products.attr(product.name, 'color')}}>
      <CrownFilled/>
      {WC_Products.label(product.name)}
    </Space>}
    color={WC_Products.attr(product.name, 'color')}
    size={"small"}
    className={'plan-card'}
  >
    {t('field.good-count')}
    <PlanCardProgress usage={limits?.find(usage => usage.name === 'good')}/>
    {t('field.installation-count')}
    <PlanCardProgress usage={limits?.find(usage => usage.name === 'installation')}/>
    {onPurchase && <>
      <Button type={'primary'} onClick={onPurchase}>{t('button.upgrade-now')}</Button>
    </>}
  </Card>
}