import {useTranslation} from "react-i18next";
import {Card} from "antd";
import {DownloadOutlined, MailOutlined} from "@ant-design/icons";
import "./SubmitReviewStage.scss"
import {StageProps} from "../Stage";

export default (props: StageProps<any>) => {
  const {t} = useTranslation()
  return <div className={'submit-review-stage'}>
    <p>{t('description.review-stage-press-submit')}</p>
    <Card bordered={false}>
      <Card.Meta avatar={<MailOutlined/>} title={t('value.generate-link-send-email')} description={t('description.generate-link-send-email')}/>
    </Card>
    <Card bordered={false}>
      <Card.Meta avatar={<DownloadOutlined/>} title={t('value.download-data-package')} description={t('description.download-data-package')}/>
    </Card>
  </div>
}