import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Button, Checkbox, Modal} from "antd";
import EntitySelect from "../../component/EntitySelect";
import Entity from "../../common/Entity";
import {ArrowRightOutlined} from "@ant-design/icons";
import "./InstallationFillNoticeDialog.scss"

export interface InstallationFillNoticeDialogProps {
  importer?: Entity
}

export default ({importer}: InstallationFillNoticeDialogProps) => {
  const [state, setState] = useState<'filled' | 'blank' | 'invisible'>('blank')
  const {t} = useTranslation()
  return <Modal
    footer={null}
    open={state !== 'invisible'}
    title={t('title.welcome-to-cbam-installation-form')}
    closable={false}
    className={'installation-fill-notice-dialog'}
  >
    <main>
      <p>{t('imperative.you-are-reporting-cbam-to-this-entity')}</p>
      <EntitySelect value={importer} name={t('field.importer')} type={"card"} disabled={true} anonymous={true}/>
      <Checkbox
        required={true}
        onChange={(e) => setState(e.target.checked ? 'filled' : 'blank')}
      ><Trans i18nKey={"description.eu-login-form"}><a
        href={"https://we-carbon.com/privacy"} target={'_blank'}
      ></a><a href={"https://customs.ec.europa.eu/taxud/uumds/cas/uumds-wayf/legalNotice.jsp?locale=en"} target={'_blank'}></a></Trans></Checkbox>
      {state === 'filled' && <Button
          type={'primary'}
          icon={<ArrowRightOutlined/>}
          onClick={() => setState('invisible')}
      >{t('button.next')}</Button>}
    </main>
  </Modal>

}