import IFormItemProps from "../../form/IFormItemProps";
import "./PurchaseSelect.scss"
import {Button, Divider, Space, Tag} from "antd";
import {CheckCircleFilled, CheckOutlined, PhoneOutlined} from "@ant-design/icons";

import {PromotedProduct} from "../../common/Product";
import {useTranslation} from "react-i18next";
import {SalesIQ} from "../../component/SalesIQ";
import _ from '../../common/lodash'
import Promotion from "../../common/Promotion";
import {GET, useApis} from "../../api";
import Loading from "../../component/Loading";

export interface PurchaseSelectProps extends IFormItemProps<PromotedProduct> {
  category: string // 'wegreenpass.subscription.importer' or 'wegreenpass.subscription.exporter'
  currency?: string
  current?: string
  promotion?: Promotion
}

export interface PurchaseSelectPriceProps {
  product: PromotedProduct
  type: 'original' | 'promoted' | 'plain' | 'saved'
}

export const PurchaseSelectPrice = ({product, type}: PurchaseSelectPriceProps) => {
  const {t} = useTranslation()
  const value = _.where(type,
    ['promoted', product.promotedValue],
    ['saved', product.value - product.promotedValue],
    ['original', product.value]
  ) || product.value
  return <span className={"price " + type}><span className={'amount'}>{product.currency} {value.toFixed(2)}</span><span
    className={'term'}
  >/{t(`value.${product.term}`)}</span></span>
}

export default ({category, currency, current, value, onChange, promotion}: PurchaseSelectProps) => {
  const {t} = useTranslation()
  const {products, state} = useApis({
    products: () => GET<PromotedProduct[]>('/products', {currency, category, current, promotionCode: promotion?.Code})
  }, [currency, category, current, promotion])
  if (state !== 'success') return <Loading state={state}/>
  return <div className={'purchase-select'}>{
    products.map(product => <div
      onClick={() => onChange?.(product)} className={_.reject([
      "item",
      product.name,
      value?.skuId === product.skuId ? "selected" : ""
    ], _.isNil).join(' ')} key={product.skuId.toString()}
    >
      <Space>{value?.skuId === product.skuId ? <CheckCircleFilled className={"check"}/> : null}
        <div className={"title"}>{t('product-abbreviated.' + product.name)}</div>
        {(product.discountPercentage || 0) > 0 && <Tag
          color={'gold'}
          className={'tag'}
        >{t('value.save-percentage', {percentage: `${product.discountPercentage}%`})}</Tag>}
      </Space>
      <desc>{t('description.' + product.name)}</desc>
      {_.where(product,
        [v => v.term === 'placeholder', <p className={"price"}><Button
          type={'primary'}
          icon={<PhoneOutlined/>}
          className={'placeholder-button'}
          onClick={SalesIQ.startChat}
        >{t('button.consult-now')}</Button></p>],
        [v => v.promotedValue < v.value, <div className={'promotion-wrapper'}>
          <PurchaseSelectPrice product={product} type={'original'}/>
          <PurchaseSelectPrice product={product} type={'promoted'}/>
          <span className={'alert saved'}>{t('imperative.you-saved')}<PurchaseSelectPrice product={product} type={'saved'}/></span>
        </div>]
      ) || <PurchaseSelectPrice product={product} type={'plain'}/>}
      <Divider/>
      <ul>{product.descriptions.map(([s, u]) => <p><CheckOutlined className={'icon'}/>{(t as any)(s, u)}</p>)}</ul>
    </div>)}
  </div>
}