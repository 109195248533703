import { Select, Space } from "antd";
import { DefaultOptionType } from "rc-select/lib/Select";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "../common/lodash";
import IFormItemProps from "../form/IFormItemProps";
import FlagImage from "./FlagImage";

export interface RegionSelectProps extends IFormItemProps<any> {
  options: DefaultOptionType[]
  onSelect?: (option: RegionOptionDataType) => void
}

export interface RegionOptionDataType {
  iso3166: string
  isd: string
  names: {
    zh: string,
    en: string
  }
}

export default ({options, onSelect, ...rest}: RegionSelectProps) => {
  const [search, setSearch] = useState<string>("")
  const {t} = useTranslation()
  return <Select
    className={'region-select'}
    filterOption={false}
    options={options.filter(o => !search || ((o.label || "") as string).includes(search)).map(o => ({
      ...o,
      label: <Space><FlagImage iso3166={o.value as string}/>{o.label}</Space>
    }))}
    onFocus={() => {
      _(document.querySelectorAll('.region-select input')).forEach(e => e.setAttribute('autocomplete', 'none'))
    }}
    onChange={onSelect}
    placeholder={t('value.region')}
    showSearch
    onSearch={setSearch}
    {...rest}
  />
}