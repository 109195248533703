import {useState, useEffect} from "react";
import Entity from "../../common/Entity";
import {Button, Card, List, Modal, Space} from "antd";

import FlagImage from "../../component/FlagImage";
import {ArrowRightOutlined, AuditOutlined, CrownOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import PlanTag from "../component/PlanTag";
import "./SelectDeclarantDialog.scss"
import CreateEntityDialog from "../../dialog/CreateEntityDialog";
import PurchaseDialog from "./PurchaseDialog";
import {EditDialogProps} from "./EditDialog";
import {useTranslation} from "react-i18next";
import {GET, POST, useApis} from "../../api";
import Loading from "../../component/Loading";

export interface SelectDeclarantDialogProps extends EditDialogProps<Entity> {
  anonymous?: boolean
}

export default ({open, anonymous}: SelectDeclarantDialogProps) => {
  const [dialog, setDialog] = useState<"payment" | "success" | "create" | null>(null)
  const [entity, setEntity] = useState<Entity>()
  const {t} = useTranslation()
  const {entities, reload, state} = useApis({
    entities: () => GET<Entity[]>('/entities/my')
  })

  useEffect(() => {
    if (!open || state !== 'success') return
    setDialog(null)
    setEntity(undefined)
  }, [open])
  if (state !== 'success') return <Loading state={state}/>

  return <Modal
    open={open}
    title={<Space className={'title'}><AuditOutlined/>{t("title.select-declarant-entity")}</Space>}
    footer={null}
    className={"select-declarant-dialog"}
    closable={false}
  >
    <p>{t("description.select-declarant-entity")}</p>
    {entities.length > 0 ? <List<Entity>
      dataSource={entities}
      renderItem={o => <List.Item><Card
        bordered={false}
        className={`card declarant-level-${o.attrs["wegreenpass-product-name"]}`}
      >
        <main>
          <List.Item.Meta
            title={
              <Space wrap><FlagImage iso3166={o.region}/><span>{o.name.native}</span><PlanTag
                entity={o}
              />
              </Space>} description={<Space wrap>{o.idNumber}{o.name.en}</Space>}
          />
          <Space wrap>
            <Button
              type={"primary"}
              className={`button declarant-level-${o.attrs["wegreenpass-product-name"]}`}
              icon={<ArrowRightOutlined/>}
              onClick={async () => {
                if (!o.attrs["wegreenpass-product-name"] || o.attrs["wegreenpass-product-name"] === "none") {
                  setEntity(o)
                  setDialog('payment')
                } else {
                  await POST('/entities/my/default', {declarantEntity: o._id})
                  window.location.reload()
                }}
              }
            >{t("title.use-this-declarant")}</Button>
            {o.attrs['wegreenpass-product-name']?.includes('basic') && <Button
                className={`button upgrade`}
                icon={<CrownOutlined/>}
                onClick={() => {
                  setDialog('payment');
                  setEntity(o)
                }}
            >{t("title.upgrade-to-pro")}</Button>}
            <Button
              icon={<EditOutlined/>} onClick={() => {
              setDialog('create')
              setEntity(o)
            }}
            >{t("button.edit")}</Button>
          </Space>
        </main>
      </Card></List.Item>}
    /> : null}
    <Card
      className={"card"}
      onClick={() => {
        setDialog('create')
        setEntity(undefined)
      }}
    ><Space><PlusOutlined/>{t("button.add-new-entity")}</Space></Card>
    {entity &&
        <PurchaseDialog
            open={dialog === "payment"}
            entity={entity}
            onReturn={() => reload('entities')}
        />}
    <CreateEntityDialog
      anonymous={anonymous}
      open={dialog === "create"}
      value={entity ?? undefined}
      onReturn={async (o) => {
        setDialog(null)
        if (o) await reload('entities')
      }}
      roles={["importer", "exporter", "representative", "manufacturer"]}
    />

  </Modal>
}