import React, {ReactElement, ReactNode} from "react";
import "./DescriptiveIcon.scss"

export interface DescriptiveIconProps {
  icon?: ReactElement
  title: ReactNode | ReactNode[]
  description?: ReactNode | ReactNode[]

}


export default ({icon, title, description}: DescriptiveIconProps) => {
  return <div className={'descriptive-icon'}>
    <div className={'icon'}>{icon}</div>
    <div className={'title'}>{title}</div>
    <div className={'description'}>{description}</div>
  </div>
}