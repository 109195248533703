export interface ISuccess {
  success: true
  httpCode: 200 | 201 | 202 | 204
  statusCode: number
  message: string
}

export default {
  success: true,
  httpCode: 200,
  statusCode: 20000,
  message: "SUCCESS"
} as ISuccess