import React, {useContext} from "react";
import {StageProps, useStageForm} from "../Stage";
import {Button, Form, Space, Table, Tag} from "antd";
import {Declaration} from "../../../common/Declaration";
import {useTranslation} from "react-i18next";
import {Installation} from "../../../common/Installation";
import FlagImage from "../../../component/FlagImage";
import {AlertFilled, CheckCircleFilled, ReloadOutlined} from "@ant-design/icons";
import _, { IdEqualCustomizer } from "../../../common/lodash";
import Good from "../../model/Good";
import CodeListContext from "../../../context/CodeListContext";


export default (props: StageProps<Declaration>) => {
  const Form = useStageForm(props)
  const {t} = useTranslation()
  const {root} = props
  const {CL_MeasurementUnits} = useContext(CodeListContext)
  const goods = root?.goods?.map(good => {
    const TotalNetMass = _(good.ImportedQuantities).sumBy('NetMass')
    const CoveredNetMass = _(root?.installationSlots).flatMap(o => o.WeightedGoods).filter(o => _.isEqualWith(good, o.Good, IdEqualCustomizer)).sumBy(o => o?.NetMass || 0)
    return {
      FinalDescription: good.Description?.native || good.Description?.en,
      TotalNetMass, CoveredNetMass,
      TotalNetMassRendered:
        <Space wrap={true}>{TotalNetMass} {CL_MeasurementUnits.label(good.ImportedQuantities?.[0]?.MeasurementUnit)}</Space>,
      CoveredNetMassRendered:
        <Space wrap={true}>{CoveredNetMass}{CL_MeasurementUnits.label(good.ImportedQuantities?.[0]?.MeasurementUnit)}</Space>,
      ...good
    };
  }) || []
  return <Form>
    <p>{t('imperative.wait-for-all-installation-finished')}</p>
    <Button onClick={() => window.location.reload()} icon={<ReloadOutlined/>}>{t('button.refresh')}</Button>
    <Form.Item
      name="validatable"
      rules={[{
        message: t('validation.still-factory-not-finished'),
        validator: o => root?.installationSlots.some(o => o.status !== 'submitted') ? Promise.reject() : Promise.resolve()
      }]}
    >
      <Table dataSource={root?.installationSlots} columns={_.toAntdColumns<Declaration.InstallationSlot>({
        [t('field.name')]: installation => <Space>{installation.Entity?.region &&
            <FlagImage iso3166={installation.Entity.region}/>}{installation.Entity?.name.native}</Space>,
        [t('field.status')]: installation =>
          installation.status === 'submitted' ?
            <Tag color={'green'} icon={<CheckCircleFilled/>}>{t('value.finished')}</Tag>:
            <Tag color={'red'} icon={<AlertFilled/>}>{t('value.not-completed')}</Tag>
      })}/></Form.Item>
    <Form.Item name={"counting"} rules={[{
      message: t('validation.good-must-be-fully-associated'),
      validator: o => goods.every(o => o.TotalNetMass === o.CoveredNetMass) ? Promise.resolve() : Promise.reject()
    }]}>
      <Table dataSource={goods} columns={_.toAntdColumns<Good>({
        [t('field.name')]: 'FinalDescription',
        [t('field.net-mass')]: 'TotalNetMassRendered',
        [t('field.associated-net-mass')]: 'CoveredNetMassRendered'
      })}/>
    </Form.Item>
  </Form>
  
}