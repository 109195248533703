import { useEffect } from "react";
import Account from "../common/Account";

export interface SalesIQProps {
  widgetCode: string
  account: Account
}

// Redeclare `window` in TS
declare global {
  interface Window {
    $zoho: {
      salesiq: any
    }
  }
}

export namespace SalesIQ {
  export function startChat(){
    const floatElement = document.querySelector('.zsiq_floatmain') as any
    if (floatElement && 'click' in floatElement) floatElement.click()
  }
  export function track(action: string){
    window.$zoho.salesiq.visitor.customaction(action)
  }
}

export default ({account, widgetCode}: SalesIQProps) => {
  useEffect(() => {
    // Reach out who am I
    // Define global variable, according to Zoho SalesIQ specification
    window.$zoho = window.$zoho || {}
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: widgetCode,
      values: {},
      ready: function(){
        // Set the visitor name
        window.$zoho.salesiq.visitor.name(account.Profile?.Name)
        // Set the visitor email
        window.$zoho.salesiq.visitor.email(account.Profile?.Email)
        // Set the visitor contact number
        window.$zoho.salesiq.visitor.contactNumber(account.Phone)
      }
    }

    // Load the SalesIQ script
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.id = "zsiqscript"
    script.defer = true
    script.src = "https://salesiq.zohopublic.com.cn/widget"
    // Add the widget as the last child of <body> tag
    document.body.appendChild(script)

  }, [account])
  return <></>
}