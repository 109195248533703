import {useCallback} from "react";
import _ from "../common/lodash"
import {useNavigate as useRouterNavigate} from "react-router-dom";

export interface INavigator {
  library: 'react-router' | 'native'
  search: {
    strategy: 'drop' | 'preserve'
    target: string[]
  }
}

const UseNavigateDefault = {
  library: 'react-router',
  search: {
    strategy: 'preserve',
    target: ['brand']
  }
}

function makeUrl(url: string, override?: Partial<INavigator>){
  const o: INavigator = _.merge({}, UseNavigateDefault, override)
  // Get drop search
  let search: URLSearchParams;
  const urlSearch = new URLSearchParams(url.split('?')[1] || '');
  // If url starts with multiple `/`, replace then into one `/`
  url = url.replace(/\/{2,}/g, '/');
  switch (o.search.strategy) {
    case 'drop': // Answer = Location - Target + URL -> Dedup
      search = new URLSearchParams(window.location.search)
      o.search.target.forEach(key => search.delete(key));
      break;
    case 'preserve': // Answer = Location * Target + URL -> Dedup
      search = new URLSearchParams()
      const locationSearch = new URLSearchParams(window.location.search);
      o.search.target.forEach(key => locationSearch.has(key) && search.set(key, locationSearch.get(key) || ''));
      break;
  }
  Array.from(urlSearch.entries()).forEach(([key, value]) => search.set(key, value));
  // Get final URL
  // Rid url of search
  return `${url.split('?')[0]}${search.toString() ? '?' : ''}${search.toString()}`
}

export function staticHrefNavigate(url: string, override?: Partial<INavigator>) {
  window.location.href = makeUrl(url, override)
}

export default function useNavigate(initial?: Partial<INavigator>) {
  const routerNavigate = useRouterNavigate()
  return useCallback((url: string, override?: Partial<INavigator>) => {
    const o: Partial<INavigator> = _.merge({}, UseNavigateDefault, initial, override)
    const finalUrl = makeUrl(url, o)
    if (o.library === 'react-router') routerNavigate(finalUrl)
    else window.location.href = finalUrl
  }, [initial]);
}